<style>
    .container-payment {
        background-color: #A6D8F3 !important;
        width: 100%;
        height: 100vh;
        padding: 20px;
        overflow: auto;
    }

    .register-form {
        width: 512px;
        margin-right: auto;
        margin-left: auto;
    }

    @media only screen and (min-device-width: 240px) and (max-device-width: 600px) {
        .register-form {
            width: 100%;
        }
    }

    *, *:before, *:after {
        box-sizing:border-box;
    }
</style>

<div class="container-payment">
    <div class="row">
        <div class="col-12">
            <div class="text-center">
                <img src="./assets/img/brand/starconnect-02.png" alt="Logo StarConnect" style="width: 25%; height: auto;">
            </div>

            <div class="card bg-secondary border-0 mb-0 register-form">
                <div class="card-body pb-0">
                    <form role="form" #f='ngForm' >
                        <div class = 'row'>
                            <div class = 'col-12'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Contact Person</label>
                                        <input type = 'text'
                                               class = 'form-control form-control-sm'
                                               name = 'nama-lengkap-name'
                                               id = 'contact-person-id'
                                               [disabled]="readOnlyInput"
                                               #nativeContactPerson
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class = 'col-12 mt--3'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Company Name</label>
                                        <input type = 'text'
                                               class = 'form-control form-control-sm'
                                               name = 'company-name-name'
                                               id = 'company-name-id'
                                               [disabled]="readOnlyInput"
                                               #nativeCompanyName
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class = 'col-12 mt--3'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Job</label>
                                        <input type = 'text'
                                               class = 'form-control form-control-sm'
                                               name = 'job-name'
                                               id = 'job-id'
                                               [disabled]="readOnlyInput"
                                               #nativeJob
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class = 'col-12 mt--3'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Phone Number</label>
                                        <input type="number"
                                               id = 'no-handphone-id'
                                               class="form-control form-control-sm"
                                               name = "no-handphone-name"
                                               #nativePhoneNumber
                                               (wheel)='wheelProc($event)'
                                               min="0"
                                               maxLength="15"
                                               oninput = "if(this.value.length > this.maxLength) {this.value = '9'.repeat(this.maxLength)}"
                                               onkeypress = 'if(event.charCode == 101 || event.charCode == 45 ||
                                                        (this.value.length >= this.maxLength && window.getSelection().toString() == "")
                                                        ) return false'
                                               [readonly] = 'readOnlyInput'>
                                    </div>
                                </div>
                            </div>
                            <div class = 'col-12 mt--3'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Email</label>
                                        <input type = 'text'
                                               class = 'form-control form-control-sm'
                                               [ngClass]="{
                                                invalid:!validEmail
                                            }"
                                               (blur)="cekEmail()"
                                               name = 'alamat-email-name'
                                               id = 'alamat-email-id'
                                               [disabled]="readOnlyInput"
                                               #nativeEmail
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class = 'col-12 col-md-6 mt--3'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Total Employees</label>
                                        <div class="quality-container d-flex justify-content-between">
                                            <span (click)="minusQty('totalEmployees')"
                                                  class="justify-content-center align-items-center"
                                                  [ngStyle]="{'opacity': totalEmployees == 10 ? '0.4' : '1',
                                                    'cursor': totalEmployees == 10 ? 'not-allowed' : 'pointer'}"
                                                  style="padding: 10px;">
                                                <i class="fa fa-minus"></i>
                                            </span>
                                            <span style="padding: 10px;">{{totalEmployees}}</span>
                                            <span (click)="addQty('totalEmployees')" class="justify-content-center align-items-center" style="cursor: pointer; padding: 10px;">
                                                <i class="fa fa-plus"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class = 'col-12 col-md-6 mt--3'>
                                <div class = 'form-group required'>
                                    <div class = 'fom-control'>
                                        <label class = 'form-control-label'>Subscription Periods</label>
                                        <div class="quality-container d-flex justify-content-between" >
                                            <span (click)="minusQty('subscriptionPeriods')"
                                                  class="justify-content-center align-items-center"
                                                  [ngStyle]="{'opacity': subscriptionPeriods == 1 ? '0.4' : '1',
                                                    'cursor': subscriptionPeriods == 1 ? 'not-allowed' : 'pointer'}"
                                                  style="padding: 10px;">
                                                <i class="fa fa-minus"></i>
                                            </span>
                                            <span style="padding: 10px;">{{subscriptionPeriods + ' ' + (subscriptionPeriods == 1 ? 'Year' : 'Years')}}</span>
                                            <span (click)="addQty('subscriptionPeriods')"
                                                  class="justify-content-center align-items-center"
                                                  [ngStyle]="{'opacity': subscriptionPeriods == 3 ? '0.4' : '1',
                                                    'cursor': subscriptionPeriods == 3 ? 'not-allowed' : 'pointer'}"
                                                  style="padding: 10px;">
                                                <i class="fa fa-plus"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" style="background-color: #6FC5F8; border-color:#6FC5F8"
                                    class="btn btn-block btn-primary my-4"
                                    (click)="paymentNow()"
                                    [disabled]='readOnlyInput'
                            >
                                Next
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-http-loader
        [entryComponent]="httpLoaderImage">
</ng-http-loader>
