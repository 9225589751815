import { Component, OnDestroy } from "@angular/core";
import { Spinkit } from "ng-http-loader";
import { Subject, takeUntil } from "rxjs";
import { MethodServices } from 'src/services/method-services';
import { HttpLoaderImageComponent } from "../http-loader-image/http-loader-image.component";

@Component({
    selector: 'ng-http-loader-global',
    templateUrl: 'ng-http-loader-global.html',
})

export class NgHttpLoaderGlobal implements OnDestroy {

    public httpLoaderImage = HttpLoaderImageComponent
    public spinkit = Spinkit

    statusDark: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject();

    constructor(public methodServices: MethodServices) {

        let gitdmod: any = localStorage.getItem("GITDMOD")
        if (typeof gitdmod != 'undefined' && gitdmod != null && gitdmod != '') {
            let statusDark_temp: any = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITDMOD"));

            this.statusDark = statusDark_temp.toLowerCase() == "true" ? true : false

        }

        this.methodServices.subject_DarkMode.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.statusDark = result
            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }
}
