import { AfterViewInit, Component, OnInit } from "@angular/core";

@Component({
    selector: 'skeleton-form-global-component',
    templateUrl: 'skeleton-form-global-component.html',
})

export class GlobalSkeletonFormGlobalComponent implements OnInit, AfterViewInit {
    constructor(){
        window.scrollTo(0,0);
    }

    ngOnInit(): void {
      window.scrollTo(0,0);
    }
    

    ngAfterViewInit(): void {
      window.scrollTo(0,0);
    }
}