import { Component, Input, ViewChild, OnInit, AfterViewInit, ViewEncapsulation, Output, EventEmitter, OnChanges, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MethodServices } from "src/services/method-services";
import { ActivatedRoute } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { DataTypeServices } from "src/services/data-type-services";
import { TranslateService } from "@ngx-translate/core";

export const SYS_TRANSACTION_FORMATS: any[] = [
    { name: "Sequence No", value: "%NO%", description: "Number" },
    { name: "Month (01, 02, 03,...)", value: "%MM%", description: "Month in Number" },
    { name: "Month (I, II, III...)", value: "%MONTH_ROMAN%", description: "Month in Roman" },
    { name: "Month (JAN,FEB,MAR,…)", value: "%MON%", description: "Month by Name" },
    { name: "Year (YYYY)", value: "%YEAR%", description: "Year" },
    { name: "Year (YY)", value: "%YY%", description: "Year" },
    { name: "Text", value: "TEXT", description: "TEXT" },
];

@Component({
    selector: "ng-select-global",
    templateUrl: "ng-select-global.html",
    styleUrls: ["ng-select-global.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NgSelectGlobal implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    triggerType: boolean = false;
    ngSelectChange_Status: boolean = false;
    ngSelectChange_ValueIdPrev: any;
    ngSelectChange_NameIdPrev: any;

    bindValue: any;
    bindLabel: any;
    modelNgSelect: any;
    tempModelNgSelect: any;

    dataMaster = [];
    firstLoad: boolean = true;
    stt: any;
    aodTopPrev: any;
    paramPrev: any;
    editableStatus: boolean = false;
    showLoading: boolean = false;

    placeholder: string = "";
    placeholderTempInvalid: string = "";

    triggerChange: boolean = false;
    triggerClear: boolean = false;
    triggerFocus: boolean = false;
    statusBackspace: boolean = false;
    statusTab: boolean = false;
    statusKeydown: boolean = false;
    statusOpenProc: boolean = false;
    statusHitApiAwal: boolean = false;
    statusValueDefaultChanged: boolean = false;

    statusValueDefaultAwal: boolean = true;

    noListArrayWithPlaceholder: boolean = false;

    firstOpenWhenLoad: boolean = true;

    @ViewChild("nativeNgSelect") nativeNgSelect: NgSelectComponent;
    @Input() ngSelect_ManualHitAPIAwal: boolean = false;
    @Input() lookup: any;
    @Input() valueDefault: any = null;
    @Input() statusDisabled: boolean = false;
    @Input() aodTop: any;
    @Output() lookupChange = new EventEmitter<any>();
    @Input() size: any = "";
    @Input() param: any;
    @Input() focusNgSelect: any;
    @Input() gitLabel: any;
    @Input() gitRequired: any;
    @Input() editShow: boolean = false;
    @Input() arrInput: any[] = [];
    @Input() manualOpenProc?: boolean = false;
    @Input() placeholderLabel: any;
    @Input() isModelModal: any = false;
    @Input() isCustomStyle: any = false; //for custom style ngStyle if needed
    @Input() customStyle: any = "";

    langCur: any = "en";
    langSelectAnOption = "Select an option ...";

    httpLoaderRenderShow: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject();
    firstLoadNgSelect: boolean = true;
    firstLoadNgSelectOpenModalValid: boolean = false;

    constructor(private methodServices: MethodServices, public dataTypeService: DataTypeServices, private translateService: TranslateService, private activatedRoute: ActivatedRoute) {
        this.methodServices.subject_DarkMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            if (result) {
                document.getElementsByTagName("body")[0].classList.add("darkMode");
            } else {
                document.getElementsByTagName("body")[0].classList.remove("darkMode");
            }
        });

        this.translateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            this.langCur = result["lang"];
            // this.funcLangChangeSelectAnOption(result['lang'])
        });

        if (typeof localStorage.getItem("GITLANG") != "undefined" && localStorage.getItem("GITLANG") != null) {
            this.langCur = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITLANG"));
            // this.funcLangChangeSelectAnOption(this.methodServices.ivSha256Decrypt(localStorage.getItem("GITLANG")))
        } else {
            this.funcLangChangeSelectAnOption();
        }

        this.methodServices.triggerRemoveDirty.pipe().subscribe((result) => {
            if (result.showModal && result.createModalNgSelect && this.isModelModal) {
                this.firstLoadNgSelect = true;
                this.firstLoadNgSelectOpenModalValid = true;
            }
        });
    }

    funcLangChangeSelectAnOption(lang: String = "en") {
        let arrLang: any[] = ["Select an option ...", "Pilih opsi ...", "옵션을 선택하세요 ...", "เลือกตัวเลือก ..."];

        if (typeof lang != "undefined" && lang != null) {
            this.langCur = lang;

            switch (this.langCur) {
                case "en":
                    this.langSelectAnOption = "Select an option ...";
                    break;
                case "id":
                    this.langSelectAnOption = "Pilih opsi ...";
                    break;
                case "kr":
                    this.langSelectAnOption = "옵션을 선택하세요 ...";
                    break;
                case "th":
                    this.langSelectAnOption = "เลือกตัวเลือก ...";
                    break;
            }

            if (typeof this.placeholderLabel == "undefined") {
                this.placeholderLabel = this.langSelectAnOption;
            }

            let findLang = arrLang.find((result) => {
                return result == this.placeholder;
            });

            if (typeof this.placeholder == "undefined" || findLang) {
                this.placeholder = this.langSelectAnOption;
                this.placeholderLabel = this.langSelectAnOption;
            }
        }
    }

    keydownInDropdown(event) {
        if (event.keyCode == 8) {
            this.statusBackspace = true;
            if (this.modelNgSelect == null || this.modelNgSelect == "") {
                if (this.nativeNgSelect.searchInput.nativeElement.value == "" || this.nativeNgSelect.searchInput.nativeElement.value == null) {
                    this.valueDefault = null;
                    this.tempModelNgSelect = null;
                    // this.placeholder = "Select an option ..."
                    this.placeholder = this.langSelectAnOption;
                    this.lookupChange.emit({ keyjson: null, tipe: "change" });
                }
            }
        }

        if (event.keyCode != 13) {
            if (event.shiftKey && event.key == "Tab") {
                return;
            }

            if (event.keyCode == 9) {
                return;
            }

            if (this.dataMaster.length == 0) {
                this.statusKeydown = false;
                if (!this.triggerType) {
                    if (this.nativeNgSelect.searchInput.nativeElement.value == "") {
                        this.statusOpenProc = true;
                        this.callFunc(this.lookup);
                        this.triggerChange = true;
                        this.triggerClear = true;

                        this.triggerType = true;
                    }
                }
            }

            this.statusKeydown = true;
        } else {
            this.statusKeydown = false;
        }

        if (event.keyCode == 9) {
            this.statusTab = true;
        }

        if (event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40 || event.keyCode == 13 || event.keyCode == 16 || event.keyCode == 9) {
            return true;
        }
        if (event.target.value.length < this.dataTypeService.sc_value) {
            // pas 10, kalau set <= 10, maka jadi 11
            return true;
        } else {
            return false;
        }
    }

    callFunc(lookupParam) {
        switch (lookupParam) {
            case "gender":
            case "tag-type":
            case "exceptiontype":
            case "relationship":
            case "preferred/gender":
            case "nationality":
            case "optyesno":
            case "type":
            case "recruitment/type":
            case "expensetype/category":
            case "recruitment/emailtemplate":
            case "recruitment/status":
            case "checklist/category":
            case "terminationrule":
            case "claimmethod":
            case "period-type":
            case "benefittype":
            case "absence/category":
            case "calculation_method":
            case "server/status":
            case "absence/type":
            case "balance/type":
            case "greeting/template":
            case "resetby":
            case "absencerequesttype":
            case "autorejecttype":
            case "reportstatus":
            case "listdayspicker":
            case "priority":
            case "packagelist":
            case "package-code":
            case "querytype":
            case "type_section":
            case "benefittypegroup":
            case "checklist/performer":
            case "loan/status":
            case "9-box-matrix":
            case "costingtype":
            case "usertype":
            case "degree":
            case "typecolorpicker":
            case "expensetypevaluetype":
            case "voice-of-employee/incident-status-new":
            case "voice-of-employee/incident-status-inprogress":
            case "greeting-template/category":
            case "voucher-type":
            case "calendar-month":
            case "matrix-type":
            case "reset-process":
            case "number-format":
            case "active-status":
                this.funcLoadData(lookupParam);
                break;
            case "grade":
            case "organization":
            case "location":
            case "job":
            case "city-all":
            case "secure/payroll-group":
            case "position":
            case "attendance-group":
            case "company":
            case "secure/company":
            case "employment-manager":
            case "element-classification":
            case "element-type":
            case "job-family":
            case "position-level":
            case "organization-level":
            case "location-group":
            case "run-type":
            case "absence-type-by-employment-id":
            case "process-by-user":
            case "team/process-by-user":
            case "benefit-type-by-employment-id":
            case "payroll-requests":
            case "eligibility-profile":
            case "company/bank":
            case "rating-detail":
            case "approval-group":
            case "request-category-type":
            case "rating-model":
            case "section":
            case "goal-plan":
            case "employee-performance":
            case "province":
            case "city":
            case "district":
            case "subdistrict":
            case "postal-code":
            case "template-period":
            case "competency":
            case "question":
            case "parent-question":
            case "question-response":
            case "questionnaire-section":
            case "questionnaire-template":
            case "employee-checklist":
            case "preceding-task":
            case "checklist-template":
            case "goal-plan-by-employmentid":
            case "asset-type":
            case "chart-of-account":
            case "employment":
            case "absence-type":
            case "benefit/transaction-type":
            case "benefit/recipient-name":
            case "transaction/benefit/claim-for":
            case "loan-type":
            case "payroll-group":
            case "role":
            case "transaction/work-space/available":
            case "competency/category":
            case "competency/sub-category":
            case "dashboard/hr/apris/organization-lists":
            case "dashboard/hr/apris/manager-lists":
            case "expense/expense-types":
            case "expense/project":
            case "recruitment/client":
            case "recruitment/client/project":
            case "expense/project/expense-types":
            case "recruitment/mpp":
            case "recruitment/section":
            case "recruitment/template":
            case "recruitment/hiring-phase":
            case "recruitment/job-requisition":
            case "recruitment/hiring-process":
            case "recruitment/mpp-detail":
            case "recruitment/applicant":
            case "pattern/detail":
            case "shift/shift-change":
            case "import/module":
            case "import/type":
            case "recruitment/job-posting":
            case "career/assessment/template":
            case "employee/hierarchy":
            case "employee/list/binding":
            case "starfeed/category":
            case "expense/currencies":
            case "expense/business-trip-categories":
            case "scadmin/master-database":
            case "job/category":
            case "balance-type":
            case "letter-template":
                this.funcLoadData(lookupParam, "lookup");
                break;
            case "admin/time-management/shift":
            case "admin/time-management/holiday":
            case "admin/time-management/pattern":
            case "employee/secure":
            case "employee/secure_employment":
            case "employee/secure_employment_name_only":
            case "admin/competencies":
            case "admin/performance/competencies":
            case "admin/goal/library":
            case "admin/goal/goal-library":
            case "admin/itemcategory":
            case "admin/performance/item-category":
            case "admin/item":
            case "admin/performance/item":
            case "admin/career/lookup/assessment":
            case "admin/employee/talent-matrix/lookup-year":
                this.funcLoadData(lookupParam, "content");
                break;
            default:
                this.funcLoadData(lookupParam, "globalkey");
                break;
        }
    }

    ngOnInit() {
        this.aodTopPrev = this.aodTop;
        this.paramPrev = this.param;

        this.tempModelNgSelect = this.valueDefault;

        this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

        if (this.statusHitApiAwal) {
            this.callFunc(this.lookup);
        }

        this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            if (typeof result.stt != "undefined") {
                if (this.methodServices.ivSha256Decrypt(result.stt) == "new") {
                    this.stt = "new";
                } else {
                    this.stt = "";
                }
            }
        });
    }

    ngAfterViewInit() {
        this.tempModelNgSelect = this.valueDefault;

        this.firstLoad = false;
        if (this.stt != "new") {
            let nativeInterval = setInterval(() => {
                if (typeof this.valueDefault != "undefined") {
                    this.modelNgSelect = this.valueDefault;
                    clearInterval(nativeInterval);
                }
            });
        }
        if (this.focusNgSelect) {
            setTimeout(() => {
                this.nativeNgSelect.focus();
            }, 900);
        }
    }

    funcLoadData(attr, api?, paramApi?) {
        if (attr == "gender") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Male", value: "Male", description: "Male" }, { name: "Female", value: "Female", description: "Female" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "tag-type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "<First Name> <Last Name>", value: "<First Name> <Last Name>", description: "<First Name> <Last Name>" }, { name: "<FullName>", value: "<FullName>", description: "<FullName>" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "exceptiontype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Include", value: "Include", description: "Include" }, { name: "Exclude", value: "Exclude", description: "Exclude" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "preferred/gender") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Male", value: "Male", description: "Male" }, { name: "Female", value: "Female", description: "Female" }, { name: "Any", value: "Any", description: "Any" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "relationship") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Spouse", value: "Spouse", description: "Spouse" }, { name: "Child", value: "Child", description: "Child" }, { name: "Parent", value: "Parent", description: "Parent" }, { name: "Sibling", value: "Sibling", description: "Sibling" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "nationality") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Local", value: "Local", description: "Local" }, { name: "Expatriate", value: "Expatriate", description: "Expatriate" }, { name: "Any", value: "Any", description: "Any" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "server/status") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Available", value: "Available", description: "Available" }, { name: "Not Available", value: "Not Available", description: "Not Available" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "optyesno") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Yes", value: "Yes", description: "Yes" }, { name: "No", value: "No", description: "No" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "benefittypegroup") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Medical", value: "Medical", description: "Medical" }, { name: "Non Medical", value: "Non Medical", description: "Non Medical" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "expensetype/category") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Project", value: "Project", description: "Project" }, { name: "Internal", value: "Internal", description: "Internal" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Add", value: 1, description: "Type Add" }, { name: "Substract", value: -1, description: "Type Substract" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "typecolorpicker") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Solid", value: "Solid", description: "Solid" }, { name: "Gradient", value: "Gradient", description: "Gradient" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "recruitment/emailtemplate") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Interview", value: "Interview", description: "Interview" }, { name: "Medical Checkup", value: "Medical Checkup", description: "Medical Checkup" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "recruitment/status") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { name: "Draft", value: "Draft", description: "Draft" },
                { name: "In Progress", value: "In Progress", description: "In Progress" },
                { name: "Skipped", value: "Skipped", description: "Skipped" },
                { name: "Failed", value: "Failed", description: "Failed" },
                { name: "Passed", value: "Passed", description: "Passed" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "recruitment/type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Interview", value: "Interview", description: "Interview" }, { name: "Prescreening", value: "Prescreening", description: "Prescreening" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "absence/category") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Leave", name: "Leave", description: "Leave" }, { value: "Permission", name: "Permission", description: "Permission" }, { value: "Sick", name: "Sick", description: "Sick" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "packagelist") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Bronze", name: "Bronze", description: "Bronze" }, { value: "Silver", name: "Silver", description: "Silver" }, { value: "Gold", name: "Gold", description: "Gold" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "package-code") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: 10, name: "Bronze", description: "Bronze" }, { value: 20, name: "Silver", description: "Silver" }, { value: 30, name: "Gold", description: "Gold" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "querytype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "CREATE-ALTER", name: "Table", description: "Table" }, { value: "INSERT-UPDATE", name: "Data", description: "Data" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "absence/type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Annual Leave", name: "Annual Leave", description: "Annual Leave" }, { value: "Long Leave", name: "Long Leave", description: "Long Leave" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "calculation_method") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Sum", name: "Sum", description: "Sum" }, { value: "Average", name: "Average", description: "Average" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "checklist/category") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { value: "On Demand", name: "On Demand", description: "On Demand" },
                { value: "Onboarding", name: "Onboarding", description: "Onboarding" },
                { value: "Offboarding", name: "Offboarding", description: "Offboarding" },
                { value: "Other", name: "Other", description: "Other" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "checklist/performer") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { value: "Employee", name: "Employee", description: "Employee" },
                { value: "Line Manager", name: "Line Manager", description: "Line Manager" },
                { value: "User", name: "User", description: "User" },
                { value: "User Group", name: "User Group", description: "User Group" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "usertype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Line Manager", name: "Line Manager", description: "Line Manager" }, { value: "User", name: "User", description: "User" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "degree") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { value: "D1", name: "D1", description: "D1" },
                { value: "D3", name: "D3", description: "D3" },
                { value: "Profesi Apoteker", name: "Profesi Apoteker", description: "Profesi Apoteker" },
                { value: "S1", name: "S1", description: "S1" },
                { value: "S2", name: "S2", description: "S2" },
                { value: "SD", name: "SD", description: "SD" },
                { value: "SMA", name: "SMA", description: "SMA" },
                { value: "SMF", name: "SMF", description: "SMF" },
                { value: "SMK", name: "SMK", description: "SMK" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "balance/type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Monthly", name: "Monthly", description: "Monthly" }, { value: "Yearly", name: "Yearly", description: "Yearly" }, { value: "Lifetime", name: "Lifetime", description: "Lifetime" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "greeting/template") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { value: "Leave", name: "Leave", description: "Leave" },
                { value: "Permission", name: "Permission", description: "Permission" },
                { value: "Sick", name: "Sick", description: "Sick" },
                { value: "Marriage", name: "Marriage", description: "Marriage" },
                { value: "Maternity", name: "Maternity", description: "Maternity" },
                { value: "Condolences", name: "Condolences", description: "Condolences" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "terminationrule") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Termination Date", value: "Termination Date", description: "Termination Date" }, { name: "Pay Thru Date", value: "Pay Thru Date", description: "Pay Thru Date" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "listdayspicker") {
            this.showLoading = true;
            this.dataMaster = [];
            for (let i = 1; i <= 31; i++) {
                this.dataMaster.push({
                    name: i.toString(),
                    value: i.toString(),
                    description: i.toString(),
                });
            }

            this.dataMaster.push({ name: "End Of Month", value: "99", description: "End Of Month" });

            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "claimmethod") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "On Bill", value: "On Bill", description: "On Bill" }, { name: "Designated Amount", value: "Designated Amount", description: "Designated Amount" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "period-type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { name: "Enterprise Hire Date", value: "Enterprise Hire Date", description: "Enterprise Hire Date" },
                { name: "Company Hire Date", value: "Company Hire Date", description: "Company Hire Date" },
                { name: "Calendar", value: "Calendar", description: "Calendar" },
                { name: "Employment Type", value: "Employment Type", description: "Employment Type" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "benefittype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Amount", value: "Amount", description: "Amount" }, { name: "Balance", value: "Balance", description: "Balance" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "expensetypevaluetype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Fixed", value: "Fixed", description: "Fixed" }, { name: "Daily", value: "Daily", description: "Daily" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "absencerequesttype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Leave", value: "Leave", description: "Leave" }, { name: "Permission", value: "Permission", description: "Permission" }, { name: "Sick", value: "Sick", description: "Sick" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "reportstatus") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { name: "In Progress", value: "In Progress", description: "In Progress" },
                { name: "Approved", value: "Approved", description: "Approved" },
                { name: "Rejected", value: "Rejected", description: "Rejected" },
                { name: "Canceled", value: "Canceled", description: "Cancelled" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "priority") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Low", name: "Low", description: "Low" }, { value: "Medium", name: "Medium", description: "Medium" }, { value: "High", name: "High", description: "High" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "autorejecttype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Current Month", name: "Current Month", description: "Current Month" }, { value: "Next Month", name: "Next Month", description: "Next Month" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "type_section") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Goal", name: "Goal", description: "Goal" }, { value: "Competency", name: "Competency", description: "Competency" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "matrix-type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Horizontal", value: "Horizontal", description: "Horizontal" }, { name: "Vertical", value: "Vertical", description: "Vertical" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "reset-process") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "All", value: "All", description: "All" }, { name: "Manager Evaluation", value: "RESET_TO_MANAGER_EVALUATION", description: "Manager Evaluation" }, { name: "Participant Feedback", value: "RESET_TO_PARTICIPANT_FEEDBACK", description: "Participant Feedback" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "number-format") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "#.###", value: "#.###", description: "#.###" }, { name: "#,###", value: "#,###", description: "#,###" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "active-status") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Yes", value: "true", description: "Yes" }, { name: "No", value: "false", description: "No" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "resetby") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ name: "Month", value: "Month", description: "Month" }, { name: "Year", value: "Year", description: "Year" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "costingtype") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Debit", name: "Debit", description: "Debit" }, { value: "Credit", name: "Credit", description: "Credit" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "loan/status") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Unpaid", name: "Unpaid", description: "Unpaid" }, { value: "Paid", name: "Paid", description: "Paid" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "voice-of-employee/incident-status-new") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "In Progress", name: "In Progress", description: "In Progress" }, { value: "Rejected", name: "Rejected", description: "Rejected" });

            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "greeting-template/category") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Birthday", name: "Birthday", description: "Birthday" }, { value: "Work Anniversary", name: "Work Anniversary", description: "Work Anniversary" }, { value: "New Joiner", name: "New Joiner", description: "New Joiner" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "voice-of-employee/incident-status-inprogress") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Rejected", name: "Rejected", description: "Rejected" }, { value: "Closed", name: "Closed", description: "Closed" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "voucher-type") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push({ value: "Online", name: "Online", description: "Online" }, { value: "Offline", name: "Offline", description: "Offline" });
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "calendar-month") {
            this.showLoading = true;
            this.dataMaster = [];
            this.dataMaster.push(
                { value: 1, name: "January", description: "January" },
                { value: 2, name: "February", description: "February" },
                { value: 3, name: "March", description: "March" },
                { value: 4, name: "April", description: "April" },
                { value: 5, name: "May", description: "May" },
                { value: 6, name: "June", description: "June" },
                { value: 7, name: "July", description: "July" },
                { value: 8, name: "August", description: "August" },
                { value: 9, name: "September", description: "September" },
                { value: 10, name: "October", description: "October" },
                { value: 11, name: "November", description: "November" },
                { value: 12, name: "December", description: "December" }
            );
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "9-box-matrix") {
            this.showLoading = true;
            this.dataMaster = [];
            for (let i = 0; i < 9; i++) {
                this.dataMaster.push({
                    value: (i + 1).toString(),
                    name: (i + 1).toString(),
                    description: (i + 1).toString(),
                });
            }
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "SYS_TRANSACTION_FORMATS") {
            this.showLoading = true;
            this.dataMaster = SYS_TRANSACTION_FORMATS;
            this.bindValue = "value";
            this.bindLabel = "name";
            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            this.showLoading = false;
            this.parsingDataFocus();
            return;
        }
        if (attr == "city-all") {
            this.showLoading = true;
            this.methodServices.getTenantApi(
                "/api/lookup/" + attr,
                (result, status) => {
                    if (status == "Success") {
                        this.dataMaster = [];
                        for (var arr in result) {
                            this.dataMaster.push({
                                value: result[arr]["value"], //store key
                                name: result[arr]["name"], //show di depan
                                description: result[arr]["description"],
                            });
                        }

                        this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                        this.bindValue = "value";
                        this.bindLabel = "name";
                        this.showLoading = false;
                        this.parsingDataFocus();
                    }
                },
                "lookup"
            );
            return;
        }
        if (attr == "country") {
            this.showLoading = true;
            this.methodServices.getTenantApi(
                "/api/lookup/" + attr,
                (result, status) => {
                    if (status == "Success") {
                        this.dataMaster = [];
                        for (var arr in result) {
                            this.dataMaster.push({
                                name: result[arr]["name"], //show di depan
                            });
                        }
                        this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                        this.bindValue = "value";
                        this.bindLabel = "name";
                        this.showLoading = false;
                        this.parsingDataFocus();
                    }
                },
                "lookup"
            );
            return;
        }
        if (api == "globalkey") {
            this.showLoading = true;

            this.methodServices.getTenantApi(
                "/api/lookup/global-key?name=" + attr,
                (result, status) => {
                    if (status == "Success") {
                        this.dataMaster = [];
                        for (var arr in result) {
                            // testing
                            this.dataMaster.push({
                                value: result[arr]["value"], //store key
                                name: result[arr]["name"], //show di depan
                                description: result[arr]["description"],
                            });
                        }
                        this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });

                        this.bindValue = "value";
                        this.bindLabel = "name";
                        this.showLoading = false;

                        this.parsingDataFocus();
                    }
                },
                "lookup"
            );
            return;
        } else if (api == "lookup") {
            if (attr == "template-period") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/lookup/" + attr,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["id"],
                                    name: result[arr]["name"],
                                    startDate: result[arr]["startDate"],
                                    endDate: result[arr]["endDate"],
                                });
                            }

                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "employee/hierarchy") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/lookup/" + attr,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    employeeId: result[arr]["employeeId"],
                                    fullName: result[arr]["employeeNo"] == null ? result[arr]["fullName"] : result[arr]["fullName"] + " - " + result[arr]["employeeNo"],
                                    employmentId: result[arr]["employmentId"],
                                    assignmentId: result[arr]["assignmentId"],
                                    employeeNo: result[arr]["employeeNo"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "employeeId";
                            this.bindLabel = "fullName";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "expense/currencies") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/lookup/" + attr,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["id"],
                                    name: result[arr]["code"] + " - " + result[arr]["name"],
                                });
                            }

                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            let paramTemp: any;
            if (typeof paramApi != "undefined" && paramApi != "" && paramApi != null) {
                paramTemp = paramApi;
            } else {
                if (typeof this.param != "undefined" && this.param != "" && this.param != null) {
                    paramTemp = this.param;
                } else {
                    paramTemp = "aod=" + this.aodTop + "&active=true";
                }
            }

            let splitParam = paramTemp.split("&");
            let findParam = splitParam.find((res) => {
                return res == "aod=undefined" || res == "employmentId=undefined" || res == "id=undefined";
            });
            if (findParam) {
                this.modelNgSelect = null;
                this.dataMaster = [];
                setTimeout(() => {
                    this.triggerFocus = false;
                }, 50);
                return;
            }

            this.showLoading = true;

            if (attr == "employment-manager") {
                this.httpLoaderRenderShow = true;

                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/lookup/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["id"],
                                    active: result[arr]["active"],
                                    name: result[arr]["employeeNo"] == null ? result[arr]["name"] : result[arr]["name"] + " - " + result[arr]["employeeNo"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "position") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/lookup/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["id"],
                                    name: result[arr]["name"],
                                    positionLevelName: result[arr]["positionLevelName"],
                                });
                            }

                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "dashboard/hr/apris/organization-lists") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/admin/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["organizationId"],
                                    name: result[arr]["name"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "scadmin/master-database") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["id"],
                                    name: result[arr]["name"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "dashboard/hr/apris/manager-lists") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/admin/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["employee_id"],
                                    employmentId: result[arr]["employment_id"],
                                    name: result[arr]["name"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "transaction/work-space/available") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/admin/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    id: result[arr]["workspaceId"],
                                    name: result[arr]["name"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            if (attr == "transaction/benefit/claim-for") {
                this.showLoading = true;
                this.methodServices.getTenantApi(
                    "/api/admin/" + attr + "?" + paramTemp,
                    (result, status) => {
                        if (status == "Success") {
                            this.dataMaster = [];
                            for (var arr in result) {
                                this.dataMaster.push({
                                    value: result[arr]["value"],
                                    name: result[arr]["name"],
                                });
                            }
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.bindValue = "value";
                            this.bindLabel = "name";
                            this.showLoading = false;
                            this.parsingDataFocus();
                        }
                    },
                    "lookup"
                );
                return;
            }

            this.httpLoaderRenderShow = true;

            this.methodServices.getTenantApi(
                "/api/lookup/" + attr + "?" + paramTemp,
                (result, status) => {
                    if (status == "Error") {
                        this.methodServices.showToast(this.dataTypeService.getErrorMessageApi(result), "error");
                        this.lookupChange.emit({
                            statusError: true,
                            msgError: this.dataTypeService.getErrorMessageApi(result),
                            tipe: "error",
                        });
                        this.showLoading = false;
                        this.parsingDataFocus();
                    } else if (status == "Success") {
                        this.showLoading = true;
                        switch (attr) {
                            case "province":
                            case "city":
                            case "district":
                            case "subdistrict":
                            case "postal-code":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        name: result[arr],
                                        value: result[arr],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "value";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "process-by-user":
                            case "team/process-by-user":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        value: result[arr]["value"],
                                        name: result[arr]["name"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "value";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "recruitment/mpp":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                    });
                                }

                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "recruitment/mpp-detail":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["mppDetailId"],
                                        name: result[arr]["positionName"],
                                        positionId: result[arr]["positionId"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "absence-type-by-employment-id":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["absenceTypeId"],
                                        name: result[arr]["period"] + " - " + result[arr]["absenceTypeName"],
                                        startDate: result[arr]["startDate"],
                                        endDate: result[arr]["endDate"],
                                        balanceEnd: result[arr]["balanceEnd"],
                                        absenceBalanceId: result[arr]["absenceBalanceId"],
                                        absenceTypeCategory: result[arr]["absenceTypeCategory"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "benefit/transaction-type":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        claimMethod: result[arr]["claimMethod"],
                                        hideBalance: result[arr]["hideBalance"],
                                        isAllowChild: result[arr]["isAllowChild"],
                                        isAllowSpouse: result[arr]["isAllowSpouse"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "pattern/detail":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["patternNo"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "recruitment/template":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["displayName"],
                                        calculationMethod: result[arr]["calculationMethod"],
                                        type: result[arr]["type"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "recruitment/hiring-phase":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        isInterview: result[arr]["interview"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "recruitment/applicant":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        applicantNo: result[arr]["applicantNo"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "import/type":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["type"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "employee/list/binding":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["employeeId"],
                                        name: result[arr]["fullName"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "import/module":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["module"],
                                        name: result[arr]["module"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "recruitment/job-requisition":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        contractPeriodMonths: result[arr]["contractPeriodMonths"],
                                        employmentType: result[arr]["employmentType"],
                                        jobCategory: result[arr]["jobCategory"],
                                        jobCategoryId: result[arr]["jobCategoryId"],
                                        jobLevel: result[arr]["jobLevel"],
                                        majority: result[arr]["majority"],
                                        minAge: result[arr]["minAge"],
                                        minEducationLevel: result[arr]["minEducationLevel"],
                                        minGpa: result[arr]["minGpa"],
                                        minWorkExperience: result[arr]["minWorkExperience"],
                                        nationality: result[arr]["nationality"],
                                        placement: result[arr]["placement"],
                                        preferredGender: result[arr]["preferredGender"],
                                        qualification: result[arr]["qualification"],
                                        salaryFrom: result[arr]["salaryFrom"],
                                        salaryTo: result[arr]["salaryTo"],
                                        vacancy: result[arr]["vacancy"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "employee-performance":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        displayName: result[arr]["displayName"],
                                        name: result[arr]["templateName"] + " - " + result[arr]["periodName"],
                                        currentManagerEmploymentId: result[arr]["employmentId"],
                                        currentManagerName: result[arr]["currentManagerName"],
                                        managerEvaluationDate: result[arr]["managerEvaluationDate"],
                                        selfEvaluationDate: result[arr]["selfEvaluationDate"],
                                        closingDate: result[arr]["closingDate"],
                                        selfWeight: result[arr]["selfWeight"],
                                        managerWeight: result[arr]["managerWeight"],
                                        status: result[arr]["status"],
                                        startDate: result[arr]["startDate"],
                                        endDate: result[arr]["endDate"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "element-classification":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        priorityStart: result[arr]["priorityStart"],
                                        priorityEnd: result[arr]["priorityEnd"],
                                        priorityLastUsed: result[arr]["priorityLastUsed"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "benefit-type-by-employment-id":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["benefitTypeId"],
                                        name: result[arr]["period"] + " - " + result[arr]["benefitTypeName"] + "" + (result[arr]["familyRelationship"] == null ? "" : " - " + result[arr]["familyRelationship"]) + "" + (result[arr]["familyName"] == null ? "" : " - " + result[arr]["familyName"]),
                                        balanceEnd: result[arr]["balanceEnd"],
                                        benefitBalanceId: result[arr]["benefitBalanceId"],
                                        startDate: result[arr]["startDate"],
                                        endDate: result[arr]["endDate"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "payroll-requests":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    if (result[arr]["status"] == "Completed") {
                                        this.dataMaster.push({
                                            id: result[arr]["id"],
                                            name: result[arr]["requestNo"] + " - " + result[arr]["payrollGroupName"] + " - " + result[arr]["runTypeName"],
                                            payrollGroupName: result[arr]["payrollGroupName"],
                                            periodStartDate: result[arr]["periodStartDate"],
                                            periodEndDate: result[arr]["periodEndDate"],
                                            runTypeName: result[arr]["runTypeName"],
                                        });
                                    }
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "company/bank":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["bankName"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "starfeed/category":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["categoryId"],
                                        name: result[arr]["categoryName"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "goal-plan":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["goalPlanId"],
                                        name: result[arr]["name"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "absence-type":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        greetingTemplate: result[arr]["greetingTemplate"],
                                        isNeedAttachment: result[arr]["isNeedAttachment"],
                                        name: result[arr]["name"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "goal-plan-by-employmentid":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["goalPlanId"],
                                        name: result[arr]["displayName"],
                                        empGoalPlanClosingDate: result[arr]["empGoalPlanClosingDate"],
                                        empGoalPlanId: result[arr]["empGoalPlanId"],
                                        goalPlanEndDate: result[arr]["goalPlanEndDate"],
                                        goalPlanStartDate: result[arr]["goalPlanStartDate"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "asset-type":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        category: result[arr]["category"],
                                        serialNo: result[arr]["serialNo"],
                                        tagNo: result[arr]["tagNo"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "element-type":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        recurring: result[arr]["recurring"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "chart-of-account":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        active: result[arr]["isActive"],
                                        segmentLevel: result[arr]["segmentLevel"],
                                        type: result[arr]["type"],
                                    });
                                }

                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "employment":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["employmentId"],
                                        employeeId: result[arr]["employeeId"],
                                        name: result[arr]["fullname"] + " - " + result[arr]["employeeNo"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "loan-type":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        interestRate: result[arr]["interestRate"],
                                        limitTermMonth: result[arr]["limitTermMonth"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindLabel = "name";
                                this.bindValue = "id";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            case "role":
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                        description: result[arr]["description"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                            default:
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        name: result[arr]["name"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;

                                this.parsingDataFocus();

                                break;
                        }
                    }
                },
                "lookup"
            );
        } else if (api == "content") {
            let paramTemp: any;
            if (typeof paramApi != "undefined" && paramApi != "" && paramApi != null) {
                paramTemp = paramApi;
            } else {
                if (typeof this.param != "undefined" && this.param != "" && this.param != null) {
                    paramTemp = this.param;
                } else {
                    paramTemp = "aod=" + this.aodTop + "&active=true";
                }
            }

            let splitParam = paramTemp.split("&");
            let findParam = splitParam.find((res) => {
                return res == "aod=undefined" || res == "employmentId=undefined";
            });
            if (findParam) {
                this.dataMaster = [];
                this.modelNgSelect = null;
                setTimeout(() => {
                    this.triggerFocus = false;
                }, 50);
                return;
            }

            switch (attr) {
                case "employee/secure":
                    this.httpLoaderRenderShow = true;

                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/admin/" + attr + "?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["id"],
                                        active: result[arr]["active"],
                                        name: result[arr]["employeeNo"] == null ? result[arr]["name"] : result[arr]["name"] + " - " + result[arr]["employeeNo"],
                                        employmentId: result[arr]["employmentId"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
                case "employee/secure_employment":
                    this.httpLoaderRenderShow = true;

                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/admin/employee/secure?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["employmentId"], //EMPLOYMENT
                                        active: result[arr]["active"],
                                        employeeId: result[arr]["id"],
                                        name: result[arr]["employeeNo"] == null ? result[arr]["name"] : result[arr]["name"] + " - " + result[arr]["employeeNo"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
                case "employee/secure_employment_name_only":
                    this.httpLoaderRenderShow = true;

                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/admin/employee/secure?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["employmentId"], //EMPLOYMENT
                                        active: result[arr]["active"],
                                        employeeId: result[arr]["id"],
                                        name: result[arr]["name"],
                                    });
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
                case "admin/competencies":
                case "admin/performance/competencies":
                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/" + attr + "?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                for (var key in result) {
                                    if (key == "data") {
                                        this.dataMaster = [];
                                        for (var arr in result[key]) {
                                            this.dataMaster.push({
                                                id: result[key][arr]["id"],
                                                name: result[key][arr]["name"],
                                                description: result[key][arr]["description"],
                                                ratingModelId: result[key][arr]["ratingModelId"],
                                                ratingModelName: result[key][arr]["ratingModelName"],
                                            });
                                        }
                                    }
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
                case "admin/career/lookup/assessment":
                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/" + attr + "?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        id: result[arr]["assessmentId"],
                                        name: result[arr]["assessmentName"],
                                        assessmentParticipantId: result[arr]["assessmentParticipantId"],
                                        employeeName: result[arr]["employeeName"],
                                        employmentId: result[arr]["employmentId"],
                                        evaluatorDetail: result[arr]["evaluatorDetail"].length <= 0 ? null : result[arr]["evaluatorDetail"],
                                        expirationDate: result[arr]["expirationDate"],
                                        requestNo: result[arr]["requestNo"],
                                        status: result[arr]["status"],
                                    });
                                }

                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "assessmentParticipantId";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
                case "admin/employee/talent-matrix/lookup-year":
                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/" + attr + "?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                this.dataMaster = [];
                                for (var arr in result) {
                                    this.dataMaster.push({
                                        label: result[arr]["label"],
                                        countData: result[arr]["countData"],
                                    });
                                }

                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "label";
                                this.bindLabel = "label";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
                default:
                    this.showLoading = true;
                    this.methodServices.getTenantApi(
                        "/api/" + attr + "?" + paramTemp,
                        (result, status) => {
                            if (status == "Success") {
                                for (var key in result) {
                                    if (key == "content" || key == "data") {
                                        this.dataMaster = [];
                                        for (var arr in result[key]) {
                                            this.dataMaster.push({
                                                id: result[key][arr]["id"],
                                                name: result[key][arr]["name"],
                                            });
                                        }
                                    }
                                }
                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                            }
                        },
                        "lookup"
                    );
                    break;
            }
        } else if (api == "dependent") {
            let paramTemp: any;
            if (typeof paramApi != "undefined" && paramApi != "" && paramApi != null) {
                paramTemp = paramApi;
            } else {
                if (typeof this.param != "undefined" && this.param != "" && this.param != null) {
                    paramTemp = this.param;
                } else {
                    paramTemp = "aod=" + this.aodTop + "&active=true";
                    if (typeof this.aodTop == "undefined" || this.aodTop == "" || this.aodTop == null) {
                        return;
                    }
                }
            }

            if (paramTemp == "id=" || paramTemp == "id=null") {
                this.modelNgSelect = null;
                this.dataMaster = [];
                return;
            }

            this.showLoading = true;
            this.methodServices.getTenantApi(
                "/api/v1/" + attr + "?" + paramTemp,
                (result, status) => {
                    if (status == "Success") {
                        switch (attr) {
                            case "pattern/detail":
                                this.dataMaster = [];
                                for (var key in result) {
                                    if (key == "patternDetails") {
                                        for (var arr in result[key]) {
                                            this.dataMaster.push({
                                                id: result[key][arr]["id"],
                                                name: result[key][arr]["patternNo"],
                                            });
                                        }
                                    }
                                }

                                if (this.valueDefault != "" && typeof this.valueDefault != "undefined") {
                                    this.modelNgSelect = this.valueDefault;
                                }

                                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                                this.bindValue = "id";
                                this.bindLabel = "name";
                                this.showLoading = false;
                                this.parsingDataFocus();
                                break;
                        }
                    }
                },
                "lookup"
            );
        }
    }

    ngSelectChange(event) {
        if (this.triggerChange && this.triggerClear) {
            //waktu msk handlerClearClick, akan menjalankan trigger change & clear

            if (this.firstLoadNgSelectOpenModalValid) {
                this.firstLoadNgSelectOpenModalValid = false;
            } else {
                this.firstLoadNgSelect = false;
            }

            this.ngSelectChange_Status = false;
            this.ngSelectChange_ValueIdPrev = null;
            this.ngSelectChange_NameIdPrev = null;

            if (typeof event["value"] != "undefined") {
                this.valueDefault = event.value;
            } else if (typeof event["id"] != "undefined") {
                this.valueDefault = event.id;
            }

            if (typeof event["name"] != "undefined") {
                this.placeholderLabel = event["name"];
            } else {
                this.placeholderLabel = this.langSelectAnOption;
            }

            this.placeholder = this.placeholderLabel;
            this.placeholderTempInvalid = this.placeholderLabel; // variabel temp parsing ke parsingdatafocus

            // default
            if (typeof event == "undefined") {
                this.lookupChange.emit({ keyjson: null, tipe: "change" });
            } else {
                this.lookupChange.emit({ keyjson: event, tipe: "change" });
            }
            // end default

            this.ngSelectChange_Status = true;
            this.ngSelectChange_ValueIdPrev = this.valueDefault;
            this.ngSelectChange_NameIdPrev = event["name"];
        }
    }

    ngOnChanges() {
        if (!this.firstLoad) {
            if (this.valueDefault == "" || this.valueDefault == null) {
                this.modelNgSelect = null;
                this.triggerChange = true;
                this.nativeNgSelect.handleClearClick();

                if (this.placeholder != this.langSelectAnOption) {
                    let find = this.dataMaster.find((result) => {
                        return result[this.bindValue] == this.placeholder;
                    });
                    if (find) {
                        this.placeholder = this.langSelectAnOption;
                    }
                }

                this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
            } else {
                if (this.dataMaster.length > 0 && this.bindValue != "" && typeof this.bindValue != "undefined") {
                    this.statusValueDefaultChanged = true;

                    let find = this.dataMaster.find((result) => {
                        return result[this.bindValue] == this.valueDefault;
                    });
                    if (!find) {
                        this.modelNgSelect = null;
                        // testing
                        this.placeholder = this.langSelectAnOption;
                        this.placeholderLabel = this.langSelectAnOption;

                        this.tempModelNgSelect = null;

                        this.triggerChange = false;
                        this.triggerClear = false;
                        this.nativeNgSelect.handleClearClick();
                        this.triggerChange = true;

                        this.placeholder = this.placeholderLabel;
                        this.placeholderTempInvalid = this.placeholderLabel;

                        this.statusValueDefaultChanged = false;
                    } else {
                        setTimeout(() => {
                            if (this.valueDefault != null) {
                                this.tempModelNgSelect = this.valueDefault;
                                this.modelNgSelect = this.valueDefault;
                            }

                            this.statusValueDefaultChanged = true;

                            this.triggerChange = false;
                            this.triggerClear = false;
                            this.nativeNgSelect.handleClearClick();
                            this.triggerChange = true;
                        }, 10);

                        setTimeout(() => {
                            this.placeholder = this.tempModelNgSelect;
                            this.placeholderTempInvalid = this.tempModelNgSelect;
                            this.modelNgSelect = this.tempModelNgSelect;
                        }, 50);

                        setTimeout(() => {
                            this.statusValueDefaultChanged = false;

                            if (this.ngSelectChange_Status) {
                                let event: any;

                                if (this.ngSelectChange_ValueIdPrev != this.valueDefault) {
                                    let eventTemp: any = "";
                                    let eventParse: any;

                                    let findItem = this.dataMaster.find((result) => {
                                        return result[this.bindValue] == this.valueDefault;
                                    });
                                    if (findItem) {
                                        for (var key in findItem) {
                                            eventTemp = eventTemp + ',"' + key + '":"' + findItem[key] + '"';
                                        }
                                        if (eventTemp.substr(0, 1) == ",") {
                                            eventTemp = eventTemp.substr(1, eventTemp.length);
                                        }
                                        if (eventTemp == "") {
                                            eventParse = null;
                                        } else {
                                            eventParse = JSON.parse("{" + eventTemp + "}");
                                        }
                                    } else {
                                        eventParse = null;
                                    }

                                    this.lookupChange.emit({ keyjson: eventParse, tipe: "change" });
                                }
                                this.ngSelectChange_Status = false;
                            }
                        }, 70);
                    }
                } else {
                    setTimeout(() => {
                        this.statusValueDefaultChanged = true;
                        if (this.placeholderLabel == null) {
                            this.placeholder = this.langSelectAnOption;
                            this.placeholderTempInvalid = this.langSelectAnOption;
                        }
                        this.modelNgSelect = null;
                        this.tempModelNgSelect = null;
                    }, 10);

                    setTimeout(() => {
                        if (this.placeholderLabel != null) {
                            this.placeholder = this.placeholderLabel;
                            this.placeholderTempInvalid = this.placeholderLabel;
                        }

                        this.statusValueDefaultChanged = false;

                        this.noListArrayWithPlaceholder = true;
                    }, 30);

                    setTimeout(() => {
                        if (this.statusValueDefaultAwal) {
                            this.lookupChange.emit({ skeleton: false, tipe: "skeleton" });
                            this.statusValueDefaultAwal = false;
                        }
                    }, 50);
                }
            }

            if (typeof this.aodTop != "undefined") {
                if (this.aodTop != this.aodTopPrev) {
                    this.aodTopPrev = this.aodTop;
                }
            }

            // PARAMETER (KONDISI KHUSUS)
            if (typeof this.param != "undefined") {
                if (this.param != this.paramPrev) {
                    if (this.param != null && this.param != "" && typeof this.param != "undefined" && this.param != "id=") {
                    }
                    this.paramPrev = this.param;
                }
            }

            if (typeof this.focusNgSelect != "undefined") {
                if (this.focusNgSelect) {
                    this.nativeNgSelect.open();
                } else {
                    this.nativeNgSelect.close();
                }
            }

            if (typeof this.manualOpenProc != "undefined") {
                if (this.manualOpenProc) {
                    this.openProc();
                }
            }

            // CASE DEPENDENT, HANYA KHUSUS EDIT
            if (typeof this.editShow != "undefined") {
                if (this.editShow) {
                    switch (this.lookup) {
                        case "pattern/detail":
                            this.dataMaster = this.arrInput;
                            this.bindValue = "id";
                            this.bindLabel = "name";
                            break;
                    }
                }
            }
        }
    }

    ngSelectClear(event) {
        if (!this.statusOpenProc) {
            if (this.modelNgSelect == null) {
                this.valueDefault = null;
                this.modelNgSelect = null;
                this.tempModelNgSelect = null;
                this.placeholder = "";
                this.placeholderLabel = "";
                this.placeholderTempInvalid = "";
                this.lookupChange.emit({ keyjson: null, tipe: "change" });
            }
        }

        if (this.triggerChange) {
            this.valueDefault = null;
            this.modelNgSelect = null;
            this.tempModelNgSelect = null;
            this.placeholder = "";
            this.placeholderLabel = "";
            this.placeholderTempInvalid = "";
            this.lookupChange.emit({ keyjson: null, tipe: "change" });
        }

        this.statusBackspace = false;
        this.triggerClear = true;
    }

    scrollProc(event) {}

    focusProc() {
        this.statusKeydown = false;
    }

    async openProc() {
        let tempVar: any;

        if (!this.triggerFocus && !this.statusKeydown) {
            this.statusOpenProc = true;

            if (this.noListArrayWithPlaceholder) {
                tempVar = this.valueDefault;
                this.placeholder = this.placeholderLabel;

                this.noListArrayWithPlaceholder = false;

                this.tempModelNgSelect = tempVar;
                this.valueDefault = tempVar;
            } else {
                if (this.modelNgSelect != null) {
                    tempVar = this.modelNgSelect;

                    this.placeholder = this.placeholderLabel;

                    this.tempModelNgSelect = tempVar;
                    this.valueDefault = tempVar;
                }
            }

            this.triggerChange = false; //untuk mencegah masuk ke ngSelectChange
            this.triggerClear = false;
            this.nativeNgSelect.handleClearClick(); // akan masuk ke ngselectchange & ngselectclear
            this.triggerChange = true;
            this.triggerFocus = true;

            if (this.tempModelNgSelect != null) {
                this.placeholder = this.placeholderLabel;
            } else {
                this.funcLangChangeSelectAnOption(this.langCur);
                this.placeholder = this.langSelectAnOption;
            }

            await this.callFunc(this.lookup);
            this.triggerFocus = true;
            this.statusKeydown = false;

            this.editableStatus = true;
        }

        if (this.size == "sm") {
            setTimeout(() => {
                let intStyle = document.getElementsByClassName("ng-dropdown-panel") as HTMLCollectionOf<HTMLElement>;
                intStyle.item(0).style.fontSize = "0.875rem";
            }, 50);
        }
    }

    blurProc() {
        if (!this.statusValueDefaultChanged) {
            this.focusNgSelect = false;
            if (this.placeholder != null && this.placeholder != this.langSelectAnOption) {
                if (this.placeholderLabel != null && typeof this.placeholderLabel != "undefined") {
                    this.placeholder = this.placeholderLabel;
                }
                return;
            }

            if (this.modelNgSelect == null) {
                this.tempModelNgSelect = null;
                this.valueDefault = null;
                this.placeholder = "";
            }
            this.statusValueDefaultChanged = false;
        }
    }

    closeProc() {}

    parsingDataFocus() {
        let indicatorDone: boolean = false;

        if (this.triggerFocus) {
            if (this.ngSelectChange_Status) {
                let event: any;

                if (this.ngSelectChange_ValueIdPrev != this.tempModelNgSelect) {
                    let eventTemp: any = "";
                    let eventParse: any;

                    let findItem = this.dataMaster.find((result) => {
                        return result[this.bindValue] == this.tempModelNgSelect;
                    });
                    if (findItem) {
                        for (var key in findItem) {
                            eventTemp = eventTemp + ',"' + key + '":"' + findItem[key] + '"';
                        }
                        if (eventTemp.substr(0, 1) == ",") {
                            eventTemp = eventTemp.substr(1, eventTemp.length);
                        }
                        if (eventTemp == "") {
                            eventParse = null;
                        } else {
                            eventParse = JSON.parse("{" + eventTemp + "}");
                        }
                    } else {
                        eventParse = null;
                    }
                    this.lookupChange.emit({ keyjson: eventParse, tipe: "change" });
                }
            }

            indicatorDone = false;

            if (this.dataMaster.length > 0) {
                let find = this.dataMaster.find((result) => {
                    return result[this.bindValue] == this.tempModelNgSelect;
                });
                if (find) {
                    this.placeholder = find[this.bindLabel];
                    this.placeholderLabel = find[this.bindLabel];
                    this.valueDefault = this.tempModelNgSelect;
                    this.modelNgSelect = this.tempModelNgSelect;
                } else {
                    // TAMBAHAN CEK LANGUAGE
                    let arrLang: any[] = ["Select an option ...", "Pilih opsi ...", "옵션을 선택하세요 ...", "เลือกตัวเลือก ..."];

                    switch (this.langCur) {
                        case "en":
                            this.langSelectAnOption = "Select an option ...";
                            break;
                        case "id":
                            this.langSelectAnOption = "Pilih opsi ...";
                            break;
                        case "kr":
                            this.langSelectAnOption = "옵션을 선택하세요 ...";
                            break;
                        case "th":
                            this.langSelectAnOption = "เลือกตัวเลือก ...";
                            break;
                    }

                    let findLang = arrLang.find((result) => {
                        return result == this.placeholder;
                    });

                    if (findLang) {
                        this.placeholderTempInvalid = this.langSelectAnOption;
                    }

                    this.placeholder = this.placeholderTempInvalid;
                    this.placeholderLabel = this.placeholderTempInvalid;
                }
                indicatorDone = true;
            }

            if (this.firstOpenWhenLoad) {
                setTimeout(() => {
                    this.nativeNgSelect.close();
                }, 5);
            }

            setTimeout(() => {
                if (!this.manualOpenProc) {
                    // UNTUK FALSE -> SECARA DEFAULT, TRUE -> EMPLOYEE PERSONAL -> POSITION -> JOB -> dst.. (tidak dibuka)
                    if (this.firstOpenWhenLoad) {
                        this.nativeNgSelect.open();
                        this.firstOpenWhenLoad = false;
                    }
                }

                this.manualOpenProc = false;
                this.triggerFocus = false;
            }, 20);
        }

        this.editableStatus = true;

        // cek status darkMode to change styles
        if (localStorage.getItem("GITDMOD") != null && typeof localStorage.getItem("GITDMOD") != "undefined" && localStorage.getItem("GITDMOD") != "") {
            const darkStatus = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITDMOD")).toString();
            if (darkStatus == "true") {
                document.getElementsByTagName("body")[0].classList.add("darkMode");

                this.methodServices.subject_DarkMode.next(true);
            } else {
                document.getElementsByTagName("body")[0].classList.remove("darkMode");
                this.methodServices.subject_DarkMode.next(false);
            }
        } else {
            document.getElementsByTagName("body")[0].classList.remove("darkMode");
            this.methodServices.subject_DarkMode.next(false);
        }

        if (this.isCustomStyle) {
            setTimeout(() => {
                let ngSelectDropdown = document.getElementsByTagName("ng-dropdown-panel");
                let ngSelectDropdownFinal = ngSelectDropdown[0];
                ngSelectDropdownFinal.classList.add(this.customStyle);
            }, 50);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }
}
