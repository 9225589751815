<ng-http-loader-global></ng-http-loader-global>

<div class="main-content-login d-flex align-items-center" [ngClass]="layoutStyle == 'NIGHT' ? 'night-mode' : ''">
  <div *ngIf="layoutStyle != 'NIGHT'" [ngClass]="layoutStyle == 'MORNING' ? 'sun-morning' : 'sun-sunset'"></div>
  <div class="moon" *ngIf="layoutStyle == 'NIGHT'">
    <svg xmlns="http://www.w3.org/2000/svg" width="267" height="352" viewBox="0 0 267 352" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.352737 26.8809C47.6996 31.0519 97.2087 52.6489 138.233 90.9313C220.286 167.5 238.095 282.539 179.865 351.475C202.672 341.57 223.862 326.922 241.908 307.583C312.28 232.17 380.588 91.9518 305.175 21.5794C239.182 -40.0025 84.9998 -27.6915 0.352737 26.8809Z" fill="url(#paint0_linear_6913_3134)"/>
      <defs>
        <linearGradient id="paint0_linear_6913_3134" x1="279.449" y1="-2.42685" x2="56.5676" y2="236.419" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFFEFC"/>
          <stop offset="1" stop-color="#D5A600"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div [ngClass]="layoutStyle == 'NIGHT' ? 'star' : 'cloud'"></div>
  <div class="bg-style"></div>
  <div class="web-people-circle"></div>
  <div class="shadow"></div>
  <div class="container-login-form">
    <div class="header-sc-logo">
      <img src="/assets/img/brand/starconnect-02.png" alt="StarConnect">
      <div class="subtitle-label">Connect with your employee</div>
    </div>
    <div class="content-login-form">
      <div class="title-form">Welcome,</div>
      <div class="subtitle-form">Start by Signing In</div>
      <div class="form-login">
        <form role="form" (ngSubmit)="login_page()" #f='ngForm'>
          <div class="text-center">
            <h5 *ngIf='error_msg != null' class='text-danger'> *{{ error_msg }} </h5>
          </div>
          <div class="form-group mb-3" [ngClass]="{ focused: focus === true }">
            <div class="input-group inputGroup">
              <span class="input-icon-left" style="border-right: 0;">
                <i class="ni ni-circle-08"></i>
              </span>
              <input placeholder="" type="text" name='username'
                (focus)="focus = true" (blur)="focus = false" (change)="clear_fault()" [(ngModel)]='user'
                #username='ngModel' required />
              <label for="username">Username</label>
            </div>
          </div>
          <div class="form-group" [ngClass]="{ focused: focus1 === true }">
            <div class="input-group inputGroup input-group-merge">
              <span class="input-icon-left" style="border-right: 0;">
                <i class="ni ni-lock-circle-open"></i>
              </span>
              <input placeholder="" name='password' [type]="showPass ? 'text' : 'password'"
                (focus)="focus1 = true" (blur)="focus1 = false" (change)="clear_fault()" [(ngModel)]='pass'
                #password='ngModel' required />
              <label for="password">Password</label>
              <span class="input-icon-right">
                <i class="fa"
                  [ngClass]="{
                    'fa-eye-slash' : !showPass,
                    'fa-eye' : showPass
                  }"
                  (click)="togglePassword()" style="cursor: pointer;">
                </i>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="pr-3">
                <label class="container-remember-me">
                  <input class="custom-control-input" id=" customCheckLogin" type="checkbox" name='remember_status'
                  [(ngModel)]='checked_status' #remember_status="ngModel" [checked]='checked_status' />
                  <svg viewBox="0 0 64 64" height="0.8em" width="0.8em">
                    <path d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16" pathLength="575.0541381835938" class="path"></path>
                  </svg>
                  <span>Remember me</span>
                </label>
              </div>
            </div>
            <div class="col">
              <div class="forgot-password d-none" style="float: right;">
                <label class="forgot-password-label" style="cursor: pointer;">
                  <span style="font-size: .875rem;">Forgot Password?</span>
                </label>
              </div>      
            </div>
          </div>
          <div class="text-center">
            <button type="submit" style="background: linear-gradient(244.52deg, #3D8FF0 20.47%, #3DC0F0 87.83%); box-shadow: 0px 0px 17px -2px #3DC0F0; border-radius: 10px;"
              class="btn btn-block btn-primary my-4" [disabled]=!f.valid>
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
