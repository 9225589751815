import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, TemplateRef, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MethodServices } from 'src/services/method-services';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DataTypeServices } from 'src/services/data-type-services';
import { Router } from '@angular/router';
import { configTable, dataInputType, element_global_type, subject_rows } from '../ngx-datatable-form-global/ngx-datatable-form-global';
import Swal from 'sweetalert2';

interface gfc_button_history_global {
    type:'global';
    url:any;
    // parameter => mengikuti api->edit->detail->param
}
interface gfc_button_history_emitter {
    type:'emitter';
}

// COL-INPUT
interface gfc_section_col_input_general {
    input_type:'text'|'date'|'checkbox'|'number';
    input_name:any;
    input_id:any;
    input_class_input?:any;
    input_class_formgroup?:any;
    input_label?:any;
    input_required?:boolean;
    input_maxlength?:any; 
    input_date_inputformat?:any;
    input_disabled?:boolean;
    input_aod?:boolean;
    input_hide?:boolean;        // untuk hide / display none pada element input (kondisi default)
    // setting-an default checkbox dengan status new & edit
    default_checkbox?:{
        stt_new?:boolean;
        stt_edit?:boolean;
    };

    input_apikey_get?:any;      // field key waktu get api
    input_apikey_post?:any;     // field key waktu post api
}[];

interface gfc_section_col_input_TextArea {
    input_type:'textarea';
    input_name:any;
    input_id:any;
    input_class_input?:any;
    input_class_formgroup?:any;
    input_label?:any;
    input_required?:boolean;
    input_maxlength?:any; 
    input_date_inputformat?:any;
    input_disabled?:boolean;
    input_aod?:boolean;
    input_hide?:boolean;        // untuk hide / display none pada element input (kondisi default)
    input_apikey_get?:any;      // field key waktu get api
    input_apikey_post?:any;     // field key waktu post api
    rows?:any;
    style?:any;   // style input textarea (e.g. "resize:none")
}
// ... <COL-INPUT>

// COL-INPUT 'ng-select-global'

interface gfc_NgSelectGlobal_valid_required {
    type:'input_required_to';
    read_value_as:'id'|'label';
    operator:'=='|'!=';       // tanda sama dengan atau tidak sama dengan, dengan ketentuan value yang dipilih. jika sesuai, maka di required
    value:any[];      // kondisi data "or" dalam array
    arr_input_required_to:any[];
}

interface gfc_section_col_input_NgSelectGlobal {
    input_type:'ng-select-global';
    input_name:any;           // name untuk global_value
    input_class_formgroup?:any;   // class form-group
    input_hide?:boolean;        // untuk hide / display none pada element input (kondisi default)
    input_apikey_get_id?:any;     // id atau valueDefault dari api get
    input_apikey_get_label?:any;  // label atau placeholder dari api get
    input_apikey_post_id?:any;    // nama id yang sesuai dengan api post
    status_aod_top?:boolean;    // parameter apakah perlu aod 
    lookup:any;     // nama lookup yang dipanggil
    value:any;       // id dari ng-select
    placeholderLabel:any;   // placeholder / label text ng-select
    input_disabled?:boolean;   // disabled ng-select
    gitLabel?:any;             // nama label yang ditampilkan
    gitRequired?:any;         // is ng-select required
    param?:any;               // parameter untuk hit api
    size?:any;                // is small (sm)
    ng_select_global:{
        id:any;     // nama key "id" yang akan dipakai dari response ng-select-global
        label:any;  // nama key "label" yang akan dipakai dari response ng-select-global
    },
    param_first_load?:{     // parameter yang di hit pada saat pertama kali di load
        api_key:{
            get_keyname:any;    // sumber data nama key dari api detail (misal : province)
            parsed_keyname:any;    // nama param key yang akan di set ke ng-select-global ini dan pada saat di open ("?province=Jakarta")
        }
    },
    dependent_to?:{     // value yang menjadi parameter untuk ng-select-global yang lain (misal: province -> city -> district -> subdistrict)
        null_value_n_label?:any[];    // kosongkan value dan placeholder pada ng_select_global (masukkan input_name sebagai value dalam array)
        clear_param?:{
            input_name:any;
            param_key:any;
        }[];   // hapus parameter tujuan input_name yang dimasukkan
        value:{
            input_name:any;
            param_key:any;
        }[];       // input_name yang mendapatkan parameter dependent
    }
    validation?:(gfc_NgSelectGlobal_valid_required)[];    // validasi tambahan jika ada, misal (tampilkan input_required jika tercapai suatu kondisi)
}[]
// ... <COL-INPUT>


interface gfc_form {
    section_type:'form';
    section_line:boolean;
    section_title?:any;
    section_show?:{
        history?:{
          id:any;
          show:boolean;
          disabled?:boolean;
          prop:{
              tableId:any;
              cellKeyId:any;
              configTable:any;
              tableName?:any;
              dataInput?:dataInputType[];
          };
          controller?:gfc_button_history_global | gfc_button_history_emitter;
        };
        delete?:{
          show:boolean;
          disabled?:boolean;
          controller?:gfc_button_delete_global;
        }
    };
    section_col?:{
        col_class?:any;
        col_input?:(gfc_section_col_input_general | gfc_section_col_input_NgSelectGlobal | 
                    gfc_section_col_input_TextArea)[];
    }[]
}[]

// CANCEL
interface gfc_button_cancel_global {
    type:'global';
    router_nav:any;
    stt_query_params?:boolean;    // query params biasa "keyjson" yang dilempar kembali ketika routing ke tabel
    aktif_table?:boolean;
    selected_active?:any;
}

interface gfc_button_cancel_emitter {
    type:'emitter';
}
// ... END CANCEL

// SUBMIT
interface gfc_button_submit_global {
  // type:'global';  
  url?:any;             // url api
  param?:{
      id_name?:any;     // nama id untuk post jika edit (e.g. 'locationGroupId')
  };
  stt_query_params?:boolean;    // query params biasa "keyjson" yang dilempar kembali ke tabel
  router_nav?:any;      // router navigate jika berhasil di submit
  aktif_table?:boolean; // this.methodServices.aktif_table.next(true)
}

// DELETE GLOBAL
interface gfc_button_delete_global {
  // type:'global';  
  url?:any;             // url api
  validation?:{
      id:{paramApi:any, label:any};     // set nama parameter "id", "label" => sebagai nama untuk ditampilkan ke toastr (misal : Location Group ID)
      aod?:{paramApi:any, label:any};    // set nama parameter "aod" kalau memang ada sebagai trigger untuk delete
  };
  router_nav?:any;      // router navigate jika berhasil di delete
  stt_query_params?:boolean;    // query params biasa "keyjson" yang dilempar kembali ke tabel
  aktif_table?:boolean; // this.methodServices.aktif_table.next(true)
}

interface submit_valid_compare_2_dates {
    // membandingkan 2 tanggal, end date harus lebih besar dari first date
    type:'compare_2_dates'; 
    input_name_first_date:any;
    input_name_end_date:any;
}

interface submit_valid_required_all_by_a_data {
    // semua input element harus di isi jika salah satu element input terisi
    // note : tidak merubah input_required masing-masing input
    // e.g. employee/employment
    type:'required_all_by_a_data'; 
    arr_input_name:any[];
    msg_error:any;
}


interface gfc_button {    // section untuk bagian button (misal: cancel & submit)
    section_type:'button';
    section_button?:
    {
        cancel?:{
            show:boolean,
            disabled?:boolean;
            controller?:gfc_button_cancel_global | gfc_button_cancel_emitter;
        },
        submit?:{
            show:boolean,
            disabled?:boolean;
            controller?:gfc_button_submit_global;
            validation?:(submit_valid_compare_2_dates | submit_valid_required_all_by_a_data)[];
        }
    }
}[]


interface gfc_ngx_datatable_data_global
{
  type:'global';    // sumber data dari api -> edit -> detail -> url
  apikey_get?:any;  // nama key dari detail api
  apikey_post?:any; // nama key yang akan di post
}

interface gfc_ngx_datatable {
  section_type:'ngx-datatable';
  source_data?:gfc_ngx_datatable_data_global; // jika data bukan dari global atau dari form children, maka tidak perlu define 'source_data' dan inject data pakai subject_rows
  cellKeyId:any;  // 'id'
  table:{
    id:any;       // id dari table global
    name?:any;    // label / judul table
    icon?:any;    // font awesome sebagai icon yang dipakai
  };
  header?:{
    statusCode?:number;   // 0
    statusKey?:any;       // e.g. 'active'
  };
  selectedActive?:any;    // e.g. 'Active'
  date?:{
    modelEffectiveDate?:any;
    modelEffectiveDateFinal?:any;
  };
  hideSortFilterTable?:any[];    // hide sort icon -> [0]
  dataParamsExport?:any | [];   // e.g. active (true), inactive (false), null
  export?:{
    apiGetData?:any;
    processName?:any;
    needAOD?:boolean;
    isDisabled?:boolean;
  };
  dataExists?:boolean;
  dataInput?:any;
  statusFinishedApi?:boolean;   // status finished api
  statusAODParamUrl?:boolean;
  statusDisabledDropdown?:boolean;  // disabled status dropdown in header (All, Active, Inactive)
  show?:{
      refreshButton?:boolean;   // show refresh button in header
      statusDropdown?:boolean;  // dropdown (All, Active, Inactive)
      exportButton?:boolean;    // show export button in header
      createButton?:boolean;    // show create button in header
  };
  avatar?:{
    divWidth?:any;  // width avatar
    fontSize?:any;  // font size avatar
  }
}[]

export type global_form_config = gfc_form | gfc_button | gfc_ngx_datatable;


interface gfc_prop {
    aod?:{
      show?:{
          stt_new?:boolean;     // to effectiveDate
          stt_edit?:boolean;    // to effectiveDate
      };
      edit?:{
          valid_match?:boolean;   // jika true, maka tanggal aod tidak boleh kurang / invalid dari tanggal effective date API
      }
    };
    api?:{
        edit?:{
          detail?:{
            url:any;
            param?:{
                id  :{locationPath?:any, locationPath_Key?:any, paramApi:any};    // locationPath : dari URL location, paramApi : nama id yang dilempar ke API
                aod ?:{locationPath?:any, locationPath_Key?:any, paramApi:any};
                employeeid?:{locationPath?:any, locationPath_Key?:any, paramApi:any};    // locationPath : dari URL location, paramApi : nama employeeId yang dilempar ke API
            };
          };
        }
    };
    hide?:{       // hide element tertentu jika suatu kondisi tercapai
        input_name_src:any;     // input name source  (e.g. checkbox dari 'Send Email' => Recruitment -> Hiring Phase)
        value:any;              // syarat value yang akan dihide jika match  (e.g. true)
        input_name_hide:any[];       // element yang dihide (e.g. ['email_template'])
        show?:{         // kondisi ketika false atau element ditampilkan
            input_name:any;             // input_name element yang akan ter-impact
            input_required?:boolean;    // apakah input element tersebut required
        }[]
    }[];
    focus_input_name?:any;    // focus ke input element ketika pertama kali di load
}

export type global_form_prop = gfc_prop;

interface subject_submit_prop{
    form_id:any;
    obj:any;
}
interface subject_delete_prop{
    form_id:any;
    obj:any;
}

interface subject_patch_prop{
    form_id:any;
    type:'disabled';
    arr_input_name:any[];
    stt_disabled:boolean; // true / false
}
interface subject_patch_focus_prop{
    form_id:any;
    type:'focus';
    input_name:any;   // input name yang akan di focus
}

interface subject_inject_data_prop{
    form_id:any;    // form id
    tableId:any;    // table id yang akan di inject
    type:'bulk_confirm';
    data:any[];
}

export const subject_form_submit:Subject<subject_submit_prop> = new Subject<subject_submit_prop>();
export const subject_form_delete:Subject<subject_delete_prop> = new Subject<subject_delete_prop>();

export const subject_form_patch:Subject<subject_patch_prop | subject_patch_focus_prop> = new Subject<subject_patch_prop | subject_patch_focus_prop>();
export const subject_form_inject_data:Subject<subject_inject_data_prop> = new Subject<subject_inject_data_prop>();

@Component({
    selector:'form-global',
    templateUrl:'form-global.html',
})


export class FormGlobal implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @HostListener('window:beforeunload',['$event'])
    canDeactivated(event:BeforeUnloadEvent){
        if (this.methodServices.statusChange == true){
            event.returnValue = true
        }
    }

    @ViewChildren("native_Input") native_Input:QueryList<ElementRef>;

    @Input() form_id:any;      // wajib di isi untuk form id
    @Input() form_global_stt:any;    // 'new' atau 'edit'
    @Input() global_form_config:any[];    // render element input
    @Input() global_form_prop:any;        // other setting property like 'aod','api'

    @Output() element_form_global = new EventEmitter<any>();
    @Output() elementGlobal = new EventEmitter<any>();        // khusus untuk ngx-datatable-form-global

    global_forms:global_form_config[];

    global_props:global_form_prop;

    // e.g. => global_value = { locationGroupName:{value:'Jakarta', dataSetIndex:0} }
    global_value:any = {};
    // e.g. => global_button = {cancel:{disabled:false,disabled_submit:true},submit:{disabled:false,disabled_submit:true}}
    global_button:any = {};

    ngUnsubscribe:Subject<any> = new Subject();

    aod_prev:any;
    aod_prev_first:any;
    aod_top:any;

    show_skeleton:boolean = true;
    skeleton_set_data_from_api:boolean = true;
    skeleton_ngselect_global:boolean = true;
    skeleton_ngxdatatable_global:boolean = true;

    concat_param_api:any = '';
    item_id:any = '';
    employee_id:any = '';

    // (ngx-datatable-form-global) HISTORY
    tableIdHistory:any = 'history';
    tableName:any = 'History';
    tableIcon:any = 'fa fa-sitemap';
    cellKeyId:any = 'id';
    configTable_History:any = [];

    statusFinishedApi:boolean = false;
    statusAODParamUrl:boolean = false;
    dataInputHistory:dataInputType[] = this.dataTypeServices.dataInputHistory;

      // SEMENTARA DI HARDCODE KE SETTING-AN DEFAULT showCloseButton:true
    configTable_History_Default:configTable = {
        modal:{
          showCloseButton:true
        }
    }

    @ViewChild('modalHistory') modalHistory:TemplateRef<any>;
    modalHistoryOptions = {
        keyboard:true,
        class:'modal modal-dialog-centered modal-lg',
        ignoreBackdropClick:false
    }
    tempHistory = []
    rowsHistory = []
    rowHistory:any;

    modalRef:BsModalRef;
    // ... end <history>
    
    // object aod_top & effectiveDate yang ter-identifikasi input_aod
    aod_vs_effdate:any;

    // object config submit yang terpilih / ter-click
    config_submit_selected:any;
    // object config delete yang terpilih / ter-click
    config_delete_selected:any;

    show_ngSelectGlobal:boolean = true;      // untuk hide - show ng-select-global pada saat load data
    status_NgSelectGlobal:boolean = false;    // cek apakah ada ng-select-global
    status_ngxDatatableFormGlobal:boolean = false;   // cek apakah ada ngx-datatable-form-global

    aodTopNgSelect:any;     // param aodTop untuk ng-select
    aod_top_ngSelect_exists:boolean = false;    // cek apakah ada setting-an "status_aod_top" pada ng-select-global

    status_submit:boolean = false;  // kondisi apakah lagi submit

    constructor(
          private router:Router,
          private dataTypeServices:DataTypeServices,
          private bsModalService:BsModalService,
          private methodServices:MethodServices
    ){

        window.scrollTo(0,0);

        subject_form_submit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result)=>{
            this.submitProc_Final(result);
        });

        subject_form_delete.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result)=>{
            this.deleteItem_Final(result);
        });

        subject_form_patch.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result)=>{
            this.updatePatch_Final(result);
        });

        subject_form_inject_data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result)=>{
          // inject data yang umumnya pada tipe 'bulk' di kondisi awal
          // terdapat kemungkinan object akan di transformasikan sebelum dimasukkan ke dalam table
          this.injectData_Final(result);
        })

    }

    injectData_Final(result){
        let result_type:any = result?.['type'];

        if (typeof result_type != 'undefined' && result_type != null && result?.['type'] == 'bulk_confirm'){

            subject_rows.next({tableId:result?.['tableId'], tipe:'bulk', data:[...result?.['data']]});
        }

    }

    updatePatch_Final(result:any){
        if (result?.['type'] == 'disabled'){
            if (typeof result?.['arr_input_name'] != 'undefined' &&
                  result?.['arr_input_name'].length > 0){

                  for (let item of result?.['arr_input_name']){
                      this.global_value[item]['input_disabled'] = result?.['stt_disabled'];
                      this.global_value[item]['input_disabled_submit'] = result?.['stt_disabled'];
                  }
            }
        }

        if (result?.['type'] == 'focus'){
          if (typeof result?.['input_name'] != 'undefined'){
              this.focus_proc();
          }
        }

    }
  
    disable_input_submit(disabled?:boolean, back_to_default?:boolean){

      if (typeof back_to_default != 'undefined' && back_to_default != null && back_to_default){
        // kembali ke kondisi default disabled
          for (var item in this.global_value){
              this.global_value[item]['input_disabled_submit'] = this.global_value[item]['input_disabled'];
          }

          for (var item in this.global_button){
              this.global_button[item]['disabled_submit'] = this.global_button[item]['disabled'];
          }
      }
      else
        // set disabled berdasarkan parameter 'disabled'
        for (var item in this.global_value){
            this.global_value[item]['input_disabled_submit'] = disabled;
        }
        for (var item in this.global_button){
            this.global_button[item]['disabled_submit'] = disabled;
        }

    }

    router_nav_func(controller:any){
      
        // QUERY PARAMS
        let query_params:any = controller?.['stt_query_params'];
        if (typeof query_params != 'undefined' && query_params != null &&
              query_params){

          // ambil value data dari keyjson
            let hasil_query_params:any = this.query_params_reEncrypt("keyjson");
            
            let router_nav_final:any = '';
            if (typeof controller['router_nav'] == 'object'){
                router_nav_final = controller['router_nav'].join("/")
            }
            else{
                router_nav_final = controller['router_nav']
            }

            this.router.navigate([router_nav_final],{
              queryParams:{
                keyjson: hasil_query_params
              }
            });

        }
        else{
          switch (typeof controller['router_nav']){
              case 'string':
                    this.router.navigate([controller['router_nav']]);
                    break;
              case 'object':
                    this.router.navigate(controller['router_nav']);
                    break;
          }
        }
    }

    submitProc_Final(result){
      // jika ada input_aod di salah satu element saja, maka akan trigger swal correction update
      
        let data_submit:any = JSON.parse(JSON.stringify(result?.['obj']?.['data']));
        // CONTROLLER SUBMIT
        let submit_api_controller_url:any = this.config_submit_selected?.['controller']?.['url']; // url api
        let submit_api_controller_routernav:any = this.config_submit_selected?.['controller']?.['router_nav']; // url api
        let submit_api_controller_activetable:any = this.config_submit_selected?.['controller']?.['active_table']; // url api
        // ... end <controller submit>

        if (this.form_global_stt == 'new'){
          data_submit['action'] = 'UPDATE';
        }

        // jika ada input_aod (trigger swal aod)
        if (typeof this.return_field_input_aod() != 'undefined' && this.return_field_input_aod() != null &&
            this.return_field_input_aod() != ''){
            
            if (this.aod_vs_effdate?.['aod'] != this.aod_vs_effdate?.['effdate']){
                this.methodServices.funcSwalGlobal("submit-cor-upd", (result)=>{

                    if (result.value){
                        data_submit['action'] = 'CORRECTION';
                        
                        this.saveData(submit_api_controller_url, data_submit,(result,msg)=>{
                            if (result == 'Success'){

                                  this.disable_input_submit(true);

                                  this.methodServices.showToast(msg,'success')
                                  setTimeout(()=>{
                                      // this.cancelBackStatus = true

                                      this.methodServices.statusChange = false;

                                      if (typeof submit_api_controller_routernav != 'undefined' && submit_api_controller_routernav != null){

                                          this.router_nav_func(this.config_submit_selected?.['controller']);
                                          // this.router.navigate(submit_api_controller_routernav);
                                      }

                                      if (typeof submit_api_controller_activetable != 'undefined' && submit_api_controller_activetable != null){
                                          this.methodServices.aktif_table.next(submit_api_controller_activetable);
                                      }

                                      // this.readOnlyInput = false
                                      this.disable_input_submit(false, true);
                                      
                                      this.status_submit = false;
                                      this.set_readOnly_dataInput_Local(false);

                                  },1800)

                            }
                            else{

                                this.disable_input_submit(true);

                                setTimeout(()=>{
                                  this.disable_input_submit(false, true);

                                  this.status_submit = false;
                                  this.set_readOnly_dataInput_Local(false);
                                },1800)
                            }
                            
                        })
                    }
                    else
                    // klik "UPDATE"
                    if (result.dismiss == Swal.DismissReason.cancel){
                        
                        data_submit['action'] = 'UPDATE';
                        data_submit['effectiveDate'] = this.methodServices.formatDate(new Date(this.aod_top),"yyyy-mm-dd");
                        
                        this.saveData(submit_api_controller_url, data_submit,(result,msg)=>{
                          if (result == 'Success'){
                              this.disable_input_submit(true);

                              this.methodServices.showToast(msg,'success');

                              setTimeout(()=>{
                                  this.methodServices.statusChange = false;

                                  if (typeof submit_api_controller_routernav != 'undefined' && submit_api_controller_routernav != null){
                                      
                                      this.router_nav_func(this.config_submit_selected?.['controller']);
                                      // this.router.navigate(submit_api_controller_routernav);
                                  }

                                  if (typeof submit_api_controller_activetable != 'undefined' && submit_api_controller_activetable != null){
                                      this.methodServices.aktif_table.next(submit_api_controller_activetable);
                                  }
                                  
                                  this.disable_input_submit(false, true);

                                  this.status_submit = false;
                                  this.set_readOnly_dataInput_Local(false);
                              },1800)
                          }
                          else{

                              this.disable_input_submit(true);

                              setTimeout(()=>{
                                this.disable_input_submit(false, true);

                                this.status_submit = false;
                                this.set_readOnly_dataInput_Local(false);
                              },1800)
                          }
                        })
                        
                        // this.readOnlyInput = false
                    }
                    
                })
            }
            else{
              // jika aod dan effDate sama, maka di-set correction
                if (this.form_global_stt != 'new'){
                  data_submit['action'] = 'CORRECTION';
                }else{
                  data_submit['action'] = 'UPDATE';
                }
                
                this.saveData(submit_api_controller_url, data_submit,(result,msg)=>{
                  if (result == 'Success'){
                      this.disable_input_submit(true);

                      this.methodServices.showToast(msg,'success');

                      setTimeout(()=>{
                          this.methodServices.statusChange = false;

                          if (typeof submit_api_controller_routernav != 'undefined' && submit_api_controller_routernav != null){

                              this.router_nav_func(this.config_submit_selected?.['controller']);
                              // this.router.navigate(submit_api_controller_routernav);
                          }

                          if (typeof submit_api_controller_activetable != 'undefined' && submit_api_controller_activetable != null){
                              this.methodServices.aktif_table.next(submit_api_controller_activetable);
                          }
                          
                          this.disable_input_submit(false, true);

                          this.status_submit = false;
                          this.set_readOnly_dataInput_Local(false);
                      },1800)
                  }
                  else{
                      // jika error, maka di kembalikan ke kondisi default untuk status disabled
                      this.disable_input_submit(true);

                      setTimeout(()=>{
                        this.disable_input_submit(false, true);

                        this.status_submit = false;
                        this.set_readOnly_dataInput_Local(false);
                      },1800)
                  }
                })
            }
            
        }
        else
        {

            if (this.form_global_stt != 'new'){   // edit
              data_submit['action'] = 'CORRECTION';
            }else{
              data_submit['action'] = 'UPDATE';
            }
                          
            this.saveData(submit_api_controller_url, data_submit,(result,msg)=>{
                if (result == 'Success'){

                      this.disable_input_submit(true);

                      this.methodServices.showToast(msg,'success')
                      setTimeout(()=>{
                          // this.cancelBackStatus = true
                          this.methodServices.statusChange = false;

                          if (typeof submit_api_controller_routernav != 'undefined' && submit_api_controller_routernav != null){

                              this.router_nav_func(this.config_submit_selected?.['controller']);
                              // this.router.navigate(submit_api_controller_routernav);
                          }

                          if (typeof submit_api_controller_activetable != 'undefined' && submit_api_controller_activetable != null){
                              this.methodServices.aktif_table.next(submit_api_controller_activetable);
                          }
                          // this.readOnlyInput = false
                          this.disable_input_submit(false, true);

                          this.status_submit = false;
                          this.set_readOnly_dataInput_Local(false);
                      },1800)

                }
            })
        }
    }

    saveData(url,dataObj,callback?){
        this.methodServices.postTenantApi(url,dataObj,(result,status)=>{
            if (status == 'Success'){
                callback('Success',result.message)
            }
            else{
                this.methodServices.showToast(this.dataTypeServices.getErrorMessageApi(result),'error');
                callback('Error')
            }
        })
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.global_forms = this.global_form_config;

        this.global_props = this.global_form_prop;

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    handle_aod(){


        // SIMPAN AOD SAAT INI / PREVIOUS
        if (typeof localStorage.getItem("GITAOD") != 'undefined' && localStorage.getItem("GITAOD") != null){
            this.aod_prev = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"));
            this.aod_prev_first = this.aod_prev;
        }

        let aod_trigger:boolean = false;
        
        if (this.form_global_stt == 'new'){
            if (typeof this.global_props?.['aod']?.['show']?.['stt_new'] != 'undefined' &&
                  this.global_props?.['aod']?.['show']?.['stt_new'] != null){

                  aod_trigger = this.global_props?.['aod']?.['show']?.['stt_new'];
                  
            }
        }
        else
        if (this.form_global_stt != 'new'){ // edit
            if (typeof this.global_props?.['aod']?.['show']?.['stt_edit'] != 'undefined' &&
                  this.global_props?.['aod']?.['show']?.['stt_edit'] != null){

                  aod_trigger = this.global_props?.['aod']?.['show']?.['stt_edit'];
            }
        }

        if (aod_trigger){

          this.methodServices.aod.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resultaod)=>{
                        
                  if (resultaod == 'Invalid Date'){
  
                      this.methodServices.showToast('Can\'t Input Invalid Date !','error')
                      setTimeout(()=>{
                          this.methodServices.aodPrev.next(this.aod_prev)
                          this.aod_top = this.aod_prev
                      },1800)
  
                      return
                  }
  
                  this.methodServices.funcSwalGlobal("change-aod", (result)=>{
                    
                    if (result.value){
                        
                        // SET AOD KE LOCAL STORAGE
                        this.methodServices.setAODToLocalStorage(resultaod)

                        this.aod_top = resultaod;
                        
                        // parameter aod_top_ngSelect untuk ng-select-global
                        if (this.aod_top_ngSelect_exists){

                            if (typeof this.aod_top == 'object'){
                              this.aodTopNgSelect = this.methodServices.formatDate(this.aod_top,"yyyy-mm-dd");
                            }else{
                              this.aodTopNgSelect = this.aod_top;
                            }

                        }else{
                            this.aodTopNgSelect = null;
                        }

                        // SET aodTopNgSelect ke dalam global_value
                        
                        for (var item_name in this.global_value){
                            // status_aod_top => untuk ng-select-global

                            let status_aod_top:boolean = this.global_value?.[item_name]?.['status_aod_top'];
                            if (typeof status_aod_top != 'undefined' && status_aod_top){

                                this.global_value[item_name]['aodTopNgSelect'] = this.methodServices.formatDate(resultaod,"yyyy-mm-dd");
                            }

                            // set tanggal langsung dari aodTop jika status new
                            // input_aod => untuk input_type = "date"
                            let input_aod:boolean = this.global_value?.[item_name]?.['input_aod'];
                            if (typeof input_aod != 'undefined' && input_aod != null && input_aod){

                              if (this.form_global_stt == 'new'){
                                if (!isNaN(resultaod)){
                                    this.global_value[item_name]['value'] = this.methodServices.formatDate(resultaod,"yyyy-mm-dd");
                                    this.global_value[item_name]['value_date'] = resultaod;
                                }
                              }
                            }
                            // ... <end>
                            
                        }
                        // ... 

                        if (this.form_global_stt != 'new'){   // edit

                            this.set_data_from_api_to_AOD(result, resultaod, true)
                        }
                        else{
                            // new
                            this.set_aod(this.global_value);

                            this.aod_prev = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"));

                        }
  
                    }
                    else
                    {   //DISMISS
                        this.methodServices.aodPrev.next(this.aod_prev)
                    }
                })
  
          })

        }

    }

    set_data_from_api_to_AOD(result:any, new_aod:any, set_aod_to_storage?:boolean){
        
        // KHUSUS UNTUK SET DATA KE GLOBAL VALUE DARI API (AOD SUBSCRIBE)

        let api_edit_detail_url:any = this.global_props?.['api']?.['edit']?.['detail']?.['url'];
        let api_edit_detail_param:any = this.global_props?.['api']?.['edit']?.['detail']?.['param'];

        // SET PARAMETER HIT API
        this.concat_param_api = '';

        // api_edit_detail_param => harus ter-registrasi
        
        if (typeof api_edit_detail_param != 'undefined' && api_edit_detail_param != null){

            for (var param in api_edit_detail_param){
                // param => aod, id, dst...
                if (typeof api_edit_detail_param?.[param] != 'undefined' && api_edit_detail_param?.[param] != null){

                    if (param == 'aod'){
                        let new_aod_final:any;

                        if (!isNaN(new_aod)){
                            new_aod_final = this.methodServices.formatDate(new_aod,"yyyy-mm-dd");
                        }
                        else{
                            new_aod_final = new_aod;
                        }

                        this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + new_aod_final;
                    }
                    else
                    if (param == 'id'){

                        this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + this.item_id;
                    }
                    
                }
            }

            if (this.concat_param_api.substring(0,1) == "&"){
                this.concat_param_api = "?" + this.concat_param_api.substring(1,this.concat_param_api.length);
            }

        }
        // ... end <set parameter hit api>


        if (typeof api_edit_detail_url != 'undefined' && api_edit_detail_url != null){
            
            this.skeleton_set_data_from_api = true
            this.skeleton_ngselect_global = true
            this.show_skeleton = true

            this.show_ngSelectGlobal = false;

            // HIT API
          
            this.methodServices.getTenantApi(api_edit_detail_url + this.concat_param_api,
            (result,status)=>{
                if (status == 'Success')
                {

                  // SET VALUE KE global_value terlebih dahulu
                    for (var key in this.global_value){

                        if (typeof result?.[this.global_value[key]['input_apikey_get']] != 'undefined'){
                          
                          if (this.global_value[key]['input_type'] == "date"){
                            
                            
                            this.global_value[key]['value'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']]) && 
                                                              result?.[this.global_value[key]['input_apikey_get']] != null

                                                  ? this.methodServices.formatDate(new Date(result?.[this.global_value[key]['input_apikey_get']]),"yyyy-mm-dd") 
                                                  : result?.[this.global_value[key]['input_apikey_get']] == 'Invalid Date' ? 
                                                      'Invalid Date'
                                                  : result?.[this.global_value[key]['input_apikey_get']];

                            this.global_value[key]['value_date'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']]) &&
                                                              result?.[this.global_value[key]['input_apikey_get']] != null

                                                  ? new Date(result?.[this.global_value[key]['input_apikey_get']])
                                                  : result?.[this.global_value[key]['input_apikey_get']] == 'Invalid Date' ? 
                                                      'Invalid Date'
                                                  : result?.[this.global_value[key]['input_apikey_get']];

                            // this.global_value[key]['value'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']])  
                            //                                         ? this.methodServices.formatDate(new Date(result?.[this.global_value[key]['input_apikey_get']]),"yyyy-mm-dd") 
                            //                                         : null
                            // this.global_value[key]['value_date'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']])  
                            //                                         ? new Date(result?.[this.global_value[key]['input_apikey_get']]) 
                            //                                         : null
                          }
                          else{
                            this.global_value[key]['value'] = result?.[this.global_value[key]['input_apikey_get']] ?? null 
                          }
                          
                        }

                        // value ng-select-global
                        // parsing value dan placeholderLabel
                        if (typeof result?.[this.global_value[key]['input_apikey_get_id']] != 'undefined'){

                            this.global_value[key]['value'] = result?.[this.global_value[key]['input_apikey_get_id']] ?? null 
                        }

                        if (typeof result?.[this.global_value[key]['input_apikey_get_label']] != 'undefined'){

                            this.global_value[key]['placeholderLabel'] = result?.[this.global_value[key]['input_apikey_get_label']] ?? null 
                        }
                        // ...


                        if (this.global_value[key]['input_type'] == "ng-select-global"){
                          // parsing parameter pertama kali load
                            this.parsing_param_ngSelectGlobal(key, result);
                        }
                        // ... <end ng-select-global>


                        // check Validation NgSelectGlobal (misal : required input)
                        if (this.global_value[key]['input_type'] == 'ng-select-global'){
                                
                              let ngSelectGlobal_id:any = this.global_value[key]?.['ng_select_global']?.['id'];
                              let ngSelectGlobal_label:any = this.global_value[key]?.['ng_select_global']?.['label'];

                              let obj_temp:any = {};
                              obj_temp[ngSelectGlobal_id] = result?.[this.global_value[key]['input_apikey_get_id']];
                              obj_temp[ngSelectGlobal_label] = result?.[this.global_value[key]['input_apikey_get_label']];

                              this.check_validation_ngSelectGlobal(this.global_value[key], obj_temp)
                        }

                        // jika section_type adalah ngx-datatable, maka cek apakah ambil data source dari (api - edit - detail - param)
                        if (this.global_value[key]['section_type'] == 'ngx-datatable'){
                          let source_data:any = this.global_value[key]?.['source_data'];

                          let table_id:any = this.global_value[key]?.['table']?.['id'];

                          if (typeof source_data != 'undefined' && source_data != null){

                                // jika type = 'global', maka dapat di proses
                                let source_data_type:any = source_data?.['type'];
                                let rows_temp:any[] = [];

                                if (typeof source_data_type != 'undefined' && source_data_type != null && source_data_type == 'global'){

                                    let source_data_apikey_get:any = source_data?.['apikey_get'];
                                    if (typeof source_data_apikey_get != 'undefined' && source_data_apikey_get != null){

                                        let data_temp:any =  result?.[source_data?.['apikey_get']];
                                        
                                        if (Array.isArray(data_temp)){
                                            if (data_temp.length > 0){

                                                let idx_rows:any = 0;
                                                let idx_temp:any = 0;
                                                rows_temp = data_temp.map((prop,idx)=>{
                                                    return {
                                                      ...prop,
                                                      indexRows:idx_rows++
                                                    }
                                                });

                                            }
                                        }

                                    }

                                }

                                this.global_value[key]['rows'] = rows_temp;
                                
                                // revisi
                                this.element_form_global.emit({form_id:this.form_id, tableId:table_id, type:'bulk_confirm', data:[...rows_temp]});

                                // subject_rows.next({tableId:table_id, tipe:'bulk', data:[...rows_temp]});
                              }
                            else{
                                
                                this.global_value[key]['rows'] = [];

                                subject_rows.next({tableId:table_id, tipe:'bulk', data:[]});
                          }
                        }
                        
                    }

                    // SKELETON
                    this.show_ngSelectGlobal = true;

                    // JIKA set_aod_to_storage = true, maka set ke local storage
                    if (typeof set_aod_to_storage != 'undefined' && set_aod_to_storage != null && set_aod_to_storage){

                        this.aod_prev_first = this.methodServices.formatDate(new_aod,"yyyy-mm-dd");
                        this.aod_prev = new_aod;
    
                        this.methodServices.setAODToLocalStorage(this.aod_prev);
                    }

                    // FILL DATA KE NATIVE ELEMENT
                    this.fill_data_to_nativeElement()

                    // SKELETON
                    this.skeleton_set_data_from_api = false;
                    this.skeleton_ngselect_global = false;
                    this.skeleton_ngxdatatable_global = false;
                    
                    this.focus_proc();

                    // let effDateNew = new Date(result['effectiveDate'])
    
                    // this.methodServices.effDate = this.methodServices.formatDate(effDateNew,'DD-MM-YYYY')

                }
                else{

                  // ERROR API
                    this.methodServices.showToast(this.dataTypeServices.getErrorMessageApi(result),'error')

                    this.skeleton_set_data_from_api = false;

                    // KEMBALIKAN KE TANGGAL SEBELUM NYA YANG MASIH VALID (JIKA ADA DI-SET DI PROP)
                    
                    if (typeof this.global_props?.['aod']?.['edit']?.['valid_match'] != 'undefined' &&
                          this.global_props?.['aod']?.['edit']?.['valid_match'] != null &&
                          this.global_props?.['aod']?.['edit']?.['valid_match'] == true){

                            // aod_prev_first => yyyy-mm-dd
                          this.aod_prev = this.aod_prev_first;
                    }
                    else{
                          this.aod_prev = new_aod;
                    }

                    if (isNaN(this.aod_prev)){
                        // jika bukan object tanggal, maka di konversi ke tanggal
                        try{
                            this.aod_prev = new Date(this.aod_prev);
                        }catch(e){
                            this.aod_prev = this.aod_prev_first;
                        }
                    }
                    
                    // this.aod_prev = new_aod;
                    
                    // JIKA set_aod_to_storage = true, maka set ke local storage
                    if (typeof set_aod_to_storage != 'undefined' && set_aod_to_storage != null && set_aod_to_storage){

                        this.methodServices.setAODToLocalStorage(this.aod_prev);
    
                        setTimeout(()=>{
                            this.methodServices.aodPrev.next(this.aod_prev)
                            this.aod_top = this.aod_prev
                        },100)
                    }

                    // this.methodServices.effDate = this.methodServices.formatDate(resultaod,'DD-MM-YYYY')
    
                    return
                }
            })
            // ... end <HIT API>

            this.check_skeleton();

        }
    }

    fill_data_to_nativeElement(){
        if (Object.keys(this.global_value).length > 0){
            for (var item in this.global_value){
                if (typeof this.global_value?.[item]?.['value'] != 'undefined' &&
                      this.global_value?.[item]?.['value'] != null){
                    
                    if (typeof this.global_value?.[item]?.['dataSetIndex'] != 'undefined'){

                        this.native_Input.toArray()[this.global_value?.[item]?.['dataSetIndex']].nativeElement.value = 
                                  this.global_value?.[item]?.['value'];
                    }
                }
            }
        }
    }

    set_data_from_api(){

        // ?keyjson=90fc63a667ba18ef402345ab0a4cc33d8efc273982d07cdb0e8ec9a5dc3ba4722c90a5e56b60636e7bf75ec043ec4e4984889e39c34ea91938e50be17a65c188d8ad94b69fea40ab45d4aac0c2a044325908fb10679cb24c5413398e598fd1faac8df358fc3a310c1fe8deef132e539add082f228ae26dabaa839c5f82f5bf00319ed337ae36f255e882255de83674861671bf0e89c98ae7500297aa928e1c6771bdc916f0f9810943d2dffab9ecf22e0595ad2cb664601aa4acc7f466a1fd2c556124d5c0052fca5ecf79b0c65f35905bfa5562
        let location_search:any = window.location.search.substring(1,window.location.search.length);
        let location_search_arr:any = location_search.split("&");   // [keyjson=123456ABC]
        
        let keyjson_obj:any;
        let other_obj:any;    // selain keyjson

        location_search_arr.forEach((ele,idx)=>{
            if (ele.split("=")[0] == 'keyjson'){
                let val_enc = ele.split("=")[1];
                let val_dec = this.methodServices.ivSha256Decrypt(val_enc);
                keyjson_obj = JSON.parse(val_dec);
            }
            else{
                let val_enc = ele.split("=")[1];
                try{
                  let val_dec = this.methodServices.ivSha256Decrypt(val_enc);
                  if (typeof val_dec == 'object'){
                      other_obj[ele.split("=")[0]] = JSON.parse(val_dec);
                  }
                  else{
                      other_obj[ele.split("=")[0]] = val_dec;
                  }
                }catch(e){
                    other_obj[ele.split("=")[0]] = null;
                }
            }
        })


        // SET PARAMETER HIT API
        this.concat_param_api = '';
        
        let api_edit_detail_url:any = this.global_props?.['api']?.['edit']?.['detail']?.['url'];
        let api_edit_detail_param:any = this.global_props?.['api']?.['edit']?.['detail']?.['param'];

        if (typeof api_edit_detail_param != 'undefined' && api_edit_detail_param != null){
            for (var param in api_edit_detail_param){
                // param => aod, id, dst...
                if (typeof api_edit_detail_param?.[param] != 'undefined' && api_edit_detail_param?.[param] != null){
                    if (api_edit_detail_param?.[param]?.['locationPath_Key'] == 'keyjson'){
                        
                        if (param == "aod"){
                            if (typeof localStorage.getItem("GITAOD") != 'undefined' && localStorage.getItem("GITAOD") != null){
                                this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"));
                            }
                        }
                        else
                        if (param == "id"){
                            this.item_id = keyjson_obj?.[api_edit_detail_param?.[param]?.['locationPath']];
                            this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + this.item_id;
                        }
                        else
                        if (param == "employeeid"){
                            this.employee_id = keyjson_obj?.[api_edit_detail_param?.[param]?.['locationPath']];
                            this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + keyjson_obj?.[api_edit_detail_param?.[param]?.['locationPath']];
                        }
                        else
                        if (typeof keyjson_obj?.[param] != 'undefined'){
                            this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + keyjson_obj?.[api_edit_detail_param?.[param]?.['locationPath']];
                        }
                        
                    }
                    else
                    {
                        this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + other_obj?.[api_edit_detail_param?.[param]?.['locationPath']];

                        if (param == "id"){
                          this.item_id = other_obj?.[api_edit_detail_param?.[param]?.['locationPath']];
                        }
                    }

                }
            }

            if (this.concat_param_api.substring(0,1) == "&"){
                this.concat_param_api = "?" + this.concat_param_api.substring(1,this.concat_param_api.length);
            }

        }
        // ... end <set parameter hit api>


        // api_edit_detail_url => harus ter-registrasi

        this.show_ngSelectGlobal = false;


        if (typeof api_edit_detail_url != 'undefined' && api_edit_detail_url != null){

              this.methodServices.getTenantApi(api_edit_detail_url + this.concat_param_api,
              (result,status)=>{
                  if (status == 'Success')
                  {

                      for (var key in this.global_value){

                          if (typeof result?.[this.global_value[key]['input_apikey_get']] != 'undefined'){
                            
                            if (this.global_value[key]['input_type'] == "date"){

                              
                              this.global_value[key]['value'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']]) && 
                                                                result?.[this.global_value[key]['input_apikey_get']] != null

                                                                      ? this.methodServices.formatDate(new Date(result?.[this.global_value[key]['input_apikey_get']]),"yyyy-mm-dd") 
                                                                      : result?.[this.global_value[key]['input_apikey_get']] == 'Invalid Date' ? 
                                                                          'Invalid Date'
                                                                      : result?.[this.global_value[key]['input_apikey_get']];

                              this.global_value[key]['value_date'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']]) &&
                                                                      result?.[this.global_value[key]['input_apikey_get']] != null

                                                                          ? new Date(result?.[this.global_value[key]['input_apikey_get']])
                                                                          : result?.[this.global_value[key]['input_apikey_get']] == 'Invalid Date' ? 
                                                                              'Invalid Date'
                                                                          : result?.[this.global_value[key]['input_apikey_get']];
                            }
                            else{
                              this.global_value[key]['value'] = result?.[this.global_value[key]['input_apikey_get']] ?? null 
                            }

                          }
                          else
                          if (this.global_value[key]['input_type'] == 'ng-select-global'){

                              if (typeof result?.[this.global_value[key]['input_apikey_get_label']] != 'undefined'){
                                
                                  this.global_value[key]['placeholderLabel'] = result?.[this.global_value?.[key]?.['input_apikey_get_label']] ?? null;
                              }
                              if (typeof result?.[this.global_value[key]['input_apikey_get_id']] != 'undefined'){
                                  this.global_value[key]['value'] = result?.[this.global_value[key]['input_apikey_get_id']] ?? null;
                              }

                              // Parsing parameter pertama kali saat di load
                              this.parsing_param_ngSelectGlobal(key, result)
                              // ... <end>

                          }

                          // check Validation NgSelectGlobal (misal : required input)
                          if (this.global_value[key]['input_type'] == 'ng-select-global'){
                                
                                let ngSelectGlobal_id:any = this.global_value[key]?.['ng_select_global']?.['id'];
                                let ngSelectGlobal_label:any = this.global_value[key]?.['ng_select_global']?.['label'];

                                let obj_temp:any = {};
                                obj_temp[ngSelectGlobal_id] = result?.[this.global_value[key]['input_apikey_get_id']];
                                obj_temp[ngSelectGlobal_label] = result?.[this.global_value[key]['input_apikey_get_label']];

                                this.check_validation_ngSelectGlobal(this.global_value[key], obj_temp)
                          }

                          // jika section_type adalah ngx-datatable, maka cek apakah ambil data source dari (api - edit - detail - param)
                          if (this.global_value[key]['section_type'] == 'ngx-datatable'){
                              let source_data:any = this.global_value[key]?.['source_data'];

                              let table_id:any = this.global_value[key]?.['table']?.['id'];

                              if (typeof source_data != 'undefined' && source_data != null){

                                  // jika type = 'global', maka dapat di proses
                                  let source_data_type:any = source_data?.['type'];
                                  let rows_temp:any[] = [];

                                  if (typeof source_data_type != 'undefined' && source_data_type != null && source_data_type == 'global'){

                                      let source_data_apikey_get:any = source_data?.['apikey_get'];
                                      if (typeof source_data_apikey_get != 'undefined' && source_data_apikey_get != null){

                                          let data_temp:any =  result?.[source_data?.['apikey_get']];
                                          
                                          if (Array.isArray(data_temp)){
                                              if (data_temp.length > 0){

                                                  let idx_rows:any = 0;
                                                  let idx_temp:any = 0;
                                                  rows_temp = data_temp.map((prop,idx)=>{
                                                      return {
                                                        ...prop,
                                                        indexRows:idx_rows++
                                                      }
                                                  });

                                              }
                                          }

                                      }

                                  }

                                  this.global_value[key]['rows'] = rows_temp;
                                  
                                  // revisi
                                  this.element_form_global.emit({form_id:this.form_id, tableId:table_id, type:'bulk_confirm', data:[...rows_temp]});

                                  // subject_rows.next({tableId:table_id, tipe:'bulk', data:[...rows_temp]});
                                }
                              else{
                                  
                                  this.global_value[key]['rows'] = [];

                                  subject_rows.next({tableId:table_id, tipe:'bulk', data:[]});
                              }
                          }
                          

                      }

                      this.hide_element(result);

                      // SKELETON

                      this.show_ngSelectGlobal = true;
                      
                      let timeout_skeleton:any = setTimeout(()=>{

                          this.skeleton_set_data_from_api = false;
                          this.skeleton_ngselect_global = false;
                          this.skeleton_ngxdatatable_global = false;

                          this.check_skeleton();
                          clearTimeout(timeout_skeleton);
                      },100)
                      
                      this.methodServices.statusChange = false;
                      
                  }
                  else{

                      this.show_ngSelectGlobal = true;

                      this.methodServices.showToast(this.dataTypeServices.getErrorMessageApi(result),'error')
                  }

              })

        }
        else{

            // SKELETON
            this.show_ngSelectGlobal = true;
            this.skeleton_set_data_from_api = false; 
            this.skeleton_ngselect_global = false;
            this.skeleton_ngxdatatable_global = false;

						this.check_skeleton();
        }

    }


    parsing_param_ngSelectGlobal(key:any, result:any){
        // key    => key dari input_name
        // result => object dari api

        let param_first_load_apikey:any = this.global_value?.[key]?.['param_first_load']?.['api_key'];
        if (typeof param_first_load_apikey != 'undefined' && param_first_load_apikey != null){
              let get_keyname:any = param_first_load_apikey?.['get_keyname'];
              let parsed_keyname:any = param_first_load_apikey?.['parsed_keyname'];

              let param_current:any = this.global_value?.[key]?.['param'];
              if (typeof param_current != 'undefined' && param_current != null && param_current != ''){

                  let arr_param:any = param_current.split("&");
                  let findIdx_parsed_keyname:any = arr_param.findIndex(result=>result.split("=")[0] == parsed_keyname);
                  if (findIdx_parsed_keyname != -1){
                      // jika ketemu, dihapus dahulu
                      arr_param.splice(findIdx_parsed_keyname, 1);
                  }
                  arr_param.push(parsed_keyname + "=" + result?.[get_keyname]);
                  this.global_value[key]['param'] = arr_param.join("&");
              }
              else
              {
                  this.global_value[key]['param'] = parsed_keyname + "=" + result?.[param_first_load_apikey?.['get_keyname']];
              }
        }
    }


    concat_urlparam_api_general(){
      // SET PARAMETER HIT API
      this.concat_param_api = '';
        
      let api_edit_detail_url:any = this.global_props?.['api']?.['edit']?.['detail']?.['url'];
      let api_edit_detail_param:any = this.global_props?.['api']?.['edit']?.['detail']?.['param'];

      if (typeof api_edit_detail_param != 'undefined' && api_edit_detail_param != null){
          for (var param in api_edit_detail_param){
              // param => aod, id, dst...
              if (typeof api_edit_detail_param?.[param] != 'undefined' && api_edit_detail_param?.[param] != null){

                      if (param == "aod"){
                        
                          let aod_top_temp:any = '';
                          if (!isNaN(this.aod_top)){
                              aod_top_temp = this.methodServices.formatDate(this.aod_top,"yyyy-mm-dd");
                          }
                          else{
                            aod_top_temp = this.aod_top;
                          }

                          this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + aod_top_temp;
                      }
                      
                      if (param == "id"){
                          this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + this.item_id;
                      }
                      if (param == "employeeid"){
                          this.concat_param_api += "&" + api_edit_detail_param?.[param]?.['paramApi'] + "=" + this.employee_id;
                      }

              }
          }

          if (this.concat_param_api.substring(0,1) == "&"){
              this.concat_param_api = "?" + this.concat_param_api.substring(1,this.concat_param_api.length);
          }

      }

      return this.concat_param_api;

    }

    ngOnInit(): void {
    
        this.global_forms = this.global_form_config;

        this.global_props = this.global_form_prop;


        this.fill_global_value_interval()
        
    }


    insert_hide_to_global_value(obj_prop:any){
        let hide:any = obj_prop?.['hide'];

        if (typeof hide != 'undefined' && hide != null){
            if (Array.isArray(hide)){
                for (let obj of obj_prop?.['hide'])
                {
                    if (typeof obj?.['input_name_src'] != 'undefined' && obj?.['input_name_src'] != null && obj?.['input_name_src'] != ''){
                        let global_value_valid:any = this.global_value?.[obj?.['input_name_src']];
                        if (typeof global_value_valid != 'undefined' && global_value_valid != null && global_value_valid != ''){
                            this.global_value[obj?.['input_name_src']]['hide'] = obj;
                        }
                    }
                }
            }
        }
    }


    fill_global_value_interval(){

        let setInt_fill_keyName = setInterval(()=>{
          if (typeof this.global_forms != 'undefined' && this.global_forms != null && this.global_forms.length > 0){
              // FILL DATA INPUT NAME FIRST, THEN VALUE FROM API
              this.global_value = {};
              this.fill_global_value_keyName(this.global_forms);

              if (!this.status_ngxDatatableFormGlobal){
                  this.skeleton_ngxdatatable_global = false;
              }

              // masukkan setting-an 'hide' object dari global_props ke dalam global_value
              this.insert_hide_to_global_value(this.global_props);
              
              if (this.form_global_stt != 'new'){   // edit
                this.set_data_from_api();
              }
              else{
                this.hide_element();

                // SET ALL TO FALSE FOR SKELETON (status = 'new')
                this.skeleton_set_data_from_api = false;
                this.skeleton_ngselect_global = false;
                this.skeleton_ngxdatatable_global = false;

                this.methodServices.statusChange = false;
              }

              clearInterval(setInt_fill_keyName);
          }
        })

        this.check_skeleton()

    }

    ngx_datatable_set_rows_temp_blank(){

      // buat blank data khusus untuk ngx-datatable
      for (var key in this.global_value){
          let section_type:any = this.global_value[key]?.['section_type'];
          if (typeof section_type != 'undefined' && section_type != null &&
              section_type == 'ngx-datatable'){

              this.global_value[key]['rows'] = [];

              subject_rows.next({tableId: this.global_value[key]?.['table']?.['id'], tipe:'bulk', data:[]});
          }
      }
    }

    check_skeleton(){
          // CHECK SKELETON
        let setInt_skeleton = setInterval(()=>{
          if (!this.skeleton_set_data_from_api &&
                !this.skeleton_ngselect_global &&
                !this.skeleton_ngxdatatable_global){

              let timeout_show:any = setTimeout(()=>{
                this.show_skeleton = false;

								this.methodServices.statusChange = false;
                window.scrollTo(0,0);
                clearTimeout(timeout_show);
              },100)

              clearInterval(setInt_skeleton);
          }
      })
    }

    changeProc(tipe:'change'|'keyup', col_input?:any, $event?:any){
      
      if (tipe == 'change' || tipe == 'keyup'){

          if (tipe == 'keyup'){
              if ($event.keyCode != 13){
                  return
              }
          }

          let input_name:any = col_input?.['input_name'];
          let input_dataSetIndex:any = this.global_value?.[col_input?.['input_name']]?.['dataSetIndex'];

          let result_input:any;
          if (typeof input_dataSetIndex != 'undefined' && input_dataSetIndex != null){

              result_input = this.native_Input.toArray()[input_dataSetIndex].nativeElement.value;
          }
          else{
              result_input = this.global_value[col_input?.['input_name']]?.['value'];
          }


          // SET KE GLOBAL VALUE UNTUK PERUBAHAN DATA
          if (typeof this.global_value?.[input_name] != 'undefined' && this.global_value?.[input_name] != null){
              this.methodServices.statusChange = true

              // HIDE ELEMENT
              let hide_temp:any = this.global_value?.[input_name]?.['hide'];
              let hide_temp_value:any = hide_temp?.['value'];
              let hide_temp_dest_arr:any = hide_temp?.['input_name_hide'];    // hide temp destination array
              
              let show_temp_arr:any = this.global_value?.[input_name]?.['hide']?.['show'];  // show (jika kondisi false)

              if (typeof hide_temp != 'undefined' && hide_temp != null){

                  for (var obj in this.global_value){
                      this.global_value[obj]['input_hide_change'] = this.global_value[obj]?.['input_hide'] ?? false;
                  };

                  if (hide_temp_value == result_input){
                      // KONDISI MATCH (TRUE)
                      for (let temp of hide_temp_dest_arr){
                          if (typeof this.global_value?.[temp] != 'undefined' && this.global_value?.[temp] != null){
                            this.global_value[temp]['input_hide_change'] = true;
                          }
                      }
                  }
                  else{

                    // KONDISI TIDAK MATCH (FALSE)
                    for (let temp of hide_temp_dest_arr){
                      if (typeof this.global_value?.[temp] != 'undefined' && this.global_value?.[temp] != null){
                        this.global_value[temp]['input_hide_change'] = false;
                      }
                    }

                    if (typeof show_temp_arr != 'undefined' && show_temp_arr != null){
                      // check kondisi show (kondisi sebaliknya dari hide)
                      for (let temp of show_temp_arr){

                        // cek dahulu apakah input_name dalam 'hide->show->input_name' sudah exists.

                        let global_value_input_name:any = this.global_value?.[temp?.['input_name']];
                        if (typeof global_value_input_name != 'undefined' && global_value_input_name != null){
                            let global_value_input_type:any = global_value_input_name?.['input_type'];
                            let input_required_temp:boolean = temp?.['input_required'] ?? false;

                            if (global_value_input_type == 'ng-select-global'){
                                this.global_value[temp?.['input_name']]['gitRequired'] = input_required_temp;
                                this.global_value[temp?.['input_name']]['value'] = null;
                                this.global_value[temp?.['input_name']]['placeholderLabel'] = null;
                            }
                            else{
                                // jika selain ng-select, maka pakai input_required
                                this.global_value[temp?.['input_name']]['input_required'] = input_required_temp;
                                this.global_value[temp?.['input_name']]['value'] = '';
                            }
                        }

                      }
                    }
                  }
              }
              // ... end <HIDE ELEMENT

              // this.global_value[key]['value_date'] = !isNaN(result?.[this.global_value[key]['input_apikey_get']])  
              //                                                       ? new Date(result?.[this.global_value[key]['input_apikey_get']]) 
              //                                                       : null

              if (this.global_value?.[input_name]?.['input_type'] == 'date'){
                this.global_value[input_name]['value'] = !isNaN($event) ? this.methodServices.formatDate($event,"yyyy-mm-dd") 
                                : $event == 'Invalid Date' ? 'Invalid Date' : '';
                this.global_value[input_name]['value_date'] = !isNaN($event) ? $event 
                                : $event == 'Invalid Date' ? 'Invalid Date' : '';;
              }
              else{
                this.global_value[input_name]['value'] = result_input;
              }

              if ((tipe == 'keyup' && $event.keyCode == 13) || tipe == 'change'){
                  this.element_form_global.emit({form_id:this.form_id, type:'change_input', data:this.global_value});
              }
          }
      }
        
    }

    hide_element(result_input?:any){

      // result_input => object dari api

      let result_input_temp:any;
      if (typeof result_input != 'undefined' && result_input != null){
          result_input_temp = result_input;
      }else{
          result_input_temp = this.global_value;
      }


      for (var input_name in result_input_temp){
        
          // HIDE ELEMENT
          let hide_temp:any = this.global_value?.[input_name]?.['hide'];
          let hide_temp_value:any = hide_temp?.['value'];     // sebagai master untuk trigger apakah sama valuenya
          let hide_temp_dest_arr:any = hide_temp?.['input_name_hide'];    // hide temp destination array

          if (typeof hide_temp != 'undefined' && hide_temp != null){
    
              for (var obj in this.global_value){
                  this.global_value[obj]['input_hide_change'] = this.global_value[obj]?.['input_hide'] ?? false;
              };

              if (hide_temp_value == this.global_value[input_name]?.['value']){
                  for (let temp of hide_temp_dest_arr){
                      if (typeof this.global_value?.[temp] != 'undefined' && this.global_value?.[temp] != null){
                        this.global_value[temp]['input_hide_change'] = true;
                      }
                  }
              }
              else{
                for (let temp of hide_temp_dest_arr){
                  if (typeof this.global_value?.[temp] != 'undefined' && this.global_value?.[temp] != null){
                    this.global_value[temp]['input_hide_change'] = false;
                  }
                }
              }
          }
      }

    }


    set_aod(obj:any){
        // obj dari "global_value"
        if (typeof obj != 'undefined' && obj != null && Object.keys(obj).length > 0){
          for (var item in obj){

                if (typeof obj?.[item]?.['input_aod'] != 'undefined'){
                  
                  if (this.form_global_stt == 'new'){

                      if (typeof this.global_props?.['aod']?.['show']?.['stt_new'] != 'undefined' && 
                            this.global_props?.['aod']?.['show']?.['stt_new'] != null &&
                            this.global_props?.['aod']?.['show']?.['stt_new'] == true){

                          this.global_value[item]['value'] = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"))
                      }

                  }
                  else{

                      if (typeof this.global_props?.['aod']?.['show']?.['stt_edit'] != 'undefined' && 
                            this.global_props?.['aod']?.['show']?.['stt_edit'] != null &&
                            this.global_props?.['aod']?.['show']?.['stt_edit'] == true){

                          this.global_value[item]['value'] = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"))
                      }

                  }
                }

            }
        }
    }



    // UNTUK MENG-INISIASI VALUE KE DALAM OBJECT 
    // e.g. => global_value = { locationGroupName:{value:'Jakarta', input_aod=true} }
    fill_global_value_keyName(obj:any[]){

        // input_disabled_submit => untuk disabled dalam kondisi submit

        Object.entries(obj).forEach(([k,v])=>{
            if (v instanceof Object){
              this.fill_global_value_keyName(v);
            }

            // UPDATE BUTTON DISABLED
            if (k == 'section_button'){
                if (typeof v?.['cancel'] != 'undefined' && v?.['cancel'] != null){
                  if (typeof v?.['cancel']?.['disabled'] != 'undefined' && v?.['cancel']?.['disabled'] != null){
                      this.global_button['cancel'] = {disabled: v?.['cancel']?.['disabled']};
                  }
                  else{
                      this.global_button['cancel'] = {disabled: false};
                  }
                }
                if (typeof v?.['submit'] != 'undefined' && v?.['submit'] != null){
                  if (typeof v?.['submit']?.['disabled'] != 'undefined' && v?.['submit']?.['disabled'] != null){
                      this.global_button['submit'] = {disabled: v?.['submit']?.['disabled']};
                  }
                  else{
                      this.global_button['submit'] = {disabled: false};
                  }
                }
            }

            if (k == 'section_show'){
                if (typeof v?.['history'] != 'undefined' && v?.['history'] != null){
                  if (typeof v?.['history']?.['disabled'] != 'undefined' && v?.['history']?.['disabled'] != null){
                      this.global_button['history'] = {disabled: v?.['history']?.['disabled']};
                  }
                  else{
                      this.global_button['history'] = {disabled: false};
                  }
                }
                if (typeof v?.['delete'] != 'undefined' && v?.['delete'] != null){
                  if (typeof v?.['delete']?.['disabled'] != 'undefined' && v?.['delete']?.['disabled'] != null){
                      this.global_button['delete'] = {disabled: v?.['delete']?.['disabled']};
                  }
                  else{
                      this.global_button['delete'] = {disabled: false};
                  }
                }
            }
            // ... end <UPDATE BUTTON>

            if (k == 'input_type' && v == 'date'){     
                // DATE
                this.global_value[obj['input_name']] = {value:'', input_type: obj?.['input_type']
                        , input_apikey_get: obj?.['input_apikey_get'] ?? null, input_apikey_post: obj?.['input_apikey_post'] ?? null
                        , input_required: obj?.['input_required'], input_label: obj?.['input_label']
                        , input_disabled: obj?.['input_disabled']
                        , input_disabled_submit: obj?.['input_disabled']
                        , input_hide: obj?.['input_hide']};

                if (typeof obj?.['input_aod'] != 'undefined'){
                    this.global_value[obj['input_name']]['input_aod'] = obj?.['input_aod'];
                }

            }
            else
            if (k == 'input_type' && (v == 'text' || v == 'number')){
                // TEXT 
                this.global_value[obj['input_name']] = {value:'', input_type: obj?.['input_type']
                        , input_apikey_get: obj?.['input_apikey_get'] ?? null, input_apikey_post: obj?.['input_apikey_post'] ?? null
                        , input_required: obj?.['input_required'], input_label: obj?.['input_label']
                        , input_disabled: obj?.['input_disabled']
                        , input_disabled_submit: obj?.['input_disabled']
                        , input_hide: obj?.['input_hide']};
            }
            else
            if (k == 'input_type' && (v == 'textarea')){
                // TEXTAREA
                this.global_value[obj['input_name']] = {
                          value:''
                        , input_type: obj?.['input_type']
                        , input_apikey_get: obj?.['input_apikey_get'] ?? null
                        , input_apikey_post: obj?.['input_apikey_post'] ?? null
                        , input_required: obj?.['input_required']
                        , input_label: obj?.['input_label']
                        , input_disabled: obj?.['input_disabled']
                        , input_disabled_submit: obj?.['input_disabled']
                        , input_hide: obj?.['input_hide']
                        , rows: obj?.['rows']};

                if (typeof obj?.['style'] != 'undefined'){
                    this.global_value[obj['input_name']]['style'] = obj?.['style'];
                }
            }
            else
            if (k == 'input_type' && (v == 'ng-select-global')){
                
                // NG-SELECT-GLOBAL
                this.global_value[obj['input_name']] = {
                        value:''
                        , input_type: obj?.['input_type']
                        , input_apikey_get_id: obj?.['input_apikey_get_id'] ?? null
                        , input_apikey_get_label: obj?.['input_apikey_get_label'] ?? null
                        , input_apikey_post_id: obj?.['input_apikey_post_id'] ?? null
                        , gitRequired: obj?.['gitRequired']
                        , gitLabel: obj?.['gitLabel']
                        , lookup: obj?.['lookup']
                        , placeholderLabel: obj?.['placeholderLabel']
                        , input_disabled: obj?.['input_disabled']
                        , input_disabled_submit: obj?.['input_disabled']
                        , param: obj?.['param']
                        , size: obj?.['size']
                        , input_hide: obj?.['input_hide']
                        , ng_select_global: obj?.['ng_select_global']
                        , validation: obj?.['validation']
                        , param_first_load: obj?.['param_first_load']
                };


                if (typeof obj?.['status_aod_top'] != 'undefined' && obj?.['status_aod_top'] != null &&
                      obj?.['status_aod_top']){

                    this.global_value[obj['input_name']]['status_aod_top'] = obj?.['status_aod_top'];
                    // this.aod_top_ngSelect_exists = true;

                    if (typeof localStorage.getItem("GITAOD") != 'undefined' &&
                        localStorage.getItem("GITAOD") != null){

                        this.global_value[obj['input_name']]['aodTopNgSelect'] = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"));
                    }
                }
                else{
                    this.global_value[obj['input_name']]['status_aod_top'] = false;
                    this.global_value[obj['input_name']]['aodTopNgSelect'] = null;
                    // this.aod_top_ngSelect_exists = false;
                }
            
                this.status_NgSelectGlobal = true;
            }
            else
            if (k == 'input_type' && v == 'checkbox'){
                // CHECKBOX
                if (this.form_global_stt == 'new'){

                    // NEW
                    if (typeof obj?.['default_checkbox']?.['stt_new'] != 'undefined'){
                      this.global_value[obj['input_name']] = { value: obj?.['default_checkbox']?.['stt_new'] };
                    }
                    else{
                      this.global_value[obj['input_name']] = {value:true};
                    }

                    this.global_value[obj['input_name']]['input_type'] = obj?.['input_type'];
                    this.global_value[obj['input_name']]['input_apikey_get'] = obj?.['input_apikey_get'] ?? null;
                    this.global_value[obj['input_name']]['input_apikey_post'] = obj?.['input_apikey_post'] ?? null;
                    this.global_value[obj['input_name']]['input_required'] = obj?.['input_required'];
                    this.global_value[obj['input_name']]['input_label'] = obj?.['input_label'];
                    this.global_value[obj['input_name']]['input_disabled'] = obj?.['input_disabled'];
                    this.global_value[obj['input_name']]['input_disabled_submit'] = obj?.['input_disabled'];
                    this.global_value[obj['input_name']]['input_hide'] = obj?.['input_hide'];
                }

                else{
                    // EDIT
                    if (typeof obj?.['default_checkbox']?.['stt_edit'] != 'undefined'){
                      this.global_value[obj['input_name']] = { value: obj?.['default_checkbox']?.['stt_edit'] };
                    }
                    else{
                      this.global_value[obj['input_name']] = {value:false};
                    }
                    
                    this.global_value[obj['input_name']]['input_type'] = obj?.['input_type'];
                    this.global_value[obj['input_name']]['input_apikey_get'] = obj?.['input_apikey_get'] ?? null;
                    this.global_value[obj['input_name']]['input_apikey_post'] = obj?.['input_apikey_post'] ?? null;
                    this.global_value[obj['input_name']]['input_required'] = obj?.['input_required'];
                    this.global_value[obj['input_name']]['input_label'] = obj?.['input_label'];
                    this.global_value[obj['input_name']]['input_disabled'] = obj?.['input_disabled'];
                    this.global_value[obj['input_name']]['input_disabled_submit'] = obj?.['input_disabled'];
                    this.global_value[obj['input_name']]['input_hide'] = obj?.['input_hide'];
                }
            }
            else
            if (k == 'section_type' && v == 'ngx-datatable'){

                let table_id:any = obj?.['table']?.['id'];

                this.status_ngxDatatableFormGlobal = true;

                if (typeof table_id != 'undefined' && table_id != null){
                    this.global_value[table_id] = obj;
                }
            }

        })

        return obj;
    }

    
    ngAfterViewInit(): void {
			
        this.nativeElement_setPointIndex();
  
        // AOD SUBSCRIBE
        this.handle_aod();
        // ... end <aod subscribe>

        // check AOD and set default date to (e.g. effective date)
        this.set_aod(this.global_value);

        if (typeof localStorage.getItem("GITAOD") != 'undefined' &&
              localStorage.getItem("GITAOD") != null){
              let aod_dec = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAOD"));
                
              this.aod_top = this.aod_prev;

              if (this.aod_top_ngSelect_exists){
                  this.aodTopNgSelect = this.aod_top;
              }else{
                  this.aodTopNgSelect = null
              }

              // set pertama kali input_aod dari aodTop
              try{
                  this.first_stt_new_set_input_aod(new Date(aod_dec));
              }catch(e){
                  this.methodServices.showToast("AOD error to be passed !","warning");
              }

              this.methodServices.aodPrev.next(aod_dec);

              // focus ke element ketika pertama kali di load
              setTimeout(()=>{
                this.focus_proc();
              },300)
        }

        if (this.form_global_stt == 'new'){
          
          // setTimeout(()=>{
            this.ngx_datatable_set_rows_temp_blank();
          // },100)
        }

    }

    focus_proc(){
      // focus ke element ketika pertama kali di load
      let focus_element:any = this.global_props?.['focus_input_name'];
      if (typeof focus_element != 'undefined' && focus_element != null && focus_element != ''){

          this.native_Input.toArray().forEach((ele,idx)=>{
    
              // ele.nativeElement.dataSetIndex = idx;
              if (ele.nativeElement.name == focus_element){
                  setTimeout(()=>{
                    ele.nativeElement.focus();
                  },300)
              }
          })
      }
    }

    first_stt_new_set_input_aod(param_aod:any){
      // param_aod => harus tipe "date"
        for (var item_name in this.global_value){

            // set tanggal langsung dari aodTop jika status new
            // input_aod => untuk input_type = "date"
            let input_aod:boolean = this.global_value?.[item_name]?.['input_aod'];
            if (typeof input_aod != 'undefined' && input_aod != null && input_aod){

              if (this.form_global_stt == 'new'){
                  if (!isNaN(param_aod)){
                      this.global_value[item_name]['value'] = this.methodServices.formatDate(param_aod,"yyyy-mm-dd");
                      this.global_value[item_name]['value_date'] = param_aod;
                  }
              }
            }
            // ... <end>
            
        }
    }

    nativeElement_setPointIndex(){
      this.native_Input.toArray().forEach((ele,idx)=>{
        
          ele.nativeElement.dataSetIndex = idx;

          if (typeof this.global_value?.[ele.nativeElement.name] != 'undefined'){
              this.global_value[ele.nativeElement.name]['dataSetIndex'] = idx
          }
      })
    }

    check_valid(config_submit?:any):boolean{
      // CEK BERDASARKAN INPUT_REQUIRED, jika true maka keluarkan pesan tidak valid
        let status_valid:boolean = true;

        for (var item in this.global_value){
            let stt_req:boolean = this.global_value?.[item]?.['input_required'];
            let stt_git_req:boolean = this.global_value?.[item]?.['gitRequired'];   // khusus ng-select-global
            let input_value:any = this.global_value?.[item]?.['value'];
            let input_type:any = this.global_value?.[item]?.['input_type'];
            let input_hide_change:any = this.global_value?.[item]?.['input_hide_change'] ?? false;
            
            // cek apa sudah terisi semua untuk input yang required
            if (stt_req || stt_git_req){

              // TYPE = 'DATE'
              if (typeof input_type == 'undefined' || input_type == null || input_type == 'date'){

                  if (typeof input_value == 'undefined' || input_value == null || input_value == 'Invalid Date'){
                      status_valid = false;
                      this.methodServices.showToast((this.global_value?.[item]?.['input_label'] + ' can\'t be Blank or Invalid'),'error');
                      break;
                  }
              }
              else{
                if (
                      input_type != 'checkbox' &&
                      (typeof input_value == 'undefined' || input_value == null || input_value == '')
                      && !input_hide_change){
  
                    let label_temp:any = this.global_value?.[item]?.['input_label'] ? this.global_value?.[item]?.['input_label'] :
                                            this.global_value?.[item]?.['gitLabel'];

                    status_valid = false;
                    this.methodServices.showToast((label_temp + ' can\'t be Blank or Invalid'),'error');
                    break;
                }
              }

            }
            // ... <>

            // cek apa ada yang INVALID DATE untuk type = 'date'
            if (typeof input_type == 'undefined' || input_type == null || input_type == 'date'){

                if (typeof input_value != 'undefined' && input_value != null && input_value == 'Invalid Date'){
                    status_valid = false;
                    this.methodServices.showToast((this.global_value?.[item]?.['input_label'] + ' can\'t be Blank or Invalid'),'error');
                    break;
                }
            }
            // ... <>

        }

        if (status_valid){
          // jika masih valid, maka di cek lagi
          if (typeof config_submit?.['validation'] != 'undefined' &&
                config_submit?.['validation'] != null){
                
                for (let obj of config_submit?.['validation']){
  
                    // COMPARE 2 DATES
                    if (typeof obj?.['type'] != 'undefined' && obj?.['type'] == 'compare_2_dates'){
                      
                          let input_name_first_date:any = this.global_value?.[obj?.['input_name_first_date']];
                          let input_name_end_date:any = this.global_value?.[obj?.['input_name_end_date']];
  
                        if (typeof input_name_first_date?.['value'] != 'undefined' && input_name_first_date?.['value'] != null && input_name_first_date?.['value'] != '' && input_name_first_date?.['value'] != 'Invalid Date'
                            &&
                            typeof input_name_end_date?.['value'] != 'undefined' && input_name_end_date?.['value'] != null && input_name_end_date?.['value'] != '' && input_name_end_date?.['value'] != 'Invalid Date')
                        {
                            if (new Date(input_name_first_date?.['value']).getTime() > new Date(input_name_end_date?.['value']).getTime()){
                                status_valid = false;
                                this.methodServices.showToast((input_name_end_date?.['input_label'] + ' must be greater than ' + input_name_first_date?.['input_label'] + ' !'),'error');
                                break;
                            }
                        }
  
                    }
  
                    if (status_valid){
                      // REQUIRED ALL BY A DATA
                      if (typeof obj?.['type'] != 'undefined' && obj?.['type'] == 'required_all_by_a_data'){
                          let arr_input_name:any = obj?.['arr_input_name'];
                          let msg_error:any = obj?.['msg_error'];
    
                          let input_value_exists:boolean = false;   // jika ada satu saja value yang terisi, maka di set "true"
                          let all_data_exists:boolean = true;   // cek apakah semua data dari arr_input_name terisi atau tidak
    
                          if (typeof arr_input_name != 'undefined' && arr_input_name != null){
                              for (let item of arr_input_name){
                                let value_temp:any = this.global_value?.[item]?.['value'];
                                if (typeof value_temp != 'undefined' && value_temp != null){
    
                                    input_value_exists = true;
                                }
                                else{
                                    all_data_exists = false;
                                }
                              }
                          }
    
                          // jika input_value_exists = true, maka all_data_exists harus true
                          // kesimpulan : satu input_value terisi, maka semua input element harus terisi
                          if (input_value_exists){
                              if (!all_data_exists){
                                status_valid = false;
                                this.methodServices.showToast(msg_error, 'error');
                                break;
                              }
                          }
                      }
                    }
  
  
                }
            }
        }

        return status_valid;
    }

    deleteItem(config_delete?:any){

        // set ke variable untuk config_delete yang ter-select
        this.config_delete_selected = JSON.parse(JSON.stringify(config_delete));

        // RETURN nama field yang ada input_aod
        let aod_exists_label:any = this.return_field_input_aod();
        let aod_first_value:any = this.global_value?.[aod_exists_label]?.['value'];

        let validation_id:boolean = config_delete?.['controller']?.['validation']?.['id'];
        let validation_id_label:boolean = config_delete?.['controller']?.['validation']?.['id']?.['label'];
        let aod_id:boolean = config_delete?.['controller']?.['validation']?.['aod'];
        let aod_id_label:boolean = config_delete?.['controller']?.['validation']?.['aod']?.['label'];
        let obj_del:any = {};

        obj_del["action"] = "DELETE";

        if (typeof validation_id != 'undefined' && validation_id != null){
            if (typeof this.item_id != 'undefined' && this.item_id != null && this.item_id != ''){
                obj_del[validation_id?.['paramApi']] = this.item_id;
            }
            else{
                this.methodServices.showToast(validation_id_label + " doesn\'t Exists !", "error")
                return;   
            }
        }

        if (typeof aod_id != 'undefined' && aod_id != null){
            if (typeof aod_first_value != 'undefined' && aod_first_value != null && aod_first_value != ''){
                obj_del[aod_id?.['paramApi']] = aod_first_value;
            }
            else{
                this.methodServices.showToast(aod_id_label + " doesn\'t Exists !", "error")
                return;
            }
        }

        this.element_form_global.emit({form_id:this.form_id, type:'delete', data: obj_del});
    }

    deleteItem_Final(result:any){
      // jika ada input_aod di salah satu element saja, maka akan trigger swal correction update
      
        let data_delete:any = JSON.parse(JSON.stringify(result?.['obj']?.['data']));
        // CONTROLLER SUBMIT
        let delete_api_controller_url:any = this.config_delete_selected?.['controller']?.['url']; // url api
        let delete_api_controller_routernav:any = this.config_delete_selected?.['controller']?.['router_nav'];
        let delete_api_controller_activetable:any = this.config_delete_selected?.['controller']?.['active_table'];
        // ... end <controller submit>

        this.methodServices.funcSwalGlobal("delete-cor-upd", (result)=>{
          if (result.value){
              this.saveData(delete_api_controller_url, data_delete,(result,msg)=>{
                  if (result == 'Success'){

                      this.disable_input_submit(true);

                      this.methodServices.showToast(msg,'success')
                      setTimeout(()=>{
                          // this.cancelBackStatus = true
                          this.methodServices.statusChange = false

                          if (typeof delete_api_controller_routernav != 'undefined' && delete_api_controller_routernav != null){
                              
                            this.router_nav_func(this.config_delete_selected?.['controller']);
                              // this.router.navigate(delete_api_controller_routernav)
                          }

                          if (typeof delete_api_controller_activetable != 'undefined' && delete_api_controller_activetable != null){
                              this.methodServices.aktif_table.next(delete_api_controller_activetable)
                          }

                          this.disable_input_submit(false, true);
                      },1800)
                      
                  } else {
                      this.disable_input_submit(true);

                      setTimeout(()=>{
                        this.disable_input_submit(false, true);
                      },1800)
                  }
              })
          } else {

                this.disable_input_submit(false, true);
          }
        })

    }

    submitProc(config_submit?:any){
        // jika tidak valid, maka return
        if (!this.check_valid(config_submit)){
            return;
        }

        this.status_submit = true;
        this.set_readOnly_dataInput_Local(true);

        // set ke variable untuk config_submit yang ter-select
        this.config_submit_selected = JSON.parse(JSON.stringify(config_submit));

        let obj_post_temp:any = {};
        
        if (typeof this.global_value != 'undefined' && this.global_value != null && Object.keys(this.global_value).length > 0){

            for (var item in this.global_value){
                let apikey_post:any = this.global_value[item]?.['input_apikey_post'];
                let apikey_post_id:any = this.global_value[item]?.['input_apikey_post_id'];

                if (typeof apikey_post != 'undefined' && apikey_post != null){
                  obj_post_temp[this.global_value[item]?.['input_apikey_post']] = this.global_value[item]?.['value']
                }
                if (typeof apikey_post_id != 'undefined' && apikey_post_id != null){
                  obj_post_temp[this.global_value[item]?.['input_apikey_post_id']] = this.global_value[item]?.['value']  // ng-select-global
                }

                // ngx-datatable
                let section_type:any = this.global_value[item]?.['section_type'];
                if (typeof section_type != 'undefined' && section_type != null && section_type == 'ngx-datatable'){
                    obj_post_temp[this.global_value[item]?.['source_data']?.['apikey_post']] = this.global_value[item]?.['rows'];
                }
                // ... <end>

            }
        }

        let submit_param_id = config_submit?.['controller']?.['param']?.['id_name'];
        
        if (this.form_global_stt != 'new'){   // edit

          if (typeof submit_param_id != 'undefined' && submit_param_id != null){

              // SET id (e.g. locationGroupId)
              obj_post_temp[submit_param_id] = this.item_id;

              // CEK APAKAH aod_top dengan effectiveDate berbeda. jika sama, maka set action = 'CORRECTION'
              if (typeof this.return_field_input_aod() != 'undefined' && this.return_field_input_aod() != null){
                  if (!this.check_aod_diff()){
                    // jika tanggal aod dan effectiveDate sama, maka diset langsung correction
                      obj_post_temp['action'] = 'CORRECTION';
                  }
              }

          }else{
            this.methodServices.showToast('(Submit -> Controller -> Param -> id_name) not configured !', 'warning')
          }
        }
        
        let obj_post_final:any;

        // JIKA ada input_aod, maka ditambah satu object aod_vs_effdate
        if (typeof this.return_field_input_aod() != 'undefined' && this.return_field_input_aod() != null){

            // SET aod_top ke format yyyy-mm-dd
            let aod_top_convert:any;

            if (!isNaN(this.aod_top)){
                aod_top_convert = this.methodServices.formatDate(this.aod_top,"yyyy-mm-dd");
            }else{
                aod_top_convert = this.aod_top;
            }
            // ... end <set aod_top_convert>

            this.aod_vs_effdate = {
                      aod: aod_top_convert,
                      effdate: this.global_value?.[this.return_field_input_aod()]?.['value']
            };

            obj_post_final = {form_id:this.form_id, type:'submit', data: obj_post_temp
                  , aod_vs_effdate: this.aod_vs_effdate
            };

        }else{
            obj_post_final = {form_id:this.form_id, type:'submit', data: obj_post_temp};
        }

        this.element_form_global.emit(obj_post_final);

    }


    return_field_input_aod(){
      // RETURN nama field yang ada input_aod
        let item_temp:any;
        for (var item in this.global_value){
            if (this.global_value?.[item]?.['input_type'] == 'date' && this.global_value?.[item]?.['input_aod']){
                item_temp = item;
                break;
            }
        }
        return item_temp;
    }

    check_aod_diff(){

        // cek input_aod pada global_value
        
        let item_temp:any;
        for (var item in this.global_value){
            if (this.global_value?.[item]?.['input_type'] == 'date' && this.global_value?.[item]?.['input_aod']){
                item_temp = item;
                break;
            }
        }

        if (typeof item_temp != 'undefined' && item_temp != null){

            // SET aod_top ke format yyyy-mm-dd
            let aod_top_convert:any;

            if (!isNaN(this.aod_top)){
                aod_top_convert = this.methodServices.formatDate(this.aod_top,"yyyy-mm-dd");
            }else{
                aod_top_convert = this.aod_top;
            }
            // ... end <set aod_top_convert>

            if (aod_top_convert != this.global_value?.[item_temp]?.['value']){
                return true;
            }
            else{
                return false;
            }
        }
    }

    query_params_reEncrypt(get_key:any){
      // e.g. "get_key" => "keyjson"

      let location_search_single:any;   // misal 'keyjson'

      let location_search:any = window.location.search;
      let location_search_arr_temp:any = [];
      if (location_search.length > 1 && location_search.substring(0,1) == "?"){
          location_search = location_search.substring(1,location_search.length);
      }

      let location_search_str_final:any;

      // jika ketemu &, maka di split
      if (location_search.search("&") > -1){

          let location_search_arr_final:any = [];

          location_search_arr_temp = location_search.split("&");
          for (let item of location_search_arr_temp){
              let item_temp:any = item.split("=")[0] + "=" 
                    + this.methodServices.ivSha256Encrypt(this.methodServices.ivSha256Decrypt(item.split("=")[1]));

              location_search_arr_final.push(item_temp);

              // contoh 'keyjson'
              if (item.split("=")[0] == get_key){
                  location_search_single = this.methodServices.ivSha256Encrypt(this.methodServices.ivSha256Decrypt(item.split("=")[1]));
              }
          }

          location_search_str_final = location_search_arr_final.join("&");
      }
      else
      if (location_search != ''){
          if (location_search.search("=") > -1){
            location_search_str_final = location_search;

            location_search_single = 
                  this.methodServices.ivSha256Encrypt(this.methodServices.ivSha256Decrypt(location_search.split("=")[1]));
          }
          else{
            location_search_str_final = location_search;
            location_search_single = location_search;
          }
      }
      else
      if (location_search == ''){
          location_search_str_final = '';
          location_search_single = '';
      }

      return location_search_single;
    }

    backCancel(controller:any){

      if (typeof controller?.['type'] != 'undefined' &&
          controller?.['type'] != null && controller?.['type'] == 'global') {

            if (typeof controller?.['router_nav'] != 'undefined' &&
                  controller?.['router_nav'] != null){
                
                this.router_nav_func(controller);
                
            }
      
            if (typeof controller?.['aktif_table'] != 'undefined' &&
                  controller?.['aktif_table'] != null){
      
                this.methodServices.aktif_table.next(controller?.['aktif_table'])
            }
      
            if (typeof controller?.['selected_active'] != 'undefined' &&
                  controller?.['selected_active'] != null){
      
                this.methodServices.selectedActive = controller?.['selected_active']
            }
      }
      else
      if (typeof controller?.['type'] != 'undefined' &&
          controller?.['type'] != null && controller?.['type'] == 'emitter') {

          this.element_form_global.emit({form_id:this.form_id, type:'cancel'})
      }


    }

    
    historyProc(config_history:any){
      
      let config_controller_type:any = config_history?.['controller']?.['type'];
      if (typeof config_controller_type != 'undefined' && config_controller_type != null) {
        
          if (config_controller_type == 'global'){

            this.tableIdHistory = config_history?.['prop']?.['tableId'];
            this.tableName = config_history?.['prop']?.['tableName'];
            this.cellKeyId = config_history?.['prop']?.['cellKeyId'];
            this.configTable_History = JSON.parse(JSON.stringify(config_history?.['prop']?.['configTable']));
            this.dataInputHistory = JSON.parse(JSON.stringify(config_history?.['prop']?.['dataInput']));

            this.showModal(this.modalHistory,this.modalHistoryOptions, config_history)
            
            setTimeout(()=>{
                let hideArrow = document.getElementsByClassName('hide_arrow')
                for (var i=0;i<hideArrow.length;i++){
                    if (document.body.contains(hideArrow[i].nextSibling.nextSibling.nextSibling)){
                        hideArrow[i].nextSibling.nextSibling.nextSibling.remove()
                    }
                }  
            })

          }
          else
          if (config_controller_type == 'emitter'){

              this.element_form_global.emit({form_id:this.form_id, type:'history', config_history: config_history});
          }
      }
    }

    
    showModal(modal:TemplateRef<any>,modalOption, config_history:any){
      if (this.modalRef){
          this.modalRef.hide()
      }
      
      this.modalRef = this.bsModalService.show(modal,modalOption)
      document.body.classList.remove('modal-open')    //HIDE WINDOW SCROLL
      
      this.methodServices.getTenantApi(config_history?.['controller']?.['url'] + this.concat_urlparam_api_general()
                          ,(result,status)=>{
          if (status == 'Success'){
              this.tempHistory = []
              this.rowsHistory = []
              for (var arr in result){
                  let effDate = new Date(result[arr]['effectiveDate'])
                  let effDateFinal = this.methodServices.formatDate(effDate,'dd-MMM-yyyy')

                  
                  this.rowsHistory.push({effectiveDate:effDateFinal,
                                  active: result[arr]['active'] ? 'Yes' : 'No',
                                  name: result[arr]['name'],
                                  description: result[arr]['description'],
                                  modifiedBy: result[arr]['modifiedBy']==null ? '' : result[arr]['modifiedBy'],    
                                  modifiedDate: result[arr]['modifiedDate'] == null ? '' : this.methodServices.formatDate(new Date(result[arr]['modifiedDate']),'DD-MMM-YYYY HH:MI:SS')
                              })
              }
              this.tempHistory = [...this.rowsHistory]

              // (NGX-DATATABLE-FORM-GLOBAL)
              subject_rows.next({tableId:config_history?.['prop']?.['tableId'], tipe:'bulk', data:[...this.rowsHistory]})
              // ... end
          }
      })
      
    }


    elementGlobal_History($event:element_global_type){
      

      if ($event['tipe'] == 'filter'){    // FILTER DATA
        // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
        
        if ($event?.['tableId'] == this.tableIdHistory)
        {
          this.rowsHistory = $event?.['rows']
          this.tempHistory = $event?.['temp']
        }
      }

    
      if ($event['tipe'] == 'form_proc'){     // TRIGGER SETELAH KLIK ITEM TER-UNDERLINE
        // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
        if ($event?.['tableId'] == this.tableIdHistory)
        {
            this.selectedHistory($event?.['selectedRow'])
        }
      }

      if ($event['tipe'] == 'close_modal'){
          if ($event?.['tableId'] == this.tableIdHistory)
          {
            this.modalRef.hide()
          }
      }

      if ($event['tipe'] == 'error'){
          if ($event?.['tableId'] == this.tableIdHistory)
          {
            this.rowsHistory = $event?.['rows']
            this.tempHistory = $event?.['temp']
          }
      }
    }

    selectedHistory(selected){
        // panggil set_data_from_api_to_AOD untuk set value ke element, 
        // tapi tidak perlu update aod ke localstorage dengan ditandai tanpa parameter ketiga 'set_aod_to_storage'

        this.set_data_from_api_to_AOD(null, new Date(selected?.['effectiveDate']));

        this.methodServices.statusChange = false;

        if (this.modalRef){
            this.modalRef.hide();
        }
    }

    wheelProc(event){
        event.preventDefault()
    }

    
    ngSelectChange(event, attr?, col_input?){

        if (typeof(event.skeleton) != 'undefined'){

        }

        if (typeof(event.keyjson) != 'undefined'){
            
            let input_name_exists:any = this.global_value?.[col_input?.['input_name']];
            if (typeof input_name_exists != 'undefined' && input_name_exists != null){
              
                let value_id_selected:any = '';

                if (event?.['keyjson'] == null){
                  this.global_value[col_input?.['input_name']]['value'] = null;
                  this.global_value[col_input?.['input_name']]['placeholderLabel'] = null;
                }
                else{
                  this.global_value[col_input?.['input_name']]['value'] = event?.['keyjson']?.[col_input?.['ng_select_global']?.['id']];
                  value_id_selected = event?.['keyjson']?.[col_input?.['ng_select_global']?.['id']];

                  this.global_value[col_input?.['input_name']]['placeholderLabel'] = event?.['keyjson']?.[col_input?.['ng_select_global']?.['label']];
                }

                // CHECK DEPENDENT ng-select-global to other input_name
                let dependent_to:any = col_input?.['dependent_to'];

                // DEPENDENT TO CLEAR PARAMETER / hapus parameter pada input_name tujuan
                let dependent_to_clear_param:any = dependent_to?.['clear_param'];
                
                if (typeof dependent_to_clear_param != 'undefined' && dependent_to_clear_param != null){
                  if (Array.isArray(dependent_to_clear_param)){

                      for (let item of dependent_to_clear_param){
                          let item_input_name:any = item?.['input_name'];
                          let item_param_key:any = item?.['param_key'];

                          let global_value_dependent:any = this.global_value?.[item_input_name];

                          if (typeof global_value_dependent != 'undefined' && global_value_dependent != null){

                            let param_temp:any = global_value_dependent?.['param'];
                            if (typeof param_temp != 'undefined' && param_temp != null && param_temp != ''){

                                let arr_temp:any = param_temp.split("&");
                                let find_Index:any = arr_temp.findIndex(result=>result.split("=")[0] == item_param_key);
                                if (find_Index != -1){
                                    arr_temp.splice(find_Index,1);
                                }

                                // parsing kembali ke key "param" pada global_value
                                this.global_value[item_input_name]['param'] = arr_temp.join("&");
                            }

                          }
                      }
                  }
                }
                // ... <end>

                // DEPENDENT TO NULL VALUE AND LABEL / hapus value dan placeholder pada input_name tujuan
                let dependent_to_null_value_label:any = dependent_to?.['null_value_n_label'];

                if (typeof dependent_to_null_value_label != 'undefined' && dependent_to_null_value_label != null){
                  if (Array.isArray(dependent_to_null_value_label)){

                    for (let input_name of dependent_to_null_value_label){
    
                        let global_value_dependent:any = this.global_value?.[input_name];
                        if (typeof global_value_dependent != 'undefined' && global_value_dependent != null){
    
                            // kosongkan "value"
                            let global_value_val:any = global_value_dependent?.['value'];
                            if (typeof global_value_val != 'undefined' && global_value_val != null){
                                this.global_value[input_name]['value'] = null;
                            }
    
                            // kosongkan "placeholderLabel"
                            let global_value_placeholderLabel:any = global_value_dependent?.['placeholderLabel'];
                            if (typeof global_value_placeholderLabel != 'undefined' && global_value_placeholderLabel != null){
                                this.global_value[input_name]['placeholderLabel'] = null;
                            }
                        }
                    }
                  }
                }
                
                // ... <end>
                

                // DEPENDENT TO VALUE
                let dependent_to_value:any = dependent_to?.['value'];

                if (typeof dependent_to_value != 'undefined' && dependent_to_value != null){
                  if (Array.isArray(dependent_to_value)){
                      for (let item of dependent_to_value){

                          let global_value_dependent:any = this.global_value?.[item?.['input_name']];
                          if (typeof global_value_dependent != 'undefined' && global_value_dependent != null){

                              let param_temp:any = global_value_dependent?.['param'];
                              if (typeof param_temp != 'undefined' && param_temp != null && param_temp != ''){

                                  if (value_id_selected != '' && value_id_selected != null){
                                      let arr_temp:any = param_temp.split("&");
                                      let find_Index:any = arr_temp.findIndex(result=>result.split("=")[0] == item?.['param_key']);
                                      if (find_Index != -1){
                                          arr_temp[find_Index] = item?.['param_key'] + "=" + value_id_selected;
                                      }

                                      // parsing kembali ke key "param" pada global_value
                                      this.global_value[item?.['input_name']]['param'] = arr_temp.join("&");
                                  }
                              }
                              else
                              {
                                  if (value_id_selected != '' && value_id_selected != null){

                                    // jika param dalam kondisi kosong, langsung di masukkan saja value nya
                                    this.global_value[item?.['input_name']]['param'] = item?.['param_key'] + "=" + value_id_selected;
                                  }
                              }
                          }
                      }
                  }
                }
                // ... <end>



                this.check_validation_ngSelectGlobal(col_input, event?.['keyjson']);

            }
            
            this.methodServices.statusChange = true

        }
    }

    check_validation_ngSelectGlobal(col_input?:any, obj_keyjson?:any){
        let validations:any = col_input?.['validation'];

        let value_check_validation:any; // value bisa berupa "id" atau "label"
        if (typeof validations != 'undefined' && validations != null){
            
            for (let validation of validations){
                if (typeof validation?.['type'] != 'undefined' && validation?.['type'] != null){
                    if (validation?.['type'] == 'input_required_to'){

                        // ambil value apakah id atau label, berdasarkan 'read_value_as'
                        let value_selected:any;
                        if (validation?.['read_value_as'] == 'id'){
                            value_selected = obj_keyjson?.[col_input?.['ng_select_global']?.['id']];}
                        else
                        if (validation?.['read_value_as'] == 'label'){
                            value_selected = obj_keyjson?.[col_input?.['ng_select_global']?.['label']];
                        }

                        if (typeof validation?.['value'] != 'undefined'){

                            let find_value:any = validation?.['value'].find(result=>result == value_selected);
                            if (find_value){
                                if (typeof validation?.['arr_input_required_to'] != 'undefined' &&
                                    validation?.['arr_input_required_to'] != null){

                                    if (validation?.['operator'] == '=='){
                                        for (let item of validation?.['arr_input_required_to']){
    
                                            if (typeof this.global_value?.[item] != 'undefined'){
    
                                              if (this.global_value[item]['input_type'] == 'ng-select-global'){
                                                this.global_value[item]['gitRequired'] = true;
                                              }else{
                                                this.global_value[item]['input_required'] = true;
                                              }
                                              
                                            }
                                        }
                                    }
                                    else{

                                        for (let item of validation?.['arr_input_required_to']){
      
                                            if (typeof this.global_value?.[item] != 'undefined'){

                                              if (this.global_value[item]['input_type'] == 'ng-select-global'){
                                                this.global_value[item]['gitRequired'] = false;
                                              }else{
                                                this.global_value[item]['input_required'] = false;
                                              }
                                              
                                            }
                                        }
                                    }
                                    
                                }
                            }
                            else{
                              // value selected tidak ada dalam array value
                                if (typeof validation?.['arr_input_required_to'] != 'undefined' &&
                                    validation?.['arr_input_required_to'] != null){
                                      
                                      for (let item of validation?.['arr_input_required_to']){
  
                                          if (typeof this.global_value?.[item] != 'undefined'){
  
                                                if (obj_keyjson == null){
                                                    // jika value selected = null, maka di set ke "false"

                                                    if (this.global_value[item]['input_type'] == 'ng-select-global'){
                                                      this.global_value[item]['gitRequired'] = false;
                                                    }else{
                                                      this.global_value[item]['input_required'] = false;
                                                    }
                                                }
                                                else
                                                if (validation?.['operator'] == '!='){

                                                    if (this.global_value[item]['input_type'] == 'ng-select-global'){
                                                      this.global_value[item]['gitRequired'] = true;
                                                    }else{
                                                      this.global_value[item]['input_required'] = true;
                                                    }
                                                }
                                                else
                                                if (validation?.['operator'] == '=='){

                                                    if (this.global_value[item]['input_type'] == 'ng-select-global'){
                                                      this.global_value[item]['gitRequired'] = false;
                                                    }else{
                                                      this.global_value[item]['input_required'] = false;
                                                    }
                                                }
                                          }
                                      }

                                }
                            }


                        }


                    }
                }
            }

        }
    }

    elementGlobal_TableFormGlobal($event:element_global_type, global_value_parsed:any){
      
        // // FUNCTION MENERIMA EVENT ADD, EDIT, DELETE

        
        if (!this.status_submit){
          // jika lagi tidak submit, maka di teruskan ke child seperti edit atau create
          this.elementGlobal.emit($event);
        }

        let table_id:any = global_value_parsed?.['table']?.['id'];

        if ($event['tipe'] == 'bulk_complete'){     // SETELAH TRIGGER CREATE ELEMENT BUTTON
          // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN

          if (typeof table_id != 'undefined' && table_id != null){
              this.global_value[table_id]['rows'] = $event?.['rows'];
          }
        }

        if ($event['tipe'] == 'add_complete'){    // SETELAH INSERT DATA BERHASIL DARI CREATE ELEMENT
          // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN

          if (typeof table_id != 'undefined' && table_id != null){
              this.global_value[table_id]['rows'] = $event?.['rows'];
          }
        }

        if ($event['tipe'] == 'edit_complete'){    // SETELAH INSERT DATA BERHASIL DARI CREATE ELEMENT
          // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN

          if (typeof table_id != 'undefined' && table_id != null){
              this.global_value[table_id]['rows'] = $event?.['rows'];
          }
        }

        if ($event['tipe'] == 'delete_complete'){    // SETELAH DELETE DATA BERHASIL
          // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
          if (typeof table_id != 'undefined' && table_id != null){
              this.global_value[table_id]['rows'] = $event?.['rows'];
          }
        }
    }

    
    set_readOnly_dataInput_Local(readonly:boolean){
        // SET READ ONLY action pada datainput & disabled pada button create, export, refresh
        for (var item in this.global_value){
            let section_type:any = this.global_value?.[item]?.['section_type'];

            if (typeof section_type != 'undefined' && section_type != null && section_type == 'ngx-datatable'){
              if (typeof this.global_value?.[item]?.['dataInput'] != 'undefined' &&
                    this.global_value?.[item]?.['dataInput'] != null){

                  this.global_value[item]['dataInput'] = [...this.dataTypeServices.set_ReadOnly_dataInput(['action'],this.global_value[item]['dataInput'],readonly)];
                  this.global_value[item]['configTable'] = {
                    isDisabled:{create:readonly, export:readonly, refresh: readonly}
                  };
              }
            }
        }

        // ... end SET READ ONLY
    }
    
}
