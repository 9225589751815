<div class="container-fluid" style="padding: 0; background: url('assets/img/brand/maintenance_background.png'); height: 100vh; width: auto; background-repeat: no-repeat; background-size: cover; overflow: hidden">
    <div class="row">
        <div class="col-12 text-center d-flex justify-content-center align-items-center flex-column" style="height: 100vh;">
            <div class="title-maintance mb-3">
                We are sorry :( We’ll back soon!
            </div>
            <div class="mb-3">
                <p [innerHTML]="message"></p>
            </div>
            <div class="mb-3">
                <img src="./assets/img/brand/icon-maintance.png" alt="Maintance">
            </div>
            <div style="font-size:14px">
                Thank you for your patience, we promise you it will be worth it!
            </div>
        </div>
    </div>
</div>
