<!-- CONTOH OBSERVER -> [items] = "data_Observer | async" -->

<div class = 'form-group' [ngClass]="[gitRequired ? 'required' : '', isCustomStyle ? 'ng-select-wrapper ' + customStyle : 'ng-select-wrapper']">
    <ng-select
        [items]="dataMaster"
        [bindValue]="bindValue"
        [bindLabel]="bindLabel"
        [placeholder]="placeholder"
        [ngClass]="[size == 'sm' ? 'custom':'custom']"
        [editableSearchTerm]="editableStatus"
        appendTo="body"
        [dropdownPosition]="'auto'"
        [loading]="showLoading"
        loadingText="Loading ..."
        #nativeNgSelect
        (open)="openProc()"
        (focus)="focusProc()"
        (close)="closeProc()"
        (blur)="blurProc()"
        (change)="ngSelectChange($event)"
        (clear)="ngSelectClear($event)"
        [(ngModel)]="modelNgSelect"
        (keydown)="keydownInDropdown($event)"
        [disabled]="statusDisabled"
        >
        <ng-template ng-option-tmp let-item="item" let-index="index" *ngIf="lookup=='employee/secure' || lookup=='employee/secure_employment' || lookup=='employment-manager'">
            <div style="display:flex; justify-content: start; align-items: center; width:100%">
                <span class="content-item" [ngClass]="item.active ? 'box-active' : 'box-inactive'">{{item.name}}</span>
            </div>
        </ng-template>
    </ng-select>
    <!-- Border color red when error for future update -->
    <!-- , gitRequired && !firstLoadNgSelect && (placeholder=='' || placeholder==' ' || placeholder==null) ? 'isError' : '' -->
    <label class = 'form-control-label'>{{ gitLabel | translate }}</label>
    <!-- <span>
        <small [ngClass]="gitRequired && !firstLoadNgSelect && (placeholder=='' || placeholder==' ' || placeholder==null) ? 'd-block' : 'd-none'" class="text-error">{{'Please fill out this field'}}</small>
    </span> -->
    <ng-http-loader-global *ngIf = "httpLoaderRenderShow"></ng-http-loader-global>
</div>
