import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter, Renderer2, OnDestroy } from "@angular/core";
import { debounceTime, fromEvent, Subject, takeUntil } from "rxjs";
import { DataTypeServices } from "src/services/data-type-services";
import { MethodServices } from "src/services/method-services";

export interface selectItem_Array_Inter {
  showText:any,
  paramUrl:any
}

@Component({
    selector:'filter-dropdown-global',
    templateUrl:'filter-dropdown-global.html',
    styleUrls:['filter-dropdown-global.scss'],
})

export class FilterDropdownGlobal implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    
    status_Terakhir_Delete:boolean = false;

    @ViewChild('nativeDropdown') nativeDropdown:ElementRef;
    @Output() selectedValueOutput = new EventEmitter<any>();
    @Input('widthList') widthList:number;
    @Input() arrFilterHeader:any[] = [];
    @Input() nameParamUrl:any;
    @Input() tableId:any;
    @Input() dataInput:any[] = [];      // name dari dataInput
    @Input() dropdownCustomList:selectItem_Array_Inter[] = [];

    widthListRev:any;

    statusOpenList:boolean = false;
    statusHoverBody:boolean = false;
    showDropdown:boolean = false;

    selectItem_Value:any = '';
    selectItem_paramUrl:any = '';
    selectItem_ValuePrev:any = '';
    selectItem_Index:any = 0;
    selectItem_Array:selectItem_Array_Inter[] = [{showText:"Yes", paramUrl:"true"},{showText:"no", paramUrl:"false"}]

    ngUnsubscribe:Subject<any> = new Subject();

    constructor(
            private renderer2:Renderer2,
            public dataTypeServices:DataTypeServices,
            private methodServices:MethodServices
            ){

        // testing DROPDOWN
        this.renderer2.listen('window','click',(event:Event)=>{
        if (!this.nativeDropdown.nativeElement.contains(event.target)){
            this.showDropdown = false
            }
        })

        // ADJUST width size filter-dropdown-global for dropdown-body
        fromEvent(window,'resize').pipe(takeUntil(this.ngUnsubscribe),debounceTime(100)).subscribe((event)=>{
            this.adjust_size_body_dropdown();
        })
    }

    ngOnInit(): void {
      // di cek apa ada custom list dropdown, jika ada di replace ke selecteItem_Array, jika tidak maka gunakan default yes no
      if (this.dropdownCustomList.length > 0 && this.dropdownCustomList != null 
            && typeof this.dropdownCustomList != "undefined"){

            this.selectItem_Array = this.dropdownCustomList
      }
    }

    ngAfterViewInit(): void {
        this.adjust_size_body_dropdown()
    }

    selectItem(val,paramUrl?,index?){
        this.selectItem_Value = val
        this.selectItem_ValuePrev = val
        this.selectItem_Index = index
        this.selectedValueOutput.emit({'showText':val,'paramUrl':paramUrl})
    }
    
    clickDropdown(){
      // !this.showDropdown, karena kondisi click di awal membaca posisi saat ini, jadi variabel nya belum di ubah
      if (!this.showDropdown){
  
        // compare antara value di body dengan di list, jika ada ambil index yg di list, jika tidak set ke 0
        let findItemIndex = this.selectItem_Array.findIndex((result)=>{
          return result['showText'].toLowerCase() == this.selectItem_Value.toLowerCase()
        })
        if (findItemIndex){
          this.selectItem_Index = findItemIndex
        }else{
          this.selectItem_Index = 0
        }

        this.adjust_size_body_dropdown()
      }
      else{
        this.adjust_size_body_dropdown()
      }

    }

    keydownSelectItem($event){
      
    }
    
    keydownDropdown($event){
      
      if ($event.keyCode == 27){
        this.showDropdown = false
      }

      if($event.keyCode == 46){   //delete button
        this.status_Terakhir_Delete = true
        this.selectItem_Value = ""
        this.selectItem_ValuePrev = "" 
        this.selectItem_Index = -1
        this.selectedValueOutput.emit(this.selectItem_Value)
        return
      }

      if ($event.keyCode == 9){
        if (this.selectItem_Value.trim() == ""){
          this.selectedValueOutput.emit(this.selectItem_Value)
        }
        this.showDropdown = false
        return
      }

      if ($event.keyCode == 13 || $event.keyCode == 32){
        
        this.showDropdown = !this.showDropdown
        if (this.showDropdown){     // posisi false yg dibaca dahulu
          // compare antara value di body dengan di list, jika ada ambil index yg di list, jika tidak set ke 0
          let findItemIndex = this.selectItem_Array.findIndex((result)=>{
            return result['showText'].toLowerCase() == this.selectItem_Value.toLowerCase()
          })
          if (findItemIndex){
            this.selectItem_Index = findItemIndex
          }else{
            this.selectItem_Index = 0
          }
        }else{
          if (this.selectItem_Index != -1){
            this.selectItem_Value = this.selectItem_Array[this.selectItem_Index]['showText']
            this.selectItem_paramUrl = this.selectItem_Array[this.selectItem_Index]['paramUrl']

            // this.selectedValueOutput.emit(this.selectItem_Value)
            this.selectedValueOutput.emit({'showText':this.selectItem_Value,'paramUrl':this.selectItem_paramUrl})
          }
        }

        $event.preventDefault()
        $event.stopPropagation()

        return
      }
      if ($event.keyCode == 40){
        if (this.selectItem_Index + 1> this.selectItem_Array.length - 1){
          this.selectItem_Index = 0
        }else{
          this.selectItem_Index++
        }
  
        if (!this.showDropdown){
          this.selectItem_Value = this.selectItem_Array[this.selectItem_Index]['showText']
          this.selectItem_paramUrl = this.selectItem_Array[this.selectItem_Index]['paramUrl']
        }
        $event.preventDefault()
        $event.stopPropagation()
        return
      }
      if ($event.keyCode == 38){
        if (this.selectItem_Index - 1 < 0){
          this.selectItem_Index = this.selectItem_Array.length - 1
        }else{
          this.selectItem_Index--
        }
  
        if (!this.showDropdown){
          this.selectItem_Value = this.selectItem_Array[this.selectItem_Index]['showText']
          this.selectItem_paramUrl = this.selectItem_Array[this.selectItem_Index]['paramUrl']
        }

        $event.preventDefault()
        $event.stopPropagation()
        return
  
      }

      // ketika mencari data di list hanya dengan huruf pertama di compare dengan yg tombol yg diketik
      // if ($event.key != "Tab" && !$event.shiftKey){

        if (!this.showDropdown){

          let findItemValue:any;
          let findItemParamUrl:any;
  
          let findItem  = this.selectItem_Array.findIndex((result)=>{
            if(result['showText'].toLowerCase().substring(0,1).indexOf($event.key.toString().toLowerCase()) != -1){
              findItemValue = result['showText']
              findItemParamUrl = result['paramUrl']
              return result['showText']
            }
          })
          if (findItem != -1){
            this.selectItem_Value = findItemValue
            this.selectItem_paramUrl = findItemParamUrl
          }
        }
    }
  
    hoverDropdownList($event){
      this.statusHoverBody = true
    }
  
    blurDropdownList($event){
      this.statusHoverBody = false
    }
    
    hoverDropdownItem(attr,index){
      this.selectItem_Index = index
    }
  
    clearDropdown($event){
      this.selectItem_Value = ""
      this.selectItem_ValuePrev = ""
      this.selectItem_Index = 0
      this.selectedValueOutput.emit("")

      $event.preventDefault()
      $event.stopPropagation()
    }

    adjust_size_body_dropdown(){
      if (typeof this.dataInput != 'undefined'){
        try{
          let _findIdx = this.dataInput.findIndex((dtInput)=>dtInput?.['nameParamUrl'].toLowerCase() == this.nameParamUrl.toLowerCase())
          if (_findIdx){
            setTimeout(()=>{
                let datatable_header_cell:any;

                if (typeof this.tableId != 'undefined'){
                  datatable_header_cell = document.querySelectorAll("." + this.tableId + " .datatable-summary-row .datatable-body-row .datatable-body-cell filter-dropdown-global .dropdown-body." + this.nameParamUrl);
                }else{
                  datatable_header_cell = document.querySelectorAll(".datatable-summary-row .datatable-body-row .datatable-body-cell filter-dropdown-global .dropdown-body." + this.nameParamUrl);
                }

                if (datatable_header_cell.length > 0){

                  datatable_header_cell.forEach((val,idx)=>{
                      if (datatable_header_cell.item(idx).clientWidth > 0){
                        this.widthListRev = datatable_header_cell.item(idx).clientWidth + "px"
                      }else{
                        this.widthListRev = this.widthList + "px"
                      }
                    })
                }
                else{
                    this.widthListRev = this.widthList + "px"
                }
            },100)
          }
        }catch(e){
          this.widthListRev = this.widthList + "px"
          this.methodServices.showToast("(filter-dropdown-global -> ngOnChanges) -> " + e,'warning')
        }
      }
    }

    ngOnChanges(): void {

      this.adjust_size_body_dropdown()

      // if (this.widthList <= 150){this.widthListRev = (this.widthList - 8) + 'px'}   // current condition
      // else if (this.widthList <= 150){this.widthListRev = (this.widthList) + 'px'}
      // else if (this.widthList <= 200){this.widthListRev = (this.widthList - 5) + 'px'}
      // else if (this.widthList <= 320){this.widthListRev = (this.widthList - 18) + 'px'}
      // else if (this.widthList <= 328){this.widthListRev = (this.widthList - 20) + 'px'}
      // else if (this.widthList <= 330){this.widthListRev = (this.widthList - 23) + 'px'}
      // else { this.widthListRev = (this.widthList - 40) + 'px '} // current condition

      // this.widthListRev = (this.widthList - 40) + 'px'
      
      // parsing data balik ke dropdown setelah ada render api ulang
      if (this.arrFilterHeader.length == 0){
        this.selectItem_Value = ""
        this.selectItem_ValuePrev = ""
        this.selectItem_Index = -1
      }else{
        let paramSingle = this.nameParamUrl + "="
        let paramSingleVal:any;

        let findItem = this.arrFilterHeader.find((result)=>{
          return result.toString().indexOf(paramSingle) != -1
        })
        if (findItem){
          let findEqual = findItem.indexOf("=")
          let valIndex = findItem.substr(findEqual + 1, findItem.length)
          let valIndex_Rev:any;

          let findItemText = this.dropdownCustomList.find((result)=>{
              return result.paramUrl.toLowerCase() == valIndex.toLowerCase()
          })
          if (findItemText){
            valIndex_Rev = findItemText['showText']
          }else{
            valIndex_Rev = ""
          }

          // switch(valIndex.toLowerCase()){
          //   case 'true'   :valIndex_Rev = "Yes";break;
          //   case 'false'  :valIndex_Rev = "No";break;
          //   case 'yes'   :valIndex_Rev = "Yes";break;
          //   case 'no'  :valIndex_Rev = "No";break;
          //   default       :valIndex_Rev = "";break;
          // }

          this.selectItem_Value = valIndex_Rev
          this.selectItem_ValuePrev = valIndex_Rev
        }
      }
    }

    lostFocusDropdownList(){
      if (!this.showDropdown){
        if (this.selectItem_ValuePrev.toString() !== this.selectItem_Value.toString()){
          this.selectItem_ValuePrev = this.selectItem_Value
          
          // this.selectedValueOutput.emit(this.selectItem_Value)
          this.selectedValueOutput.emit({'showText':this.selectItem_Value,'paramUrl':this.selectItem_paramUrl})
        }
      }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }
}