import { Component, OnInit } from "@angular/core";
import { SharingModule } from "src/app/sharing-module/sharing.module";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    standalone: true,
    imports: [SharingModule]
})
export class FooterComponent implements OnInit {
  test: Date = new Date();

  constructor() {}

  ngOnInit() {}
}
