<div class = "dropdown-custom" (click)="showDropdown = !showDropdown" #nativeDropdown>
    <!-- dropdown body -->
    <div
        class = "dropdown-body"
        [ngClass]="[nameParamUrl]"
        tabindex="0"
        (click)="clickDropdown()"
        (blur)="lostFocusDropdownList()"
        (keydown)="keydownDropdown($event)">
        {{ selectItem_Value | translate }}
        
        <!-- angle icon -->
        <div class = 'position-absolute fa' style = "font-size:20px;top:50%; right:10px; transform:translateY(-50%)"
            [ngClass]="{'fa-angle-up' : showDropdown, 'fa-angle-down': !showDropdown}"
        ></div>

        <!-- fa-times icon (X) -->
        <div class = 'position-absolute times-dropdown fa' style = "font-size:17px;top:50%; right:35px; transform:translateY(-50%)"
            (click)="clearDropdown($event)"
            *ngIf="selectItem_Value != ''"
            [ngClass] = "{'fa-times' : selectItem_Value != ''}"
        ></div>
    </div>

    <!-- dropdown list -->
    <div class = "dropdown-list"
        style="position:absolute;z-index:1;width:inherit;border-radius:10px; background-color:white; border:1px solid lightgrey; 
                margin-top:2px;font-size:0.875rem; overflow:hidden; text-overflow: ellipsis; transition:0.17s linear"
        (mouseenter)="hoverDropdownList($event)"
        (mouseleave)="blurDropdownList($event)"
        [ngStyle]="{display : showDropdown ? 'block' : 'none', width:widthListRev}">

        <!-- LOOPING -->
        <div class = "dropdown-item" 
            *ngFor="let selectItem_Array of selectItem_Array; let i = index; let isFirst = first; let isLast = last"
            style = "padding:5px 0.5rem"
            [ngStyle] = "{'border-radius': isFirst ? '10px 10px 0 0' : isLast ? '0 0 10px 10px' : '0'}"
            tabindex="0"
            [ngClass]="{'active':selectItem_Index == i}"
            (mouseenter)="hoverDropdownItem(selectItem_Array.showText, i)"
            (keydown)="keydownSelectItem($event)"
            (click)="selectItem(selectItem_Array.showText, selectItem_Array.paramUrl, i)">{{ selectItem_Array.showText | translate }}</div>
    </div>
</div>