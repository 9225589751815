import { Component } from "@angular/core";
import { SharingModule } from "src/app/sharing-module/sharing.module";
import { NavbarComponent } from "src/app/components/navbar/navbar.component";
import { FooterComponent } from "src/app/components/footer/footer.component";
import { SidebarComponent } from "src/app/components/sidebar/sidebar.component";
import { fadeAnimation } from "src/app/animations/routes-animation";

@Component({
    selector: "app-admin-layout",
    templateUrl: "./admin-layout.component.html",
    styleUrls: ["./admin-layout.component.scss"],
    standalone: true,
    imports: [SharingModule, SidebarComponent, NavbarComponent, FooterComponent],
    animations: [fadeAnimation]
})
export class AdminLayoutComponent {
  isMobileResolution: boolean;

  constructor() {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
}
