<style>
    .container-git-popup {
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    @media only screen and (min-device-width: 240px) and (max-device-width: 600px) {
        .container-git-popup {
            width: 100%;
        }
    }
    .container-payment {
        background-color: #A6D8F3 !important;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        background-size: 100%;
        padding: 20px;
        overflow: auto;
    }
    .container-status .success-mark {
        background-color: transparent;
        border: 4px solid #00bcf1;
        box-sizing: border-box;
    }

    .container-status .error-mark {
        background-color: transparent;
        border: 4px solid #FF6167;
    }

    .container-status .success-mark,
    .container-status .error-mark {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        /*margin-bottom: 32px;*/
        /*margin-top: 32px;*/
    }

    .success-mark {
        display: inline-block;
        position: relative;
    }

    .error-mark {
        display: inline-block;
        position: relative;
    }

    .container-status .error-mark:after,
    .container-status .error-mark:before {
        margin: 18px 32px;
        width: 0px;
        height: 36px;
        border: solid #FF6167;
        border-width: 0 6px 6px 0;
    }

    .error-mark:before {
        transform: rotate(
                -45deg);
    }
    .error-mark:after {
        transform: rotate(
                45deg);
    }
    .error-mark:after, .error-mark:before {
        position: absolute;
        content: '';
        display: block;
        width: 0px;
        height: 6px;
        border: solid #555555;
        border-width: 0 3px 3px 0;
    }

    .container-status {
        border-color: var(--color);
        background-color: var(--color);
        background-image: -webkit-repeating-linear-gradient(
                135deg, #fff, #fff 1px, transparent 2px, transparent 2px, #fff 3px);
        background-image: -moz-repeating-linear-gradient(135deg, #fff, #fff 1px, transparent 2px, transparent 2px, #fff 3px);
        background-image: -o-repeating-linear-gradient(135deg, #fff, #fff 1px, transparent 2px, transparent 2px, #fff 3px);
        background-image: repeating-linear-gradient(
                135deg, #fff, #fff 1px, transparent 2px, transparent 2px, #fff 3px);
        -webkit-background-size: 4px 4px;
        -moz-background-size: 4px 4px;
        background-size: 4px 4px;
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding: 16px;
        text-align: center;
    }

    .container-status .success-mark:after {
        margin: 12px 24px;
        width: 20px;
        height: 40px;
        border: solid #00bcf1;
        border-width: 0 6px 6px 0;
    }

    .success-mark:after {
        position: absolute;
        content: '';
        display: block;
        transform: rotate(45deg);
        width: 3px;
        height: 6px;
        border: solid #555555;
        border-width: 0 3px 3px 0;
    }

    *, *:before, *:after {
        box-sizing:border-box;
    }
</style>

<div class="container-payment">
    <div class="row">
        <div class="col-12">
            <div class="card bg-secondary border-0 mb-0 container-git-popup">
                <div class="card-body pb-0">
                    <div class="d-flex justify-content-center align-items-center mb-3">
                        <img src="./assets/img/brand/starconnect-06.png" alt="" style="width: 50%; height: auto;">
                    </div>
                    <div class="text-center mb-5">
                        <h2>Transaction Sent Successfully </h2>
                    </div>
                    <div class="text-center mb-5">
                        <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M115.841 48.0265L117.567 31.4944L102.378 24.7357C101.881 24.5154 101.485 24.1186 101.264 23.6235L94.5078 8.43451L77.9724 10.1627C77.4355 10.2162 76.8976 10.0707 76.4613 9.75524L63.0005 0L49.5398 9.75524C49.1024 10.0707 48.5644 10.2162 48.0287 10.1627L31.4922 8.43451L24.7357 23.6235C24.5164 24.1186 24.1197 24.5154 23.6235 24.7335L8.43451 31.4944L10.1605 48.0265C10.2162 48.5634 10.0718 49.1024 9.75524 49.5398L0 63.0005L9.75524 76.4613C10.0718 76.8987 10.2162 77.4377 10.1605 77.9745L8.43451 94.5056L23.6235 101.264C24.1186 101.485 24.5154 101.881 24.7357 102.378L31.4922 117.567L48.0287 115.838C48.1025 115.838 48.1795 115.827 48.2468 115.827C48.7088 115.827 49.158 115.973 49.5312 116.246L63.0005 126L76.4613 116.246C76.8976 115.928 77.4355 115.783 77.9724 115.838L94.5078 117.567L101.264 102.378C101.485 101.881 101.881 101.486 102.378 101.266L117.567 94.5056L115.841 77.9745C115.784 77.4377 115.928 76.8987 116.246 76.4613L126 63.0005L116.246 49.5398C115.928 49.1024 115.784 48.5634 115.841 48.0265ZM94.773 47.4651L51.408 90.8311C50.5525 91.6855 49.1665 91.6855 48.311 90.8311L29.7159 72.236C29.3042 71.8254 29.0732 71.2671 29.0732 70.6854C29.0732 70.1036 29.3042 69.5454 29.7159 69.1347L35.9143 62.9588C36.7688 62.1065 38.1526 62.1065 39.007 62.9588L49.8595 73.7888L85.4776 38.1708C86.3331 37.3152 87.7191 37.3152 88.5746 38.1708L94.773 44.368C95.6285 45.2236 95.6285 46.6106 94.773 47.4651Z" fill="#3D8FF0"/>
                        </svg>
                    </div>
                    <div class="text-center mb-5">
                        <p>Please check your email for more information<br>
                            about your payment</p>
                    </div>
                    <div class="text-center">
                        <button type="button" style="background-color: #6FC5F8; border-color:#6FC5F8"
                                class="btn btn-block btn-primary my-4"
                                (click)="payNow()"
                        >
                            Check Status Payment
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class = 'modal' bsModal #modalCheckStatus = 'bs-modal' [config]="{keyboard:false, animated:false, ignoreBackdropClick:true}" tabindex='-1' role='dialog'>
    <div class = 'modal-dialog modal-dialog-centered modal-md'>
        <div class = 'modal-content container-status' [style.--color]="statusPayment == 'settlement' ? blueColorBackgroundModal : redColorBackgroundModal">
            <div class=" modal-body" [ngStyle]="{'color': statusPayment == 'settlement' ? blueColorPrimaryText : redColorPrimaryText}">
                <div class="row">
                    <div class = 'col-12'>
                        <div class="container-status">
                            <div [ngClass]="statusPayment == 'settlement' ? 'success-mark' : 'error-mark'"></div>
                        </div>
                        <hr [ngStyle]="{'border-color': statusPayment == 'settlement' ? blueColorPrimaryText : redColorPrimaryText}">
                        <p [ngStyle]="{'color': statusPayment == 'settlement' ? blueColorPrimaryText : redColorPrimaryText}">
                            {{statusPayment == 'settlement' ? 'Your Payment Already Successfully' : 'Your Transaction Already Expired!'}}
                        </p>
                        <hr [ngStyle]="{'border-color': statusPayment == 'settlement' ? blueColorPrimaryText : redColorPrimaryText}">
                    </div>
                </div>
            </div>

            <div class=" modal-footer d-flex justify-content-center w-100 mt--4">
                <button
                        class="btn btn-icon w-100"
                        [ngStyle]="{'background-color': statusPayment == 'settlement' ? blueColorPrimaryText : redColorPrimaryText}"
                        data-dismiss="modal"
                        type="button"
                        (click)="modalCheckStatus.hide()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

