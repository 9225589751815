<style>
    .btnDisabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
</style>

<button [disabled]="isDisabled" type = 'button' (click)="openModalExport()" class = 'btn btn-icon'
        [ngClass]="gitBtnClass"
        style = 'z-index:2;'>
    <span class = 'btn-inner--icon text-white'><i class="fas fa-download"></i></span>
    <span *ngIf="showLabelBtn" class="btn-inner--text">{{ 'Export' | translate }}</span>
</button>

<div class="modal fade" bsModal #modalExport="bs-modal" (onShown)="handler('onShown', $event)" [config]="{keyboard:false, animated:true,ignoreBackdropClick:true}" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class=" modal-header">
                <div class = 'w-100'>
                    <div style="width: 100%;" class = 'd-flex justify-content-between'>
                        <h6 class=" modal-title mb-1" id="modal-title-default">
                            {{ 'Export' | translate }}
                        </h6>
                    </div>
                    <hr style = 'border:1px solid lightsteelblue' class = 'mt-1'>
                </div>
            </div>

            <div class=" modal-body mt--6">
                <div class = 'row'>
                    <div class = 'col-12'>
                        <div class="d-flex" style="position:relative; width: 100%; height: 106px;">
                            <div class="d-flex flex-row justify-content-center align-items-center" style="flex-wrap: wrap;width:100%;">
                                <div class="d-flex justify-content-center align-items-center mx-3" style="border-radius: 5px;width: 66px; height: 66px; background-color: #5e72e4;">
                                    <i class="fa fa-file-alt text-white" style="font-size: 28px;"></i>
                                </div>
                                <div class="d-flex flex-column justify-content-center" style="flex: 1; margin-right: 15px;width: 100%;">
                                    <progressbar [animate]="true" [striped]="true" [value]="exportInformation.valueProgress" [max]="exportInformation.totalProgress" type="success"></progressbar>
                                    <small><em style="color:var(--git-field-label)">{{exportInformation.progressStatus}}</em></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" modal-footer mt--5">
                <button *ngIf="exportInformation.canSave"
                        class="btn btn-link ml-auto"
                        type="button"
                        (click)="modalExport.hide()">
                    {{ 'Close' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
