<style>
    .invalid {
        border-color:red;
    }

    input::-webkit-inner-spin-button, 
    input::-webkit-outer-spin-button{
        -webkit-appearance: none;
        appearance: none;
    }
    input[type=number]{
        -moz-appearance: textfield;
    }
</style>

<ng-http-loader [backgroundColor]="'#0070d2'" 
  [entryComponent]="httpLoaderImage"
  >
</ng-http-loader>

<div class=" main-content-login d-flex align-items-center justify-content-center">
    <div class="container pa-0" *ngIf = "status_aktif_register" style="width: 516px;">
        <div class="card bg-secondary border-0 mb-0" style='margin-left: auto; margin-right: auto;'>
            <div class=" card-header bg-transparent pb-0">
                <div class="text-center">
                    <a class="navbar-brand" href="https://starconnect.id">
                        <!-- <img src="assets/img/brand/logo.png" style="width: 40%;height:40%" /> -->
                        <img src="assets/img/brand/starconnect-06.png" style="width: 35%;height:35%" />
                    </a>
                    <h4 class = 'mt-3'><b>Fill the following data to begin your Free Demo</b></h4>
                </div>
            </div>
            <div class="card-body pb-0">
                <form role="form" #f='ngForm' >
                    <div class = 'row'>
                        <div class = 'col-12'>
                            <div class = 'form-group required'>
                                <div class = 'fom-control'>
                                    <label class = 'form-control-label'>Full Name</label>
                                    <input type = 'text'
                                           class = 'form-control form-control-sm'
                                           name = 'nama-lengkap-name'
                                           id = 'nama-lengkap-id'
                                           [disabled]="readOnlyInput"
                                           #nativeNamaLengkap
                                    >
                                </div>
                            </div>
                        </div>
                        <div class = 'col-12 mt--3'>
                            <div class = 'form-group required'>
                                <div class = 'fom-control'>
                                    <label class = 'form-control-label'>Email</label>
                                    <input type = 'text'
                                           class = 'form-control form-control-sm'
                                           [ngClass]="{
                                                invalid:!validEmail
                                            }"
                                           (blur)="cekEmail()"
                                           name = 'alamat-email-name'
                                           id = 'alamat-email-id'
                                           [disabled]="readOnlyInput"
                                           #nativeAlamatEmail
                                    >
                                </div>
                            </div>
                        </div>
                        <div class = 'col-12 mt--3'>
                            <div class = 'form-group required'>
                                <div class = 'fom-control'>
                                    <label class = 'form-control-label'>Company</label>
                                    <input type = 'text'
                                           class = 'form-control form-control-sm'
                                           name = 'nama-perusahaan-name'
                                           id = 'nama-perusahaan-id'
                                           [disabled]="readOnlyInput"
                                           #nativeNamaPerusahaan
                                    >
                                </div>
                            </div>
                        </div>
                        <div class = 'col-12 mt--3'>
                            <div class = 'form-group required'>
                                <div class = 'fom-control'>
                                    <label class = 'form-control-label'>Phone Number</label>
                                    <input type="number"
                                           id = 'no-handphone-id'
                                           class="form-control form-control-sm"
                                           name = "no-handphone-name"
                                           #nativeNoHandphone
                                           (wheel)='wheelProc($event)'
                                           min="0"
                                           maxLength="15"
                                           oninput = "if(this.value.length > this.maxLength) {this.value = '9'.repeat(this.maxLength)}"
                                           onkeypress = 'if(event.charCode == 101 || event.charCode == 45 ||
                                                        (this.value.length >= this.maxLength && window.getSelection().toString() == "")
                                                        ) {event.preventDefault()}'
                                           [readonly] = 'readOnlyInput'>
                                </div>
                            </div>
                        </div>
                        <div class = 'col-12 mt--3'>
                            <div class = 'form-group required'>
                                <div class = 'fom-control'>
                                    <label class = 'form-control-label'>Job</label>
                                    <input type = 'text'
                                           class = 'form-control form-control-sm'
                                           name = 'jabatan-name'
                                           id = 'jabatan-id'
                                           [disabled]="readOnlyInput"
                                           #nativeJabatan
                                    >
                                </div>
                            </div>
                        </div>
                        <div class = 'col-12 mt--3'>
                            <div class = 'form-group required'>
                                <div class = 'fom-control'>
                                    <label class = 'form-control-label'>Total Employees</label>
                                    <input type = 'number'
                                           class = 'form-control form-control-sm'
                                           name = 'jumlah-karyawan-name'
                                           id = 'jumlah-karyawan-id'
                                           (wheel)="wheelProc($event)"
                                           maxLength = "15"
                                           [disabled]="readOnlyInput"
                                           onkeypress = "if(event.charCode == 45 || event.charCode == 101 ||
                                                    (this.value.length >= this.maxLength && window.getSelection().toString() == ''))
                                                    { event.preventDefault()}"
                                           #nativeJumlahKaryawan
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <div class="custom-control custom-control-alternative custom-checkbox">
                                <input class="custom-control-input" id="customCheckAgree" type="checkbox" name='remember_status'
                                       [(ngModel)]='checked_agree' #agree="ngModel" [checked]='checked_agree'
                                       [disabled]="readOnlyInput" />

                                <label class="custom-control-label pt-1" for = "customCheckAgree" style = 'font-size:11px'>
                                    I agree to StarConnect <a style = 'color:lightskyblue;font-size:11px' href = "https://starconnect.id/eula/" target="_blank">Terms of Service & Privacy Policy</a>
                                </label>
                            </div>

                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" style="background-color: #0070d2; border-color:#0070d2"
                                class="btn btn-block btn-primary my-4"
                                (click)="tryDemo()"
                                [disabled]=!checked_agree
                        >
                            Free Demo - 14 Days
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <div class="container mt-lg-0" *ngIf = "!status_aktif_register" style = 'zoom:80%;'>
        <div class = 'row justify-content-center'>
            <div class = 'col-12 col-md-6'>

                <div class=" card bg-secondary border-0" style = 'margin-left: auto; margin-right: auto;'>
                    <div class=" card-body py-lg-3">
                        <div class=" text-left text-muted mb-4">
                            <div class="text-left">
                                <img src="assets/img/brand/starconnect-06.png" style="width: 30%;height:30%" />
                            </div>
                        </div>
                        <div>
                            <h1 style = 'font-weight: bold;'>Halo, {{namaLengkap}} !</h1>
                            <div style = 'font-family: Arial, Helvetica, sans-serif;'>
                                Your StarConnect free demo has been successfully activated. Please
                                check your inbox to get your FREE StarConect demo username and
                                password.
                            </div>
                        </div>

                        <div>
                            <button type = "button" style="background-color: #0070d2; border-color:#0070d2"
                                class="btn btn-block btn-primary my-4"
                                (click)="backSuccess()">
                                OK
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
