import { Component, OnInit, ViewChild } from '@angular/core';
import { MethodServices } from 'src/services/method-services'; //utama
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
// import * as CryptoJS from 'crypto-js'
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment'

import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpLoaderImageComponent } from 'src/app/global/http-loader-image/http-loader-image.component'
import { Subject, takeUntil } from 'rxjs';

const CryptoJS = require('crypto-js');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public httpLoaderImage = HttpLoaderImageComponent

  keyjson:any;
  username:any;
  config:any;

  test: Date = new Date();
  focus;
  focus1;
  loginFault = false;
  error_code: any;
  error_msg: any;
  access_token:any;
  @ViewChild("f") f: NgForm;
  checked:boolean=false;
  checked_status:boolean= false;
  user:string;
  pass:string;
  title:string;
  showPass:boolean = false;
  valueFromParams:any;
  layoutStyle:string = 'MORNING'

  defaultSetting:any = {
      "darkMode":this.methodServices.ivSha256Encrypt("false"),
      "lang":this.methodServices.ivSha256Encrypt("en")
  }

  ngUnsubscribe:Subject<any> = new Subject()

  constructor(private location:Location,private methodServices: MethodServices, private router: Router,
          private http:HttpClient,
          private activatedRoute:ActivatedRoute) {
            this.layoutStyle = this.methodServices.layoutStyleTime
            // this.layoutStyle = 'AFTERNOON'
          }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(void 0)
    this.ngUnsubscribe.complete() 
  }

  ngOnInit() {
    // DIRECT to WEB PAYROLL
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (typeof res['k'] != 'undefined') {
        this.valueFromParams = JSON.parse(this.methodServices.ivSha256Decrypt(res['k']))
      }
    })

    this.checked_status = false;
    if (localStorage.getItem('ZEU@AL!') !== null) {
      this.user = CryptoJS.AES.decrypt(localStorage.getItem('ZEU@AL!'),environment.encryptKey).toString(CryptoJS.enc.Utf8)
      this.checked_status = true
    }
    if (localStorage.getItem('SPS!#WU') !== null) {
      this.pass = CryptoJS.AES.decrypt(localStorage.getItem('SPS!#WU'),environment.encryptKey).toString(CryptoJS.enc.Utf8)
    }
    this.title = this.location.prepareExternalUrl(this.location.path())
  }

  getInfoStorage(){
    let existsToken:boolean = false
    const helper = new JwtHelperService
    if (localStorage.length > 0) {
      for (var i = 0;i < localStorage.length;i++){
        try{
          if (localStorage.key(i) != 'GITAOD'){
            if (typeof (localStorage.getItem("GITAUTHTKN")) != 'undefined' && localStorage.getItem("GITAUTHTKN") != null) {
              this.methodServices.jwt_authorities_value = this.methodServices.ivSha256Decrypt(localStorage.getItem("GITAUTHTKN"))
            }

              if (typeof localStorage.getItem('GITINSTAPI') != 'undefined' && localStorage.getItem('GITINSTAPI') != null){
                  this.methodServices.jwt_instance_api = this.methodServices.ivSha256Decrypt(localStorage.getItem('GITINSTAPI'))
              }
            if (this.methodServices.ivSha256Decrypt(localStorage.key(i)) != ''){
              // return
              let keyStor = this.methodServices.ivSha256Decrypt(localStorage.key(i))
              let valStor = this.methodServices.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)))

              if (keyStor == 'keyjson'){
                this.methodServices.jwt_keyjson = valStor
                let valStorObj = JSON.parse(valStor)
                this.methodServices.jwt_access_token = typeof(valStorObj['access_token']) != 'undefined' ? valStorObj['access_token'] : null
                this.methodServices.jwt_refresh_token = typeof(valStorObj['refresh_token']) != 'undefined' ? valStorObj['refresh_token'] : null
      
                let jwt = new JwtHelperService
                let jwtDecode = jwt.decodeToken(this.methodServices.jwt_access_token)
                this.methodServices.jwt_instance_api = jwtDecode['instance_api'][0]
                this.methodServices.jwt_tenant_id = jwtDecode['tenant_id'][0]
                existsToken = true
              }
              if (keyStor == 'authorities'){
                this.methodServices.jwt_authorities_key = localStorage.key(i)
                this.methodServices.jwt_authorities = JSON.parse(valStor)
              }

              if (keyStor == 'keyjson') {
                  let accessTokenDecode = helper.decodeToken(JSON.parse(this.methodServices.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)))).access_token)
                  if (typeof accessTokenDecode.resource_access['git-client'] != 'undefined') {
                      let getCheckData_SUPER_Admin = accessTokenDecode.resource_access['git-client']['roles'].findIndex(result => result == 'SUPER_ADMIN')
                      let getCheckData_SC_Admin = accessTokenDecode.resource_access['git-client']['roles'].findIndex(result => result == 'SC_ADMIN')
                      if (getCheckData_SUPER_Admin != -1 && getCheckData_SC_Admin == -1) {
                          this.methodServices.jwt_role_user = accessTokenDecode['resource_access']['git-client']['roles'][getCheckData_SUPER_Admin]
                      } else if (getCheckData_SC_Admin != -1 && getCheckData_SUPER_Admin == -1) {
                          this.methodServices.jwt_role_user = accessTokenDecode['resource_access']['git-client']['roles'][getCheckData_SC_Admin]
                      } else {
                          this.methodServices.jwt_role_user = 'STAFF'
                      }
                  }else{
                      this.methodServices.jwt_role_user = 'STAFF'
                  }

                  // SET GITROLE to localStorage
                  if (typeof (localStorage.getItem("GITROLE")) != 'undefined' && localStorage.getItem("GITROLE") != null) {
                    localStorage.removeItem("GITROLE")
                  }
                  localStorage.setItem("GITROLE", this.methodServices.ivSha256Encrypt(this.methodServices.jwt_role_user))
              }
            }
          }
        }catch(err){
          console.log('error get api : ' + err)
        }
      }
    }
   }

  login_page() {
    let statusUser:boolean = false
    var colors = this.methodServices.arrColor
    let randomColorAvatar = colors[Math.floor(Math.random() * colors.length)];
    
    if (this.checked_status == true) {
      if (localStorage.getItem('ZEU@AL!') !== null) {
        localStorage.removeItem('ZEU@AL!')
      }
      localStorage.setItem('ZEU@AL!',CryptoJS.AES.encrypt(this.f.value.username,environment.encryptKey).toString())
      this.checked_status = true
      
      if (localStorage.getItem('SPS!#WU') !== null) {
        localStorage.removeItem('SPS!#WU')
      }
      localStorage.setItem('SPS!#WU',CryptoJS.AES.encrypt(this.f.value.password,environment.encryptKey).toString())
    }
    

    let keyForm = new HttpParams({fromObject:{
      'grant_type':'password',
      'client_id':'git-client',
      'username':this.f.value.username,
      'password':this.f.value.password
    }})
    
    this.http.post(environment.tokenUrl,keyForm)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res)=>{
            const helper = new JwtHelperService
            let getDecodeToken = helper.decodeToken(res['access_token'])
            let instance_url = getDecodeToken.instance_url[0]
            let instance_api = getDecodeToken.instance_api[0]
            let tenant_id = getDecodeToken.tenant_id[0]
            this.keyjson = JSON.stringify(res)

            if (typeof getDecodeToken['resource_access']['git-client'] == 'undefined'
                || getDecodeToken['resource_access']['git-client']['roles'][0] != 'SUPER_ADMIN') {
                this.http.get(instance_api + '/api/user/profile',{
                    headers:{
                        'Authorization':'Bearer ' + res['access_token'],
                        'X-TenantID':tenant_id
                    }
                }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((resprofile)=>{
                    let getDecodeTokenAuth = helper.decodeToken(resprofile['authoritiesToken'])
                    let authorities = getDecodeTokenAuth['authorities']

                    let cekWebAccess = authorities.find((resAuth)=>{
                        return resAuth.toLowerCase() == 'web access'
                    })
                    if (!cekWebAccess){
                        this.error_msg = 'You are not authorized to access this application'
                    }else{
                        if (typeof localStorage.getItem('GITAUTHTKN') == "undefined") {
                            let encryptAuthentical = this.methodServices.ivSha256Encrypt(resprofile['authoritiesToken'])
                            localStorage.setItem('GITAUTHTKN', encryptAuthentical)
                        }
                        this.setToLocalStorage(this.keyjson, () => {
                            this.getInfoStorage()
                            this.router.navigate(['home', 'profile'])
                        })
                    }
                }, (err) => {
                    if (err.status == 400 || err.status == 404 || err.status.toString().substring(0,1) == '5'){
                        this.error_msg = err.error.message
                    }
                    else{
                        this.error_msg = 'Can\'t connect to server. Please Contact Admin '
                    }
                })
            } else {
                this.keyjson = JSON.stringify(res)
                this.setToLocalStorage(this.keyjson, () => {
                    this.getInfoStorage()
                    this.router.navigate(['home', 'profile'])
                })
            }
        },(err)=>{
        if (err.status == 403) {
          this.error_msg = 'Invalid username / password'
        } else {
          this.error_msg = err.error.error_description
        }
      })
  }

  setToLocalStorage(dataJson,callback) {
      var colors = this.methodServices.arrColor
      let randomColorAvatar = colors[Math.floor(Math.random() * colors.length)];
      let decIVKeyjson = this.methodServices.ivSha256Encrypt(dataJson)
      const helper = new JwtHelperService
      let getDecodeToken = helper.decodeToken(JSON.parse(dataJson).access_token)
      let instance_api = getDecodeToken.instance_api[0] // menggunakan hardcode dulu

      let keyjson = this.methodServices.ivSha256Encrypt('keyjson')
      localStorage.setItem(keyjson,decIVKeyjson)

      // GET API URL
      if (typeof localStorage.getItem('GITINSTAPI') != 'undefined' && localStorage.getItem('GITINSTAPI') != null) {
          localStorage.removeItem('GITINSTAPI')
      }
      let instanceAPIValue = this.methodServices.ivSha256Encrypt(instance_api)
      localStorage.setItem('GITINSTAPI',instanceAPIValue)

      if (typeof localStorage.getItem('GITDMOD') == "undefined") {
          localStorage.setItem('GITDMOD', this.defaultSetting.darkMode)
      }

      if (typeof localStorage.getItem('GITLANG') == 'undefined') {
          localStorage.setItem('GITLANG', this.defaultSetting.lang)
      }


      // SET AOD to Local Storage
      this.methodServices.setAODToLocalStorage(new Date())

      localStorage.setItem('GITAV', this.methodServices.ivSha256Encrypt(randomColorAvatar))
      localStorage.setItem('GITAL', this.methodServices.ivSha256Encrypt('true'))

      callback()
  }

  clear_fault() {
    if (this.f.value.username == "" && this.f.value.password == "") {
      this.loginFault = false;
    }
  }
  togglePassword() {
    this.showPass = !this.showPass;
  }
}
