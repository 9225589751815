import { Injectable, ErrorHandler } from "@angular/core";

@Injectable()

export class GlobalErrorHandler implements ErrorHandler{
    handleError(error:any):void{
        // const chunkFailedMessage  = /Loading chunk [\d]+ failed/;
        const chunkFailedMessage  = /Loading chunk (\S)* failed/;
        if (chunkFailedMessage.test(error.message)){
            window.location.reload()
        }
        const listenerFailed:any = /A listener indicated an asynchronous */;
        if (listenerFailed.test(error.message)){
          window.location.reload()
      }
    }
}