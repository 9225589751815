import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { MethodServices } from 'src/services/method-services';

@Component({
    selector:'git-datepicker',
    templateUrl:'git-datepicker.html',
    styleUrls:['git-datepicker.scss']
})

export class gitDatepicker implements OnInit, AfterViewInit {
    @Output() changeDateValue = new EventEmitter<any>()
    @Input() modelDatepicker:any;
    @Input() modelTimepicker:any;
    @Input() gitClass:string;
    @Input() gitRequired:boolean = false;
    @Input() gitFormatDate:string;
    @Input() gitLabel:string;
    @Input() minDate?:any;
    @Input() maxDate?:any;
    @Input() isDisabled:boolean = false;
    @Input() showTimepicker:boolean = false;
    @Input() usingTimepicker:boolean = false;
    @Input() minTime:Date = new Date()
    @Input() maxTime:Date = new Date()
    @Input() startView?:any = 'day'

    firstLoad: boolean = true
    isDarkMode:boolean = false
    isValidTimepicker:boolean = false


    constructor(private methodServices: MethodServices) {
        this.methodServices.subject_DarkMode.subscribe((result)=>{
            this.methodServices.statusDark = result
            this.isDarkMode = result
        })

    }

    ngOnInit() {
        this.minTime.setHours(0)
        this.minTime.setMinutes(0)
        this.maxTime.setHours(23)
        this.maxTime.setMinutes(59)
    }

    ngAfterViewInit() {
        this.firstLoad = false
    }

    changeProcDate($event) {
        if (!this.firstLoad) {
            if ($event instanceof KeyboardEvent)
                this.changeProcDate(null)
            else {
                this.changeDateValue.emit($event)
            }
        }
    }

    onOpenCalendar(container) {
        if (this.gitFormatDate == 'MMM-yyyy') {
            container.monthSelectHandler = (event: any): void => {
                container._store.dispatch(container._actions.select(event.date));
            };
            container.setViewMode('month');
        }
    }
}
