// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  tokenUrl:
    "https://sso.starconnect.id/auth/realms/git/protocol/openid-connect/token",
  encryptKey: "q@36^5vGFK)~z#rW",
  secretKey: "secret-key-58923",
  apiDomain_Lookup: "https://api.gitsolutions.id",
  baseUrlImage: "/api/images?path=",
  baseUrl: "https://api.gitsolutions.id", // NOT USE, ONLY FOR OTHER METHOD SERVICE
  urlApiMaster: "https://apimaster.starconnect.id",
  loginUrl: "https://login.starconnect.id/#/login",
  mapApiKey: "AIzaSyAQlSiJZaDaYKQEUfSbtVay6fG21hmtX8I",
  basicAuthentication: "Basic dGFsZW50c19tdGY6cWEzNGhkamZ5dHU0NXJ0ZmRzZmJnZmo=", //IDS
  urlSnapMidtrans: "https://app.midtrans.com/snap/snap.js",
  clientKeyMidtrans: "Mid-client-pcfvhqGOxxwAlgrR",
  timeoutms: 35000,
};
