<div>
  <app-sidebar></app-sidebar>
  <div class="main-content">
    <!-- Top navbar -->
    <div class = 'ml--1'>
      <app-navbar></app-navbar>
      <!-- Pages -->
      <div [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
      </div>
      <div class = 'mt-3'>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>
