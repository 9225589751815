<!-- Skeleton Global -->
<div class = 'row p-1' style = 'background-color:var(--git-skeleton);box-shadow:1px 1px 6px 1px transparent;
border-radius:20px'>
    <div class = 'col-12'>
        <div class = 'row'>
            <div class = 'col-12 pb-2'>
                <div class = 'row'>
                    <div class = 'col-2'>
                        <ngx-skeleton-loader animation="progress-dark"
                            [theme]="{height:'24px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class = 'col-10 text-right'>
                        <ngx-skeleton-loader animation="progress-dark"
                            [theme]="{height:'24px',width:'15%'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader animation="progress-dark"
                        class = 'ml-2'
                            [theme]="{height:'24px',width:'15%'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            <div class = 'col-12 mt--5'>
                <hr style = 'border:1px solid lightsteelblue'>
            </div>
        </div>
        <div class = 'row' *ngFor = 'let seq of [1,2,3,4,5]'>
            <div class = 'col'>
                <ngx-skeleton-loader animation="progress-dark"
                        count = "1" [theme] = "{height:'24px',width:'30%'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader animation="progress-dark" [theme] = "{
                    'margin-top':'-9px',height:'46px'}">
                </ngx-skeleton-loader>
            </div>
            <div class = 'col'>
                <ngx-skeleton-loader animation="progress-dark"
                        count = "1" [theme] = "{height:'24px',width:'30%'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader animation="progress-dark" [theme] = "{
                    'margin-top':'-9px',height:'46px'}">
                </ngx-skeleton-loader>
            </div>
        </div>

        <!-- Tabel Detail -->
        <div class = 'row mt-3'>
            <div class = 'col-2 pb-2'>
                <ngx-skeleton-loader animation="progress-dark"
                    [theme]="{height:'24px'}">
                </ngx-skeleton-loader>
            </div>
            <div class = 'col-12 mt--5'>
                <hr style = 'border:1px solid lightsteelblue'>
            </div>
        </div>
        <div class = 'row'>
            <div class = 'col-8'>
                <ngx-skeleton-loader animation="progress-dark"
                    [theme]="{height:'24px',width:'10%'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader animation="progress-dark" 
                    class = 'ml-1' *ngFor = 'let seq of [1,2,3]'
                    [theme]="{height:'24px',width:'10%'}">
                </ngx-skeleton-loader>
            </div>
            <div class = 'col text-right'>
                <ngx-skeleton-loader animation="progress-dark"
                    [theme]="{height:'24px',width:'60%'}">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class = 'row'>
            <div class = 'col' *ngFor = 'let seq of [1,2,3,4,5,6]'>
                <ngx-skeleton-loader count = "5" animation="progress-dark"
                    [theme]="{height:'24px','margin-top':'-10px'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>
<br>
<!-- End of Skeleton Global  -->