import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MethodServices } from 'src/services/method-services';
import { TranslateService } from '@ngx-translate/core';
import { Workbook }  from 'exceljs'
import moment from 'moment';
// import * as fs from 'file-saver'
const fs = require('file-saver');

@Component({
    selector:'git-btn-export',
    templateUrl:'git-btn-export.html',
    styleUrls: ['git-btn-export.scss']
})

export class gitBtnExport implements AfterViewInit {
    @Input() selectedActive:string;
    @Input() processName:string;
    @Input() needAOD:boolean = false;
    @Input() apiGetData:string;
    @Input() isDisabled:boolean = false;
    @Input() dataTable:any[] = [];
    @Input() configDatatable:any[] = [];
    @Input() dataParams:any;
    @Input() gitBtnClass:string = '';
    @Input() showLabelBtn?:boolean = true;
    @Input() customExportValue?:boolean = false; //for global export datatable (if needed customizable export value [different style from showed in front]) Example: Transaction > Check In Out
    firstLoad: boolean = true
    no_data_to_display:any = this.methodServices.langChangeList['noDataToDisplay'][this.methodServices.langArrNum]['no_data_to_display'];
    exportInformation = {
        progressStatus: '',
        valueProgress: 0,
        totalProgress: 0,
        canSave: false
    }
    
    @ViewChild('modalExport') modalExport:any;

    constructor(private methodServices: MethodServices,
                private translateService:TranslateService) {
        this.translateService.onLangChange.subscribe((result)=>{
            this.methodServices.langCur = result['lang']
            this.methodServices.funcLangChange(result['lang'])
            this.no_data_to_display = this.methodServices.langChangeList['noDataToDisplay'][this.methodServices.langArrNum]['no_data_to_display']
        })
    }
   
    ngAfterViewInit() {
        this.firstLoad = false
    }

    openModalExport() {
      this.exportInformation.totalProgress = 0
      this.exportInformation.valueProgress = 0
      this.exportInformation.progressStatus = 'Waiting Progress'
      this.exportInformation.canSave = false
      this.modalExport.show()
    }

    handler(attr, event) {
        if (attr == 'onShown') {
            this.generate(this.dataTable, this.configDatatable)
        }
    }
    generate(_data, _dataTableConfig){
        let wb = new Workbook()
        let ws = wb.addWorksheet('Sheet1',{pageSetup:{paperSize:9,orientation:'portrait'},
            properties:{tabColor:{argb:'D9FF00'}}})
        let titleTable = []

        let data = []
        wb.views = [{x:1000,y:0,width:20000,height:20000,firstSheet:0,activeTab:1,visibility:'veryHidden'}]

        // ws.addRow(['Code','Name','Description'])
        // ws.autoFilter = 'A1:C1'

        // data = [['Good','Change','Better'],
        //         ['BetterBest','Change Dess','Better Most']]

        // ws.columns = [
        //   {header:'Id',key:'id'},
        //   {header:'Code',key:'code'},
        //   {header:'Description',key:'description'}
        // ]
        // ws.columns.forEach(column=>{
        //   column.width = column.header.length
        // })

        // let title = ws.addRow(['Judul Table'])
        // title.font = {size:20,bold:true}

        // ws.mergeCells('A1:E1')
        // ws.addRows(data)

        //Report Name
        // let reportName = ws.addRow(['Laporan Data Table'])
        // reportName.font = {size:15}

        // ws.addRow([])
        // ws.addRow([])
        this.exportInformation.totalProgress = Number(_data.length)
        this.exportInformation.progressStatus = 'Starting Export File'
        for(let data of _dataTableConfig) {
            titleTable.push(data.label)
        }


        //Column Header Name
        let titleParse = ws.addRow(titleTable)
        titleParse.eachCell((cell,number)=>{
            cell.font = {color:{argb:'000000'},bold:true}
            cell.fill = {type:'pattern',
                pattern:'solid',
                fgColor:{argb:'8DB4E2'},
                bgColor:{argb:'FFFFFF'}
            }
            cell.border = {top:{style:'thin'},bottom:{style:'thin'},
                left:{style:'thin'},right:{style:'thin'}}
        })

        let idxCol = 1;
        titleTable.forEach((result)=>{
            ws.getColumn(idxCol).width = result.length
            idxCol++;
        })

        if (_data.length > 0){
            let index = 1;
            _data.forEach((hasilTemp)=>{
                setTimeout(() => {
                    if (hasilTemp instanceof Array){
                        let tempRes = ws.addRow(hasilTemp)
                        tempRes.eachCell((cell,number)=>{
                            if (index == data.length){
                                cell.border = {left:{style:'thin'},right:{style:'thin'},
                                    bottom:{style:'thin'}}
                            }
                            else{
                                cell.border = {left:{style:'thin'},right:{style:'thin'}}
                            }
                        })

                        let idx = 1;
                        hasilTemp.forEach(hasilTemp2=>{
                            if (hasilTemp2.length > ws.getColumn(idx).width){
                                ws.getColumn(idx).width = hasilTemp2.length
                            }
                            idx++;
                        })
                    }
                    else
                    if (hasilTemp instanceof Object){

                        let arrTemp = [];
                        for (let data of _dataTableConfig) {
                            if (data.name != 'no') {
                                if (hasilTemp[data.name]!=null){
                                    if (!this.customExportValue){
                                        if (moment(hasilTemp[data.name], "DD-MMM-YYYY HH:mm:ss", true).isValid()){
                                            arrTemp.push(moment(hasilTemp[data.name]).format("YYYY-MM-DD HH:mm:ss"))
                                        } else if (moment(hasilTemp[data.name], "DD-MMM-YYYY", true).isValid()){
                                            arrTemp.push(moment(hasilTemp[data.name]).format('YYYY-MM-DD'))
                                        } else {
                                            arrTemp.push(hasilTemp[data.name])
                                        }
                                    } else {
                                        if (typeof data.isCustomValue != "undefined"){
                                            //Penambahan data value yang fleksible (need CustomValue dari menu depan)
                                            arrTemp.push(hasilTemp[(data.name)+"CustomValue"] == null ? '' : hasilTemp[(data.name)+"CustomValue"])
                                        } else {
                                            if (moment(hasilTemp[data.name], "DD-MMM-YYYY HH:mm:ss", true).isValid()){
                                                arrTemp.push(moment(hasilTemp[data.name]).format("YYYY-MM-DD HH:mm:ss"))
                                            } else if (moment(hasilTemp[data.name], "DD-MMM-YYYY", true).isValid()){
                                                arrTemp.push(moment(hasilTemp[data.name]).format('YYYY-MM-DD'))
                                            } else {
                                                arrTemp.push(hasilTemp[data.name])
                                            }
                                        }
                                    }
                                } else {
                                    arrTemp.push("")
                                }
                            } else {
                                arrTemp.push(index)
                            }
                        }
                        let tempRes = ws.addRow(arrTemp)
                        tempRes.eachCell((cell,number)=>{
                            if (index == _data.length){
                                cell.border = {left:{style:'thin'},right:{style:'thin'},
                                    bottom:{style:'thin'}}
                            }
                            else{
                                cell.border = {left:{style:'thin'},right:{style:'thin'}}
                            }
                        })

                        let idx = 1;
                        arrTemp.forEach((hasilTemp2)=>{
                            if (hasilTemp2.length > ws.getColumn(idx).width){
                                ws.getColumn(idx).width = hasilTemp2.length
                            }
                            idx++;
                        })
                    }
                    this.exportInformation.valueProgress = index
                    this.exportInformation.progressStatus = index + '/' + this.exportInformation.totalProgress + ' Exporting File'
                    if (index === _data.length) {
                        this.exportInformation.canSave = true
                    }
                    index++;
                }, 50)
            })
        }
        else{
          this.exportInformation.canSave = true
          this.exportInformation.valueProgress = 1
          this.exportInformation.totalProgress = 1
        }

        // {pageSetup:{paperSize:5,orientation:"landscape"}
        let interval = setInterval(() => {
            if (this.exportInformation.canSave) {
                idxCol = 1;
                for (var i = 0;i < titleTable.length;i++){
                    ws.getColumn(idxCol).width += 1
                    idxCol++;
                }

                wb.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let filename = "export_"+this.methodServices.formatDate(new Date(), 'YYYYMMDDHHMM')+".xlsx"
                    fs.saveAs(blob,filename)
                    this.exportInformation.progressStatus = 'Exporting Status Done!'
                });
                clearInterval(interval)
            }
        })
    }
}
