<div class="container-fluid">
  <footer class="footer">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a
            href="https://gemainovasi.id"
            class="font-weight-bold ml-1"
            target="_blank"
            >PT Gema Inovasi Teknologi</a
          >
        </div>
      </div>
    </div>
  </footer>
</div>
