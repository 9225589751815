export class BuildDetails {
    get buildNumber(): string {
      return <string>this._buildNumber;
    }
  
    set buildNumber(value: string) {
      this._buildNumber = value;
    }
    private _buildNumber: string | undefined;
  }
  