import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MethodServices } from 'src/services/method-services';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpParams, HttpClient } from '@angular/common/http';

@Component({
    selector: 'blankpage-component',
    templateUrl: 'blankpage.component.html',
})

export class BlankPageComponent implements OnInit {
    keyjson:any;
    username:any;
    config:any;

    constructor(private router:Router,
                private activatedRoute:ActivatedRoute,
                private methodServices:MethodServices,
                private http:HttpClient){
    }


    prevToken:any;
    ngOnInit(){
        let statusKeyjson:boolean = false
        let statusUser:boolean = false
        var colors = this.methodServices.arrColor
        let randomColorAvatar = colors[Math.floor(Math.random() * colors.length)];

        this.activatedRoute.queryParams.subscribe((result)=>{

            if (typeof(result.keyjson) != 'undefined'){
                this.keyjson = decodeURIComponent(result.keyjson)

                // JIKA ADA KEYJSON di parameter URL, maka di clear semua item localstorage (asumsinya user baru masuk)
                localStorage.clear()
            }
            if (typeof(result.username) != 'undefined'){
                this.username = decodeURIComponent(result.username)
            }

            let k_temp:any = '';
            if (typeof(result?.['k']) != 'undefined'){

              try{
                k_temp = JSON.parse(window.atob(decodeURIComponent(result['k'])));

                if (typeof k_temp?.['lang'] != 'undefined'){
                  if (typeof (localStorage.getItem("GITLANG")) != 'undefined' && localStorage.getItem("GITLANG") != null){
                    localStorage.removeItem("GITLANG")
                  }

                  localStorage.setItem("GITLANG", this.methodServices.ivSha256Encrypt(k_temp?.['lang']));
                }
                if (typeof k_temp?.['darkMode'] != 'undefined'){
                  if (typeof (localStorage.getItem("GITDMOD")) != 'undefined' && localStorage.getItem("GITDMOD") != null){
                      localStorage.removeItem("GITDMOD")
                  }

                  localStorage.setItem("GITDMOD", this.methodServices.ivSha256Encrypt(k_temp?.['darkMode']));
                }
                
              }catch(e){
                k_temp = ''
              }

            }

            // KEYCLOAK
            // if (this.keycloakService.isLoggedIn){
            //     let token = this.keycloakService.getKeycloakInstance().token

            //     // console.log(this.keycloakService.getKeycloakInstance().refreshToken)
            //     this.keycloakService.loadUserProfile().then((data)=>{
            //         console.log(data.firstName)
            //     })
            //     // console.log('UPDATE TOKEN \n')

            //     // console.log('\n')
            //     // if (token != this.keycloakService.getKeycloakInstance().token){
            //     //     console.log('TIDAK SAMA')
            //     //     console.log(this.keycloakService.getKeycloakInstance().token)
            //     // }else{
            //     //     console.log('SAMA')
            //     // }

            // }

            // alert(accessToken)
            // localStorage.clear();
            
            if (typeof(result.keyjson) != 'undefined'){

                let decKeyjson = window.atob(this.keyjson)
                let decIVKeyjson = this.methodServices.ivSha256Encrypt(decKeyjson)
                
                const helper = new JwtHelperService
                let getDecodeToken = helper.decodeToken(JSON.parse(decKeyjson).access_token)
                let instance_api = getDecodeToken.instance_api[0] // menggunakan hardcode dulu
                // let instance_api = environment.baseUrl

                let keyjson = this.methodServices.ivSha256Encrypt('keyjson')
                // localStorage.clear()
                localStorage.setItem(keyjson,decIVKeyjson)

                // GET API URL
                if (typeof localStorage.getItem('GITINSTAPI') != 'undefined' && localStorage.getItem('GITINSTAPI') != null) {
                    localStorage.removeItem('GITINSTAPI')
                }
                // let instanceAPI = this.methodServices.ivSha256Encrypt('instance_api')
                let instanceAPIValue = this.methodServices.ivSha256Encrypt(instance_api)
                localStorage.setItem('GITINSTAPI',instanceAPIValue)


                // SET AOD to Local Storage
                this.methodServices.setAODToLocalStorage(new Date())

                localStorage.setItem('GITAV', this.methodServices.ivSha256Encrypt(randomColorAvatar))
                localStorage.setItem('GITAL', this.methodServices.ivSha256Encrypt('true'))

                // if (typeof this.config != 'undefined') {
                //     if (typeof localStorage.getItem('GITDMOD') == 'undefined') {
                //         localStorage.setItem('GITDMOD', this.config.darkMode)
                //     }
                //     if (typeof localStorage.getItem('GITLANG') == 'undefined') {
                //         localStorage.setItem('GITLANG', this.config.lang)
                //     }
                // }


                // REVISI TAMBAH AUTHORITIES TOKEN
                // let access_token = JSON.parse(decKeyjson)['access_token']
                // let refresh_token = JSON.parse(decKeyjson)['refresh_token']
                
                // this.methodServices.jwt_access_token = access_token
                // this.methodServices.jwt_refresh_token = refresh_token

                // let jwt = new JwtHelperService
                // let jwtDecode = jwt.decodeToken(access_token)
                // this.methodServices.jwt_tenant_id = jwtDecode['tenant_id'][0]

                // this.methodServices.getTenantApi('/api/user/profile',(result,status)=>{  
                //   if (status != 'Error'){
                //     if (typeof (localStorage.getItem("GITAUTHTKN")) != 'undefined' && localStorage.getItem("GITAUTHTKN") != null) {
                //       localStorage.removeItem("GITAUTHTKN")
                //     }

                //     this.methodServices.jwt_authorities_value = result['authoritiesToken']
                //     localStorage.setItem("GITAUTHTKN",this.methodServices.ivSha256Encrypt(result['authoritiesToken']))
                //   }
                // },"user/profile")
                

                // alert(instanceAPIValue)
                // let aaa = new JwtHelperService
                // let bbb = aaa.decodeToken(('eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVQmppQkZXYmM4NnpBaER0M1QtTUJ6cnl3R3FnYkF5QlFxYjRjN0w3VHpNIn0.eyJleHAiOjE2MTEyNDkxNzIsImlhdCI6MTYxMTIxMzE3MiwianRpIjoiN2FkYjBjNDktOTA3OC00MzIyLWI5N2UtMWZiYTY2OThkZmMyIiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay1kZXYuZ2l0c29sdXRpb25zLmlkL2F1dGgvcmVhbG1zL0dJVCIsInN1YiI6ImFhOTMzMzEyLTAyOGQtNDczNC1hOWE0LWEwZjE2Y2VkNjllMSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImdpdC1jbGllbnQiLCJzZXNzaW9uX3N0YXRlIjoiOTgzMTFmZmEtYjgzYS00MjY1LTkzOGEtOThhNThmMjU3YWEzIiwiYWNyIjoiMSIsInNjb3BlIjoiZ2l0c2NvcGUiLCJ0ZW5hbnRfaWQiOlsiODFmYjY3M2EtMzRmYy00NzljLWFjN2MtNjA1M2QxNzcxMjhhIl0sImluc3RhbmNlX3VybCI6WyJodHRwczovL2Rldi5naXRzb2x1dGlvbnMuaWQiXSwiaW5zdGFuY2VfYXBpIjpbImh0dHBzOi8vYXBpLWRldi5naXRzb2x1dGlvbnMuaWQiXSwiZW1wX2lkIjoiMzBlMWRlMjEtNTRiNC0xMWViLThiNWUtNTYwMDAyZTQ1NjFmIn0.nMrgrhirNpYoVFNXxeG5DHpiTBG3bRAtUFGj7V4mwP6eY2k_UYpbLuTofVi0vPlm6PwOb82WueTnOGog3Y9CLprNuyWfmxJ-7gUPgsVBVDHC4AHk3TzHegEqb67pjBkiuT9rGcCnWDCddkWktwxXyy4G0gJwLPVYdB_-CNYcUwwNs1oPQe3duiDMVMWiW7Zb-m0HiWwjviy_f09q2-bjOx-V-pYnNSSOmU5w_N4yBuelh-5PRYtOOpaCvAayMKow62UurBghh8GVHaUu59_3vs-8bWLbHPepVWhlXRVMZvjPy-2Gfqkx41XlTrVmxcApKrGccY389J16LjjLyZv4Lg'))
            } else{
                if(localStorage.length != 0){
                    let valStor:any;
                    let keyStor:any;
                    let existsKeyjson:boolean = false;
                    for (var i = 0;i < localStorage.length;i++){
                        keyStor = this.methodServices.ivSha256Decrypt(localStorage.key(i))
                        valStor = this.methodServices.ivSha256Decrypt(localStorage.getItem(localStorage.key(i)))
                        if (keyStor == 'keyjson'){
                            existsKeyjson = true
                        }
                    }
                    if (!existsKeyjson){
                        localStorage.clear()
                        // window.location.href = "https://login-dev.gitsolutions.id/#/login?k=" + this.methodServices.ivSha256Encrypt('logout')
                        let keyjson = {
                            'darkMode': "false",
                            'lang': "en"
                        }
                
                        let encryptKeyjson = this.methodServices.ivSha256Encrypt(JSON.stringify(keyjson))
                        this.router.navigate(['login'])
                        // window.location.href = environment.loginUrl + "?k=" + encryptKeyjson
                        // window.location.href = environment.loginUrl + "?k=" + this.methodServices.ivSha256Encrypt('logout')
                        return

                    }else{
                        if (localStorage.getItem('GITAOD') == null){
                            this.methodServices.setAODToLocalStorage(new Date())
                        }
                        if (localStorage.getItem('GITAV') == null) {
                            localStorage.setItem('GITAV', this.methodServices.ivSha256Encrypt(randomColorAvatar))
                        }
                        if(localStorage.getItem('GITAL') == null) {
                            localStorage.setItem('GITAL', this.methodServices.ivSha256Encrypt('true'))
                        }

                        this.router.navigate(['home','profile'])
                    }
                }else{
                    // window.location.href = "https://login-dev.gitsolutions.id/#/login?k=" + this.methodServices.ivSha256Encrypt('logout')
                    let keyjson = {
                        'darkMode': "false",
                        'lang': "en"
                    }
            
                    let encryptKeyjson = this.methodServices.ivSha256Encrypt(JSON.stringify(keyjson))
                    this.router.navigate(['login'])
                    // window.location.href = environment.loginUrl + "?k=" + encryptKeyjson
                    // window.location.href = environment.loginUrl + "?k=" + this.methodServices.ivSha256Encrypt('logout')
                    return
                }
            }

            if (statusUser == false){
                if (typeof(result.username) != 'undefined'){
                    let decUser = window.atob(this.username)
                    let decIVUser = this.methodServices.ivSha256Encrypt(decUser)
                    let usernameLabel = this.methodServices.ivSha256Encrypt('username')

                    // let decIVUser2 = this.methodServices.ivSha256Encrypt(decUser)
                    localStorage.setItem(usernameLabel,decIVUser)
                }
            }

            // this.generateToken(()=>{
                this.router.navigate(['home','profile'])
            // })    //GENERATE TOKEN DIPAKAI WAKTU DEVELOPMENT SAJA
        })   
        
        

        // Remove 'username' from localstorage
        // let statusUser:boolean = false;
        // for (var i = 0;i<localStorage.length;i++){
        //     let decryptLocal = this.methodServices.ivSha256Decrypt(localStorage.key(i))

        //     if (decryptLocal.trim() == 'username')
        //     {
        //         localStorage.removeItem(localStorage.key(i));

        //         let username = this.methodServices.ivSha256Encrypt('username')
        //         localStorage.setItem(username,null)
        //         statusUser = true
        //     }
            
            // else if(decryptLocal.trim() == 'password'){
            //     localStorage.removeItem(localStorage.key(i));
            //     let password = this.methodServices.ivSha256Encrypt('password')
            //     localStorage.setItem(password,null)
            //     statusPass = true;
            // }
        // }

        // if (statusUser == false){
        //     let username = this.methodServices.ivSha256Encrypt('username')
        //     localStorage.setItem(username,null)
        // }

    }

    refreshProc(){
    }

    generateToken(callback){
        let keyForm = new HttpParams({fromObject:{
            'grant_type':'password',
            'client_id':'git-client',
            'username':'fredy.fernando',
            'password':'admin'
          }})
          
          this.http.post('https://keycloak-dev.gitsolutions.id/auth/realms/git/protocol/openid-connect/token'
            ,keyForm)
          .subscribe((res)=>{
                let usernameLabel = this.methodServices.ivSha256Encrypt('username')
                let usernameValue = this.methodServices.ivSha256Encrypt('fredy.fernando')
                let keyjsonLabel = this.methodServices.ivSha256Encrypt('keyjson')
                let keyjsonValue = this.methodServices.ivSha256Encrypt(JSON.stringify(res))

                localStorage.setItem(usernameLabel,usernameValue)
                localStorage.setItem(keyjsonLabel,keyjsonValue)
                

              const helper = new JwtHelperService
              let getDecodeToken = helper.decodeToken(res['access_token'])
              let instance_url = getDecodeToken.instance_url[0]
              let keyjson = JSON.stringify(res)
                callback();

            },(err)=>{
              alert('Error Set Token')
            })
      
    }
}
