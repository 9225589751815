import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MethodServices } from 'src/services/method-services'; //utama
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { KeycloakService } from 'keycloak-angular';

import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpLoaderImageComponent } from 'src/app/global/http-loader-image/http-loader-image.component'

@Component({
    selector: 'register-demo',
    templateUrl: './register-demo.component.html'
})
export class RegisterDemoComponent implements OnInit {
  
  public httpLoaderImage = HttpLoaderImageComponent
  
  keyjson:any;

  test: Date = new Date();
  focus;
  focus1;
  loginFault = false;
  error_code: any;
  error_msg: any;
  access_token:any;
  readOnlyInput:boolean = false;
  validEmail:boolean = true;
  status_aktif_register:boolean = true;
  namaLengkap:string = 'Gunardi';
  @ViewChild("f") f: NgForm;

  @ViewChild('nativeNamaLengkap') nativeNamaLengkap:ElementRef
  @ViewChild('nativeAlamatEmail') nativeAlamatEmail:ElementRef
  @ViewChild('nativeNamaPerusahaan') nativeNamaPerusahaan:ElementRef
  @ViewChild('nativeNoHandphone') nativeNoHandphone:ElementRef
  @ViewChild('nativeJabatan') nativeJabatan:ElementRef
  @ViewChild('nativeJumlahKaryawan') nativeJumlahKaryawan:ElementRef
  
  loading_progress = false;
  checked:boolean=false;
  checked_agree:boolean= false;
  user:string;
  pass:string;
  title:string;
  showPass:boolean = false;
  
  keyjsonStorage:any;
  instanceURLStorage:any;
  siteKey:string = '6LfyFvkbAAAAAKwRVbLypthpNxK9dOEGtOceqTmV';
  secretKey:string = '6Ld4FPkbAAAAAPT5Bnme2AqToENtPG3uTGOcGYgG'

  constructor(private location:Location,private methodServices: MethodServices, private router: Router,
          private http:HttpClient,
          private keycloakServices:KeycloakService) {}
  
  logOuts(){
      this.keycloakServices.logout()
      // "http://localhost:4200"
  }

  ngOnInit() {

    // this.aFormGroup = this.formBuilder.group({
    //   recaptcha: ['', Validators.required]
    // });
    
    // DIRECT to WEB PAYROLL
    // this.activatedRoute.queryParams.subscribe((result)=>{
      
    //   if (localStorage.length > 0){
    //     for (var i = 0;i < localStorage.length;i++){
    //       try{
    //         if (this.methodServices.ivSha256Decrypt(localStorage.key(i)) == 'keyjson'){
    //           if (typeof(result.k) != 'undefined'){
    //             if(this.methodServices.ivSha256Decrypt(result.k) == 'logout'){
    //               localStorage.removeItem(localStorage.key(i))
    //             }
    //           }
    //           this.keyjsonStorage = localStorage.key(i)
    //         }
            
    //         if (this.methodServices.ivSha256Decrypt(localStorage.key(i)) == 'instance_url'){
    //           this.instanceURLStorage = localStorage.key(i)
    //         }
    //       }catch(e){}

    //     }

    //     // let intervalKey = setInterval(()=>{
    //       if (typeof(this.keyjsonStorage) != 'undefined' && this.keyjsonStorage!=null &&
    //           this.keyjsonStorage != ''){
    //           if (this.instanceURLStorage != '' && typeof(this.instanceURLStorage) != 'undefined'){
    //             window.location.href = this.methodServices.ivSha256Decrypt(localStorage.getItem(this.instanceURLStorage))
    //           }
    //       }
    //     // })

    //   }
      
    // })

    // END DIRECT

    // let aaa = new Date(1609141734*1000)
    // let bbb = new Date(1609141674*1000)
    // alert(aaa + '\n' + bbb)

    // let keyjson = this.keycloakServices.getKeycloakInstance()

    // let strKeycloak = JSON.stringify(keyjson)
    // console.log('Key JSON : \n')
    // console.log(keyjson)
    // console.log('\nstrKeycloak : \n')
    // console.log(strKeycloak)
    
    // let parseKeycloak = JSON.parse(strKeycloak)
    // console.log('Parse KeyCloak \n')
    // console.log(parseKeycloak)

    // window.location.href = "https://login-dev.gitsolutions.id/#/login?keyjson=" + strKeycloak

    // this.activatedRoute.queryParams.subscribe((result)=>{
    //   alert(result.keyjson)
    //   this.keyjson = result.keyjson
    //   console.log(JSON.parse(result.keyjson))
    // })

    // return

    // let keyForm = new URLSearchParams()
    // keyForm.set('grant_type','password')
    // keyForm.set('client_id','helloworld-client')
    // keyForm.set('username','gunardi.halim')
    // keyForm.set('password','admin')

    // this.keycloakServices.loadUserProfile().then((result)=>{
    //   console.log('user profile \n')
    //   console.log(result)
    // })


    // let keyForm = new HttpParams({fromObject:{
    //   'grant_type':'password',
    //   'client_id':'helloworld-client',
    //   'username':'gunardi.halim',
    //   'password':'admin'
    // }})

    // let keyForm = new FormData()
    // keyForm.append('grant_type','password')
    // keyForm.append('client_id','helloworld-client')
    // keyForm.append('username','gunardi.halim')
    // keyForm.append('password','admin')
    
    // this.http.post('https://keycloak-dev.gitsolutions.id/auth/realms/helloworldrealm/protocol/openid-connect/token'
    //   ,keyForm)
    // .subscribe((res)=>{
    //     console.log('Http Keycloak Dev Form urlencoded \n')
    //     console.log(res)
    //   },(err)=>{
    //     alert('Error rrrrrrrrrrrrrrrrrr')
    //   })

      

    //   .subscribe((result)=>{
    //     alert(result)
    //     console.log('after post : ' + result)
    // },(err)=>{
    //   alert('Error')
    // })
    

    // console.log(this.keycloakServices.getToken())

    // if (this.keycloakServices.isTokenExpired(40)){
      // alert('expired ' + new Date())


      // this.keycloakServices.updateToken(50).then((refreshed)=>{
      //   if (refreshed){
      //     alert('success refresh token')
      //     console.log('refresh token')
      //     console.log(this.keycloakServices.getKeycloakInstance())
      //   }
      //   else{
      //     alert('token is still valid')
      //     console.log('token is still valid')
      //     console.log(this.keycloakServices.getKeycloakInstance())
      //   }
      // })

      // .catch(function(){
      //    alert('failed to refresh token, or the session has expired')
      //  })
    // }
    // ---- ONLY TESTING ABOVE ------ 

    this.checked_agree = false;
    // if (localStorage.getItem('ZEU@AL!') !== null) {
    //   // this.user = window.atob(localStorage.getItem('ZEU@AL!'))
    //   this.user = CryptoJS.AES.decrypt(localStorage.getItem('ZEU@AL!'),environment.encryptKey).toString(CryptoJS.enc.Utf8)
    //   // alert(window.atob(localStorage.getItem('ZEU@AL!'))  )
    //   // this.checked = true;
    //   this.checked_agree = true
    // }
    // if (localStorage.getItem('SPS!#WU') !== null) {
    //   // this.pass = window.atob(localStorage.getItem('SPS!#WU'))
    //   this.pass = CryptoJS.AES.decrypt(localStorage.getItem('SPS!#WU'),environment.encryptKey).toString(CryptoJS.enc.Utf8)
    // }
    this.title = this.location.prepareExternalUrl(this.location.path())
  }

  login_page() {
    // var dataBody =
    // // "username=" + username + "&password=" + password;
    // {
    //   "username":this.f.value.username,
    //   "password":this.f.value.password
    //   }
    //   // this.http.post(environment.baseUrl + "/oauth/token?grant_type=password",dataBody,{
          
    //   this.http.post(environment.baseUrl + "/public/api/login/hrcentral",dataBody,{
    //   headers: {
    //         Authorization: environment.basicAuthentication,
    //         "Content-Type": "application/json"
    //         // "Content-Type": "application/x-www-form-urlencoded"
    //         }
    //   })
    //   .subscribe(hasil => {
    //                 this.methodServices.getToken(this.checked_agree,hasil,this.f.value.username,this.f.value.password);
    //                 // callback(this.error_msg,this.access_token)
    //                 },
    //             err => {
    //                     this.methodServices.getError(err,err.status);
    //                     // callback(this.error_msg,this.access_token)
    //                   }
    //               )
    
    // if (this.checked_agree == true) {
    //   if (localStorage.getItem('ZEU@AL!') !== null) {
    //     localStorage.removeItem('ZEU@AL!')
    //   }
    //   localStorage.setItem('ZEU@AL!',CryptoJS.AES.encrypt(this.f.value.username,environment.encryptKey).toString())
    //   this.checked_agree = true
    //
    //   if (localStorage.getItem('SPS!#WU') !== null) {
    //     localStorage.removeItem('SPS!#WU')
    //   }
    //   localStorage.setItem('SPS!#WU',CryptoJS.AES.encrypt(this.f.value.password,environment.encryptKey).toString())
    // }
    

    let keyForm = new HttpParams({fromObject:{
      'grant_type':'password',
      'client_id':'git-client',
      'username':this.f.value.username,
      'password':this.f.value.password
    }})
    
    // this.http.post('https://keycloak-dev.gitsolutions.id/auth/realms/git/protocol/openid-connect/token'
    this.http.post(environment.tokenUrl
      ,keyForm)
    .subscribe((res)=>{
        // console.log('Http Keycloak Dev Form urlencoded \n')
        // console.log(res)
        // alert(res['access_token'])
        
        const helper = new JwtHelperService
        let getDecodeToken = helper.decodeToken(res['access_token'])
        let instance_url = getDecodeToken.instance_url[0]
        let instance_api = getDecodeToken.instance_api[0]
        let tenant_id = getDecodeToken.tenant_id[0]

        this.http.get(instance_api + '/api/v1/user/profile',{
            headers:{
              'Authorization':'Bearer ' + res['access_token'],
              'X-TenantID':tenant_id
            }
        }).subscribe((resprofile)=>{
          let getDecodeTokenAuth = helper.decodeToken(resprofile['authoritiesToken'])
          let authorities = getDecodeTokenAuth['authorities']
          
          let cekWebAccess = authorities.find((resAuth)=>{
            return resAuth.toLowerCase() == 'web access'
          })
          if (!cekWebAccess){
            this.error_msg = 'You are not authorized to access this application'
          }else{
            let keyjson = JSON.stringify(res)

            let encURi = encodeURIComponent(window.btoa(keyjson))
            let encUser = encodeURIComponent(window.btoa(this.f.value.username))

            // if (this.keyjsonStorage != '' && typeof(this.keyjsonStorage) != 'undefined'){
            //   localStorage.removeItem(this.keyjsonStorage)
            // }
            // localStorage.setItem(this.methodServices.ivSha256Encrypt('keyjson'),encURi)
            
            // if (this.instanceURLStorage != '' && typeof(this.instanceURLStorage) != 'undefined'){
            //   localStorage.setItem(this.instanceURLStorage,this.methodServices.ivSha256Encrypt(instance_url))
            // }else{
            //   localStorage.setItem(this.methodServices.ivSha256Encrypt('instance_url'),this.methodServices.ivSha256Encrypt(instance_url))
            // }
            
            this.router.navigate(['home/profile'])
            // window.location.href =  instance_url + "/#/blankpage?keyjson=" + encURi + 
            //   "&username=" + encUser
          }

        }, (err) => {
          if (err.status == 400 || err.status == 404 || err.status.toString().substring(0,1) == '5'){
            this.error_msg = err.error.message
          }
          else{
            this.error_msg = 'Can\'t connect to server. Please Contact Admin '
          }
        })

        // console.log(encURi)
        // let decURi = window.atob(decodeURIComponent(encURi))
        // console.log('\n Hasil Dec URI \n')
        // console.log(decURi)

        // console.log('\nuri component \n' + encLocation)
      },(err)=>{
        // this.error_msg = err.message
        this.error_msg = 'Invalid username / password'
      })

      // this.methodServices.processToken(
      //   this.checked_agree,
      //   this.f.value.username,
      //   this.f.value.password,
      //       (error_msg_res,access_token_res)=>{
      //             this.error_msg = error_msg_res
      //             this.access_token = access_token_res
      //       }
      //   )
  }

  clear_fault() {
    if (this.f.value.username == "" && this.f.value.password == "") {
      this.loginFault = false;
    }
  }
  togglePassword() {
    this.showPass = !this.showPass;
  }

  wheelProc(event){
      event.preventDefault()
  }

  tryDemo(){
    let namaLengkapFinal = this.nativeNamaLengkap.nativeElement.value.trim()
    let alamatEmailFinal = this.nativeAlamatEmail.nativeElement.value.trim()
    let namaPerusahaanFinal = this.nativeNamaPerusahaan.nativeElement.value.trim()
    let noHandphoneFinal = this.nativeNoHandphone.nativeElement.value.toString().trim()
    let jabatanFinal = this.nativeJabatan.nativeElement.value.trim()
    let jumlahKaryawanFinal = this.nativeJumlahKaryawan.nativeElement.value.trim()
    let checkAgree = this.checked_agree
    
    if (namaLengkapFinal == '' || namaLengkapFinal==null){
      this.methodServices.showToast('Full Name can\'t be blank !','error')
      return
    }

    if (alamatEmailFinal == '' || alamatEmailFinal==null){
      this.methodServices.showToast('Email can\'t be blank or Invalid !','error')
      return
    }
    if (this.methodServices.emailValid(alamatEmailFinal)==null){
      this.methodServices.showToast('Format Email Invalid !','error')
      return
    }

    if (namaPerusahaanFinal == '' || namaPerusahaanFinal==null){
      this.methodServices.showToast('Company can\'t be blank !','error')
      return
    }
    if (noHandphoneFinal == '' || noHandphoneFinal==null){
      this.methodServices.showToast('Phone Number can\'t be blank !','error')
      return
    }
    if (jabatanFinal == '' || jabatanFinal==null){
      this.methodServices.showToast('Job can\'t be blank !','error')
      return
    }
    if (jumlahKaryawanFinal == '' || jumlahKaryawanFinal==null){
      this.methodServices.showToast('Total Employees can\'t be blank !','error')
      return
    }

    if (!checkAgree){
      this.methodServices.showToast('You must check the agreement  !','error')
      return
    }

    let obj = {}
    obj = {
      'fullName':namaLengkapFinal,
      'email':alamatEmailFinal,
      'jabatan':jabatanFinal,
      'companyName':namaPerusahaanFinal,
      'phoneNumber':noHandphoneFinal,
      'totalEmployee':jumlahKaryawanFinal
    }

    this.readOnlyInput = true
    this.checked_agree = true

    this.http.post(environment.urlApiMaster + 'api/demo/request-demo-instance',obj)
    .subscribe((res)=>{
      this.namaLengkap = namaLengkapFinal
      // this.methodServices.showToast(res['message'],'success')
        // setTimeout(()=>{
          // window.location.href = "https://starconnect.id/"
          this.status_aktif_register = false
          this.readOnlyInput = false
          this.checked_agree = false
        // },10300)
    },(err)=>{
      this.readOnlyInput = false
      this.checked_agree = false
      if (typeof(err.error.message) != 'undefined' && err.error.message != '' && 
         err.error.message != null){
        this.methodServices.showToast(err.error.message,'error')
      }
      else{
        this.methodServices.showToast(err.error.error,'error')
      }
    })

  }

  cekEmail(){
    if (this.nativeAlamatEmail.nativeElement.value.trim() == ''){
      this.validEmail = true
    }else{
      if (this.methodServices.emailValid(this.nativeAlamatEmail.nativeElement.value.trim())==null){
        this.validEmail = false
        this.methodServices.showToast('Format Email Invalid !','error')
      }else{
        this.validEmail = true
      }
    }
  }

  backSuccess(){
      window.location.href = "https://starconnect.id/"    
  }

}
