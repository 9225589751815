<style>
  .invalidError {
      border-color: red;
  }
  .btn-header {
      margin-bottom: 0.7rem;
      padding: 0.2rem 1.6rem;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button{
      -webkit-appearance: none;
      appearance: none;
  }
  input[type=number]{
      appearance: textfield;
      -moz-appearance: textfield;
  }

</style>

<skeleton-form-global-component *ngIf = "show_skeleton">
</skeleton-form-global-component>

<form #f='ngForm' 
  [ngClass]="{'d-none': show_skeleton}"
>
<!-- [ngClass]="!skeletonShow ? 'd-block' : 'd-none'" -->

    <!-- LOOPING SECTION -->
      <div class = 'row'
            *ngFor = "let global_form of global_forms">

              <div class = 'col'>
                  <div *ngIf = "global_form?.['section_type'] == 'form'">
                      <h2>{{ global_form?.['section_title'] | translate }}</h2>
                  </div>
              </div>


              <div *ngIf="form_global_stt !== 'new'" class = 'col-12 col-sm-7 text-right'>

                  <!-- (click)="deleteItemId()" -->
                  <button *ngIf = "global_form?.['section_show']?.['delete']?.['show']"
                          type = 'button'
                          class = 'btn btn-icon btn-3 btn-git-danger btn-md py-1 btn-header'
                          (click)="deleteItem(global_form?.['section_show']?.['delete'])"
                          [disabled]="global_button?.['delete']?.['disabled_submit']"
                          >
                          <!-- [disabled]="global_form?.['section_show']?.['delete']?.['disabled']" -->
                      <span class = 'btn-inner--icon'><i class="fas fa-trash"></i></span>
                      <span class="btn-inner--text">{{ 'Delete' | translate }}</span>
                  </button>

                  <button *ngIf = "global_form?.['section_show']?.['history']?.['show']"
                          type = 'button'
                          class = 'btn btn-icon btn-3 btn-git-blue btn-md py-1 btn-header'
                          (click)="historyProc(global_form?.['section_show']?.['history'])"
                          [disabled]="global_button?.['history']?.['disabled_submit']"
                  >
                      <span class = 'btn-inner--icon'><i class="fas fa-history"></i></span>
                      <span class="btn-inner--text">{{ 'History' | translate }}</span>
                  </button>

              </div>


              <div class="col-12 mt-1">

                  <div *ngIf = "global_form?.['section_type'] == 'form'">

                    <!-- LINE HEADER -->
                      <hr class = 'mt--1' style = 'border:1px solid lightsteelblue'
                            *ngIf = "global_form?.['section_line']">

                      <div class = 'row'>
                          
                        <!-- LOOPING COLUMN -->
                          <div [class] = "section_col?.['col_class']" 

                                *ngFor = "let section_col of global_form?.['section_col']">
                                
                                <!-- LOOPING INPUT -->
                                <div class = 'row' *ngFor = "let col_input of section_col?.['col_input']">
                                      <div class = 'col-12'>
                                          <git-datepicker *ngIf = "col_input?.['input_type'] == 'date'"
                                                          [isDisabled]="global_value?.[col_input?.['input_name']]?.['input_disabled_submit']"
                                                          [gitFormatDate]="'dd-MMM-yyyy'" [gitClass]="col_input?.['input_class_input']"
                                                          placeholder=" "
                                                          [gitLabel]="col_input?.['input_label']"
                                                          [gitRequired]="global_value?.[col_input?.['input_name']]?.['input_required'] ?? ''"
                                                          [modelDatepicker]="global_value?.[col_input?.['input_name']]?.['value_date']"
                                                          (changeDateValue)="changeProc('change', col_input, $event)">
                                          </git-datepicker>

                                            <!-- INPUT = 'TEXT' -->
                                          <div [class] = "col_input?.['input_class_formgroup']"
                                                  [ngClass] = "{'required':col_input?.['input_required'] ?? ''}"
                                                  [ngStyle] = "{'display':global_value?.[col_input?.['input_name']]?.['input_hide_change'] ? 'none' : 'block'}"
                                                  *ngIf = "col_input?.['input_type'] == 'text'"
                                              >
                                                  <input type = 'text'
                                                      [id] = "col_input?.['input_id']"
                                                      [class] = "col_input?.['input_class_input']"
                                                      placeholder=" "
                                                      [name] = "col_input?.['input_name']"
                                                      [value]="global_value?.[col_input?.['input_name']]?.['value'] ? global_value?.[col_input?.['input_name']]?.['value'] : null"
                                                      [maxLength]="col_input?.['input_maxlength']"
                                                      [disabled]='global_value?.[col_input?.["input_name"]]?.["input_disabled_submit"]'
                                                      (change)="changeProc('change', col_input)"
                                                      autocomplete="off"
                                                      spellcheck="false"
                                                      autocapitalize="off"
                                                      #native_Input
                                                      >
                                                  <label class = 'form-input-label' [for] = "col_input?.['input_id']">{{ col_input?.['input_label'] | translate }}</label>
                                          </div>
                                            <!-- end INPUT = 'TEXT' -->

                                            <!-- INPUT = 'TEXTAREA' -->
                                          <div [class] = "col_input?.['input_class_formgroup']"
                                                  [ngClass] = "{'required':col_input?.['input_required'] ?? ''}"
                                                  [ngStyle] = "{'display':global_value?.[col_input?.['input_name']]?.['input_hide_change'] ? 'none' : 'block'}"
                                                  *ngIf = "col_input?.['input_type'] == 'textarea'"
                                          >
                                                  <textarea
                                                      [id] = "col_input?.['input_id']"
                                                      placeholder=" "
                                                      [class] = "col_input?.['input_class_input']"
                                                      [name] = "col_input?.['input_name']"
                                                      [value]="global_value?.[col_input?.['input_name']]?.['value'] ? global_value?.[col_input?.['input_name']]?.['value'] : null"
                                                      [maxLength]="col_input?.['input_maxlength']"
                                                      [disabled]='global_value?.[col_input?.["input_name"]]?.["input_disabled_submit"]'
                                                      [rows]='global_value?.[col_input?.["input_name"]]?.["rows"]'
                                                      [style]='global_value?.[col_input?.["input_name"]]?.["style"]'
                                                      (change)="changeProc('change', col_input)"
                                                      autocomplete="off"
                                                      spellcheck="false"
                                                      autocapitalize="off"
                                                      #native_Input
                                                    >
                                                  </textarea>
                                                  <label class = 'form-control-label' [for] = "col_input?.['input_id']">{{ col_input?.['input_label'] | translate }}</label>
                                          </div>
                                            <!-- end INPUT = 'TEXT' -->

                                            <!-- 'ng-select-global' -->
                                          <div [class] = "col_input?.['input_class_formgroup']"
                                                  [ngStyle] = "{'display':global_value?.[col_input?.['input_name']]?.['input_hide_change'] ? 'none' : 'block'}"
                                                  *ngIf = "col_input?.['input_type'] == 'ng-select-global'"
                                          >
                                                <!-- ADA parameter aod / aodTop -->
                                                  <!-- *ngIf = "col_input?.['input_type'] == 'ng-select-global' && show_ngSelectGlobal" -->
                                                  <ng-select-global *ngIf = "global_value?.[col_input?.['input_name']]?.['status_aod_top']"
                                                                    [lookup]="col_input?.['lookup']"
                                                                    [aodTop]="global_value?.[col_input?.['input_name']]?.['aodTopNgSelect']"
                                                                    [placeholderLabel]="global_value?.[col_input?.['input_name']]?.['placeholderLabel']"
                                                                    [valueDefault]="global_value?.[col_input?.['input_name']]?.['value']"
                                                                    [statusDisabled]="global_value?.[col_input?.['input_name']]?.['input_disabled_submit']"
                                                                    [gitLabel]="col_input?.['gitLabel']"
                                                                    [gitRequired]="global_value?.[col_input?.['input_name']]?.['gitRequired']"
                                                                    [param]="global_value?.[col_input?.['input_name']]?.['param']"
                                                                    [size]="col_input?.['size']"
                                                                    (lookupChange)="ngSelectChange($event, col_input?.['lookup'], col_input)">
                                                  </ng-select-global>

                                                  <!-- TIDAK ADA parameter aod / aodTop -->
                                                  <ng-select-global *ngIf = "!global_value?.[col_input?.['input_name']]?.['status_aod_top'] && show_ngSelectGlobal"
                                                                    [lookup]="col_input?.['lookup']"
                                                                    [placeholderLabel]="global_value?.[col_input?.['input_name']]?.['placeholderLabel']"
                                                                    [valueDefault]="global_value?.[col_input?.['input_name']]?.['value']"
                                                                    [statusDisabled]="global_value?.[col_input?.['input_name']]?.['input_disabled_submit']"
                                                                    [gitLabel]="col_input?.['gitLabel']"
                                                                    [gitRequired]="global_value?.[col_input?.['input_name']]?.['gitRequired']"
                                                                    [param]="global_value?.[col_input?.['input_name']]?.['param']"
                                                                    [size]="col_input?.['size']"
                                                                    (lookupChange)="ngSelectChange($event, col_input?.['lookup'], col_input)">
                                                  </ng-select-global>
                                          </div>
                                            <!-- end ng-select-global -->

                                          <!-- INPUT = 'NUMBER' -->
                                          <div [class] = "col_input?.['input_class_formgroup']"
                                                [ngClass] = "{'required':col_input?.['input_required'] ?? ''}"
                                                [ngStyle] = "{'display':global_value?.[col_input?.['input_name']]?.['input_hide_change'] ? 'none' : 'block'}"
                                                *ngIf = "col_input?.['input_type'] == 'number'"
                                          >
                                              <input type = 'number'
                                                    [id] = "col_input?.['input_id']"
                                                    placeholder=" "
                                                    [class] = "col_input?.['input_class_input']"
                                                    [name] = "col_input?.['input_name']"
                                                    [value]="global_value?.[col_input?.['input_name']]?.['value']"
                                                    (change)="changeProc('change', col_input)"
                                                    (wheel)='wheelProc($event)'
                                                    min="0"
                                                    [maxLength]="col_input?.['input_maxlength']"
                                                    [disabled]='global_value?.[col_input?.["input_name"]]?.["input_disabled_submit"]'
                                                    oninput = "if(this.value.length > this.maxLength) {this.value = '9'.repeat(this.maxLength)}"
                                                    (keypress)="dataTypeServices.keypressValidate($event)"
                                                    #native_Input
                                              >
                                              <label class = 'form-input-label' [for] = "col_input?.['input_id']">{{ col_input?.['input_label'] | translate }}</label>
                                          </div>
                                          <!-- end INPUT = 'NUMBER' -->

                                          <!-- CHECKBOX -->
                                          <div [class] = "col_input?.['input_class_formgroup']"
                                                [ngClass] = "{'required':col_input?.['input_required'] ?? ''}"
                                                [ngStyle] = "{'display':global_value?.[col_input?.['input_name']]?.['input_hide_change'] ? 'none' : 'flex'}"
                                              *ngIf = "col_input?.['input_type'] == 'checkbox'"
                                          >
                                              <label class = 'form-control-label' [for] = "col_input?.['input_id']">{{ col_input?.['input_label'] | translate }}</label><br>
                                              <label class = 'custom-toggle'>
                                                <input type = 'checkbox'
                                                          [name] = "col_input?.['input_name']"
                                                          [class] = "col_input?.['input_class_input']"
                                                          [(ngModel)] = "global_value[col_input?.['input_name'] ?? false]['value']"
                                                          [id] = "col_input?.['input_id']"
                                                          (change)="changeProc('change', col_input)"
                                                          [disabled]='global_value?.[col_input?.["input_name"]]?.["input_disabled_submit"]'
                                                          >
                                                  <span class = 'custom-toggle-slider' data-label-on='Yes' data-label-off = 'No'></span>
                                              </label>
                                          </div>
                                      </div>
                                </div>
                          </div>
                      </div>
                  </div>
              </div>

              <!-- BUTTON SUBMIT / CANCEL -->
              <div class = 'col-12'
                  *ngIf = "global_form?.['section_type'] == 'button'">

                  <div class = 'row'>
                      <div class = 'col text-right'>

                          <button type = 'button' class = 'btn btn-icon btn-3 btn-git-danger' (click) = "backCancel(global_form?.['section_button']?.['cancel']?.['controller'])"
                                  [disabled]="global_button?.['cancel']?.['disabled_submit']"
                                  *ngIf = "global_form?.['section_button']?.['cancel']?.['show']"
                          >
                              <span class = 'btn-inner--icon'><i class="fas fa-times"></i></span>
                              <span class="btn-inner--text">{{ 'Cancel' | translate }}</span>
                          </button>

                          <button type = 'button' class = 'btn btn-icon btn-3 btn-git-success' (click)="submitProc(global_form?.['section_button']?.['submit'])" 
                                  [disabled]="global_button?.['submit']?.['disabled_submit']"
                                  *ngIf = "global_form?.['section_button']?.['submit']?.['show']"
                          >
                              <span class = 'btn-inner--icon'><i class="fas fa-check"></i></span>
                              <span class="btn-inner--text">{{ 'Submit' | translate }}</span>
                          </button>
                      </div>
                  </div>
              </div>

              <div class = 'col-12'
                    *ngIf = "global_form?.['section_type'] == 'ngx-datatable'">

                    <div class = 'row'>
                          <div class = 'col'>
                              <ngx-datatable-form-global
                                  [configTable]="global_value?.[global_form?.['table']?.['id']]?.['configTable']"

                                  [cellKeyId]="global_value?.[global_form?.['table']?.['id']]?.['cellKeyId']"
                                  [tableId]="global_value?.[global_form?.['table']?.['id']]?.['table']?.['id']"
                                  [tableIcon]="global_value?.[global_form?.['table']?.['id']]?.['table']?.['icon']"
                                  [tableName]="global_value?.[global_form?.['table']?.['id']]?.['table']?.['name']"
                                  [headerStatusCode]="global_value?.[global_form?.['table']?.['id']]?.['header']?.['statusCode']"
                                  [headerStatusKey]="global_value?.[global_form?.['table']?.['id']]?.['header']?.['statusKey']"
                                  [selectedActive]="global_value?.[global_form?.['table']?.['id']]?.['selectedActive']"
                                  [modelEffectiveDate]="global_value?.[global_form?.['table']?.['id']]?.['date']?.['modelEffectiveDate']"
                                  [modelEffectiveDateFinal]="global_value?.[global_form?.['table']?.['id']]?.['date']?.['modelEffectiveDateFinal']"
                                  [hideSortFilterTable]="global_value?.[global_form?.['table']?.['id']]?.['hideSortFilterTable']"
                                  [dataParamsExport]="global_value?.[global_form?.['table']?.['id']]?.['dataParamsExport']"
                                  
                                  [exportApiGetData]="global_value?.[global_form?.['table']?.['id']]?.['export']?.['apiGetData']"
                                  [exportProcessName]="global_value?.[global_form?.['table']?.['id']]?.['export']?.['processName']"
                                  [exportNeedAOD]="global_value?.[global_form?.['table']?.['id']]?.['export']?.['needAOD']"
                                  [exportIsDisabled]="global_value?.[global_form?.['table']?.['id']]?.['export']?.['isDisabled']"
              
                                  [dataExists]="global_value?.[global_form?.['table']?.['id']]?.['dataExists']"
                                  [dataInput]="global_value?.[global_form?.['table']?.['id']]?.['dataInput']"
                                  [statusFinishedApi]="global_value?.[global_form?.['table']?.['id']]?.['statusFinishedApi']"
                                  [statusAODParamUrl]="global_value?.[global_form?.['table']?.['id']]?.['statusAODParamUrl']"
              
                                  [showRefreshButton]="global_value?.[global_form?.['table']?.['id']]?.['show']?.['refreshButton']"
                                  [showStatusDropdown]="global_value?.[global_form?.['table']?.['id']]?.['show']?.['statusDropdown']"
                                  [showExportButton]="global_value?.[global_form?.['table']?.['id']]?.['show']?.['exportButton']"
                                  [showCreateButton]="global_value?.[global_form?.['table']?.['id']]?.['show']?.['createButton']"
              
                                  [statusDisabledDropdown]="global_value?.[global_form?.['table']?.['id']]?.['statusDisabledDropdown']"
                                  [avatarDivWidth]="global_value?.[global_form?.['table']?.['id']]?.['avatar']?.['divWidth']"
                                  [avatarFontSize]="global_value?.[global_form?.['table']?.['id']]?.['avatar']?.['fontSize']"
              
                                  (elementGlobal)="elementGlobal_TableFormGlobal($event, global_value?.[global_form?.['table']?.['id']])"
                                >
                              </ngx-datatable-form-global>
                          </div>
                    </div>
              </div>
      </div>


      <!-- MODAL FOR HISTORY -->
      <ng-template #modalHistory>

        <div class=" modal-header">
            <div class = 'w-100' *ngIf="false">
                <div style="width: 100%;" class = 'd-flex justify-content-between'>
                    <h6 class=" modal-title mb-1" id="modal-title-default">
                        {{ 'History' | translate }}
                    </h6>
                    <a class = 'fa fa-times text-lg' style = 'cursor:pointer;z-Index:1'
                        (click)='modalRef.hide()'></a> 
                </div>
                <hr style = 'border:1px solid lightsteelblue' class = 'mt-1'>
            </div>
        </div>

        <div class=" modal-body mt--5">
            <div class = 'row'>
                <div class = 'col-12'>
                    <!-- Tabel History List -->
                    
                    <ngx-datatable-form-global
                        [configTable]="configTable_History" 
                        [cellKeyId]="cellKeyId"
                        [tableId]="tableIdHistory"
                        [tableIcon]="tableIcon"
                        [tableName]="tableName"
                        [dataInput]="dataInputHistory"

                        [headerStatusCode]="0"
                        [headerStatusKey]="'active'"
                        [selectedActive]="selectedActive"
                        [modelEffectiveDate]="modelEffectiveDate"
                        [modelEffectiveDateFinal]="modelEffectiveDateFinal"
                        [hideSortFilterTable]=[0,1,2,3]
                        [dataParamsExport]="dataParamsExport"
                        
                        [exportApiGetData]="''"
                        [exportProcessName]="''"
                        [exportNeedAOD]="true"
                        [exportIsDisabled]="false"

                        [dataExists]="dataExists"
                        [statusFinishedApi]="statusFinishedApi"
                        [statusAODParamUrl]="statusAODParamUrl"

                        [showRefreshButton]="false"
                        [showStatusDropdown]="false"
                        [showExportButton]="false"
                        [showCreateButton]="false"

                        [statusDisabledDropdown]="true"
                        [avatarDivWidth]="'50px'"
                        [avatarFontSize]="'1.5rem'"

                        (elementGlobal)="elementGlobal_History($event)"
                        >
                    </ngx-datatable-form-global>
                </div>  
            </div>
        </div>
    </ng-template>
    
    <ng-http-loader-global></ng-http-loader-global>

</form>

