import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MethodServices } from 'src/services/method-services'; //utama
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'

import { HttpLoaderImageComponent } from 'src/app/global/http-loader-image/http-loader-image.component'

@Component({
    selector: 'payment-register',
    templateUrl: './payment-register.component.html'
})
export class PaymentRegisterComponent implements OnInit {

    midtransScriptUrl = environment.urlSnapMidtrans
    myMidtransClientKey = environment.clientKeyMidtrans

    @ViewChild('nativeCompanyName') nativeCompanyName:ElementRef
    @ViewChild('nativeContactPerson') nativeContactPerson:ElementRef
    @ViewChild('nativeEmail') nativeEmail:ElementRef
    @ViewChild('nativePhoneNumber') nativePhoneNumber:ElementRef
    @ViewChild('nativeJob') nativeJob:ElementRef


    public httpLoaderImage = HttpLoaderImageComponent

    totalEmployees:number = 30
    subscriptionPeriods:number = 1
    validEmail:boolean = true;
    readOnlyInput:boolean = false;
    validToPay:boolean = false;

    constructor(private methodServices: MethodServices,
                private http:HttpClient) {}

    ngOnInit() {
        let scriptTag = document.createElement('script');
        scriptTag.src = this.midtransScriptUrl;
        scriptTag.setAttribute('data-client-key', this.myMidtransClientKey);

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }

    addQty(attr) {
        if (this.readOnlyInput)
            return

        switch (attr) {
            case 'totalEmployees':
                this.totalEmployees = this.totalEmployees + 30;
                break;
            case 'subscriptionPeriods':
                this.subscriptionPeriods = this.subscriptionPeriods >= 3 ? 3 : this.subscriptionPeriods + 1;
                break;
        }
    }
    minusQty(attr) {
        if (this.readOnlyInput)
            return

        switch (attr) {
            case 'totalEmployees':
                this.totalEmployees = this.totalEmployees > 30 ? this.totalEmployees - 30 : 30
                break;
            case 'subscriptionPeriods':
                this.subscriptionPeriods = this.subscriptionPeriods > 1 ? this.subscriptionPeriods - 1 : 1;
                break;
        }
    }

    cekEmail(){
        if (this.nativeEmail.nativeElement.value.trim() == ''){
            this.validEmail = true
        }else{
            if (this.methodServices.emailValid(this.nativeEmail.nativeElement.value.trim())==null){
                this.validEmail = false
                this.methodServices.showToast('Invalid format Email !','error')
            }else{
                this.validEmail = true
            }
        }
    }

    paymentNow() {
        let contactPersonFinal = this.nativeContactPerson.nativeElement.value.trim()
        let companyNameFinal = this.nativeCompanyName.nativeElement.value.trim()
        let phoneNumberFinal = this.nativePhoneNumber.nativeElement.value.toString().trim()
        let jobFinal = this.nativeJob.nativeElement.value.trim()
        let emailFinal = this.nativeEmail.nativeElement.value.trim()

        if (contactPersonFinal == '' || contactPersonFinal==null) {
            this.methodServices.showToast('Contact Person can\'t be blank !', 'error');
            return
        } else if (companyNameFinal == '' || companyNameFinal==null) {
            this.methodServices.showToast('Company Name can\'t be blank !', 'error');
            return
        } else if (jobFinal == '' || jobFinal==null) {
            this.methodServices.showToast('Job can\'t be blank !', 'error');
            return
        } else if (phoneNumberFinal == '' || phoneNumberFinal==null) {
            this.methodServices.showToast('Phone Number can\'t be blank !', 'error');
            return
        } else if (emailFinal == '' || emailFinal==null) {
            this.methodServices.showToast('Email can\'t be blank !', 'error');
            return
        } else if (this.totalEmployees <= 0) {
            this.methodServices.showToast('Total Employees must more than 0 !', 'error');
            return
        } else if (this.subscriptionPeriods <= 0) {
            this.methodServices.showToast('Subscripttion Periods must more than 0 !', 'error');
            return
        }

        let obj = {}
        obj = {
            'contactPerson':contactPersonFinal,
            'companyName':companyNameFinal,
            'phoneNo':phoneNumberFinal,
            'email':emailFinal,
            'job':jobFinal,
            'totalLicense':this.totalEmployees,
            'period':this.subscriptionPeriods
        }

        this.http.post(environment.urlApiMaster + '/api/subscription/register',obj)
            .subscribe((res)=>{
                // @ts-ignore
                window.snap.pay(res.token)

                // },10300)
            },(err)=>{
                this.readOnlyInput = false
                if (typeof(err.error.message) != 'undefined' && err.error.message != '' &&
                    err.error.message != null){
                    this.methodServices.showToast(err.error.message,'error')
                }
                else{
                    this.methodServices.showToast(err.error.error,'error')
                }
            })
    }
    wheelProc(event){
        event.preventDefault()
    }
}
