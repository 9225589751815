import {Component, OnInit, ViewChild} from '@angular/core';
import {MethodServices} from '../../../../services/method-services';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'finishpayment',
    templateUrl: './finishpayment.component.html'
    ,
})
export class FinishpaymentComponent implements OnInit {

    @ViewChild('modalCheckStatus',{static:false}) modalCheckStatus:ModalDirective;

    midtransScriptUrl = environment.urlSnapMidtrans
    myMidtransClientKey = environment.clientKeyMidtrans
    orderIdMidtrans:any = null;
    redColorBackgroundModal = '#FFC7C9'
    redColorPrimaryText = '#FF6167'
    blueColorPrimaryText = '#00bcf1'
    blueColorBackgroundModal = '#ccf2fc'
    statusPayment:any


    constructor(private methodServices: MethodServices,private http:HttpClient,
                private activatedRoute:ActivatedRoute) {}

    ngOnInit() {
        // alert(window.location.href)
        this.activatedRoute.queryParams.subscribe(result => {
            this.orderIdMidtrans = result.order_id
        })
        let scriptTag = document.createElement('script');
        scriptTag.src = this.midtransScriptUrl;
        scriptTag.setAttribute('data-client-key', this.myMidtransClientKey);

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }

    payNow() {
        this.http.get(environment.urlApiMaster + '/api/subscription/status?orderid='+this.orderIdMidtrans)
            .subscribe((res:any)=>{
                if (typeof res == 'object') {
                    //@ts-ignore
                    // window.snap.pay('f0baf09c-092e-4cd3-a80f-a99a901eb67e')
                    // window.snap.pay('6b0104b3-d6c4-45f5-a1a2-370c210c991d')
                    this.statusPayment = res.transactionStatus
                    if (res.transactionStatus == 'settlement' || res.transactionStatus == 'capture') {
                        this.modalCheckStatus.show()
                    } else if (res.transactionStatus == 'pending') {
                        if (res.token!=null) {
                            // @ts-ignore
                            window.snap.pay(res.token)
                        }
                    } else if (res.transactionStatus == 'expired') {
                        this.modalCheckStatus.show()
                    }
                }
            },(err)=>{
                if (typeof(err.error.message) != 'undefined' && err.error.message != '' &&
                    err.error.message != null){
                    this.methodServices.showToast(err.error.message,'error')
                }
                else{
                    this.methodServices.showToast(err.error.error,'error')
                }
            })
    }
}
