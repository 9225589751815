<div class = 'form-group' [ngClass]="{'required':gitRequired}">
    <label class = 'custom-calendar w-100'>
        <input type="text"
               [ngClass]="modelDatepicker == 'Invalid Date' ? 'invalidError datepicker-field' : 'datepicker-field'"
               class="{{gitClass}}"
               bsDatepicker
               placeholder = " "
               [bsValue]='modelDatepicker'
               [value]='modelDatepicker | date:gitFormatDate'
               autocomplete="off"
               autocapitalize="off"
               spellcheck="false"
               [minDate]="minDate"
               [maxDate]="maxDate"
               [bsConfig]="{ dateInputFormat: gitFormatDate.toUpperCase(), showTodayButton:true, adaptivePosition: true}"
               (bsValueChange)='changeProcDate($event)'
               (onShown)='onOpenCalendar($event)'
               [disabled]="isDisabled">
        <label class = 'form-input-label'>{{gitLabel | translate }}</label>
    </label>
</div>
