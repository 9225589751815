<style>

    /* HOVER PADA CELL */
    /* .ngx-datatable.material.single-selection .datatable-body-row:not(.active):hover, .ngx-datatable.material.single-selection .datatable-body-row:not(.active):hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row:not(.active):hover, .ngx-datatable.material.multi-selection .datatable-body-row:not(.active):hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row:not(.active):hover, .ngx-datatable.material.multi-click-selection .datatable-body-row:not(.active):hover .datatable-row-group{
        background-color:red !important;
    } */

    .btnHover:hover {
        box-shadow: 1px 1px 5px 5px lightcyan;
    }

    .btnDisabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

    .datatable-header-cell-template-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .datatable-header-cell-template-wrap .sort-btn {
        align-self: center;
    }

    .datatable-header-cell-template-wrap .reportfilterbackground {
        order: 10;
    }

    .form-search-style {
        border: 1px solid #C6C6C6;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .badge-secondary {
        background-color: #F7FAFC !important;
        color: rgb(128, 128, 128) !important;
    }
</style>

<div class="dataTables_wrapper pt-4" style='border-radius:10px'>
    <div class='row'>
        <div class='container-table-header'>
            <!-- X button -->
            <div class="align-self-center close-right position-absolute" style="top: 2px; right:20px" *ngIf="configTable?.['modal']?.['showCloseButton'] ?? false">
              <a class="fa fa-times" style="font-size:25px; cursor:pointer; z-index:3;"
                (click)="closeButton()"> </a>
            </div>
            <div class='table-information'>
                  <h2 *ngIf="configTable?.['header']?.['show']?.['title'] ?? true" class="table-title ml-1"> {{ tableName | translate }} </h2>
                  <div class='btn-group dropdown btn-filter-status' style='z-Index:11' *ngIf="showStatusDropdown" dropdown>
                      <button type="button" class='btn btn-secondary dropdown-toggle btn-sm pl-1 py-0 dropdown-table-active'
                              data-toggle='dropdown' dropdownToggle *ngIf="!statusDisabledDropdown"
                              aria-controls="dropdown-animated"
                              [disabled]="statusDisabledDropdown"
                              [ngStyle]="{'background-color':methodServices.statusDark ? '#242526' : 'transparent',
                                        'border-color':'transparent',
                                        'color':methodServices.statusDark ? '#E4E6EB' : 'black'}">
                          <span style='font-size:16px'>{{ selectedActive | translate}}</span>
                      </button>
                      <button type="button" class='btn btn-secondary dropdown-toggle btn-sm pl-1 py-0 dropdown-table-active'
                              data-toggle='dropdown' *ngIf="statusDisabledDropdown"
                              aria-controls="dropdown-animated"
                              [disabled]="statusDisabledDropdown"
                              [ngStyle]="{'background-color':methodServices.statusDark ? '#242526' : 'transparent',
                                        'border-color':'transparent',
                                        'color':methodServices.statusDark ? '#E4E6EB' : 'black'}">
                          <span style='font-size:16px'>{{ selectedActive | translate}}</span>
                      </button>
                      <div id="dropdown-animated" *dropdownMenu class='dropdown-menu'>
                          <a class='dropdown-item' style='cursor:pointer'
                              *ngFor="let headerStatusList of headerStatusList; let idx = index"
                              (click)="selectedActiveProc(headerStatusList)">{{ headerStatusList['showText'] | translate }}</a>
                      </div>
                  </div>
                  <label class="table-showing-data ml-1">
                      <span>{{ 'Show' | translate }}</span>
                      <select
                              name="datatable_length"
                              aria-controls="datatable"
                              class="form-control form-control-sm select-show-data"
                              (change)="entriesChange($event)">
                          <option value="5" [selected]="entries==5" *ngIf="configTable?.['tipe']=='checkbox'">5</option>
                          <option value="10" [selected]="entries==10">10</option>
                          <option value="25" [selected]="entries==25">25</option>
                          <option value="50" [selected]="entries==50">50</option>
                          <option value="100" [selected]="entries==100">100</option>
                          <option value="500" [selected]="entries==500">500</option>
                          <option value="1000" [selected]="entries==1000">1000</option>
                          <!-- <option value="-1" [selected]="entries==-1">{{ 'All' | translate }}</option> -->
                      </select>
                      <span>{{ 'entries' | translate }}</span>
                  </label>
            </div>
            <div class="table-action" [ngClass]="configTable?.['modal']?.['showCloseButton'] ? 'mt-4' : ''">
                <div class="ml-2">
                  <button type="button" (click)='elementClassLoad()' class="btn btn-icon"
                          style="z-index: 2;"
                          *ngIf="showRefreshButton"
                          [disabled]="configTable?.['isDisabled']?.['refresh']">
                      <span title='Refresh' class='fas fa-sync-alt' style='color:white'></span>
                  </button>

                  <git-btn-export [isDisabled]="configTable?.['isDisabled']?.['export']"
                                  [dataTable]="rows_excel"
                                  [configDatatable]="dataInput_excel"
                                  *ngIf="showExportButton"
                  ></git-btn-export>

                  <button type='button' class='btn btn-icon'
                          (click)='createElement()'
                          style='z-index:2'
                          *ngIf="showCreateButton"
                          [disabled]="configTable?.['isDisabled']?.['create']">
                      <span class='btn-inner--icon'><i class="fas fa-plus-circle"></i></span>
                      <span class="btn-inner--text">{{ 'Add' | translate }}</span>
                  </button>

                  <button type="button" (click)="viewTemplate()" class="btn btn-icon"
                          style="z-index: 2"
                          *ngIf="showViewTemplate">
                      <span title="View Template" class="fas fa-list" style="color: white"></span>
                  </button>
                </div>
            </div>
        </div>

        <div class="col-12" *ngIf="false">
            <hr class='mt-1' style='border:1px solid lightsteelblue'
                *ngIf="configTable?.['header']?.['show']?.['line'] ?? true"
            >
        </div>
    </div>

    <div class="row mt--3" *ngIf="false"
         [ngClass]="{'mt--3':(configTable?.['header']?.['show']?.['line'] ?? true), 'mt-3':!(configTable?.['header']?.['show']?.['line'] ?? true)}"
    >
        <div class="col-12 ml-auto">
            <div style="width:100%; margin-top:20px; margin-bottom:0" *ngIf="progress_show">
                <progressbar [animate]="true" [striped]="true" [value]="progressValue" [max]="progress_max"
                             type="success"></progressbar>
            </div>

            <div id="datatable_filter" class="dataTables_filter"
                 *ngIf="headerStatusRangePicker && rangePicker_show">
                <label>
                    <!-- DATE RANGE PICKER -->
                    <div class="input-group" style='width:105%;'>
                        <div class="input-group-prepend" style="height:31px;">
                            <div class="input-group-text"
                                 style="border: 1px solid #ABABAB;box-sizing: border-box;border-top-left-radius: 10px; border-bottom-left-radius: 10px;"
                                 [ngStyle]="{'background-color':methodServices.statusDark ? 'rgb(36, 37, 38)' : 'white'}">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">

                                    <!-- KONDISI DARK (WARNA PUTIH)-->
                                    <path d="M11.9 1.5H10.5V0.75C10.5 0.551088 10.4263 0.360322 10.295 0.21967C10.1637 0.0790176 9.98565 0 9.8 0C9.61435 0 9.4363 0.0790176 9.30503 0.21967C9.17375 0.360322 9.1 0.551088 9.1 0.75V1.5H4.9V0.75C4.9 0.551088 4.82625 0.360322 4.69497 0.21967C4.5637 0.0790176 4.38565 0 4.2 0C4.01435 0 3.8363 0.0790176 3.70503 0.21967C3.57375 0.360322 3.5 0.551088 3.5 0.75V1.5H2.1C1.54305 1.5 1.0089 1.73705 0.615076 2.15901C0.221249 2.58097 0 3.15326 0 3.75V12.75C0 13.3467 0.221249 13.919 0.615076 14.341C1.0089 14.7629 1.54305 15 2.1 15H11.9C12.457 15 12.9911 14.7629 13.3849 14.341C13.7788 13.919 14 13.3467 14 12.75V3.75C14 3.15326 13.7788 2.58097 13.3849 2.15901C12.9911 1.73705 12.457 1.5 11.9 1.5ZM12.6 12.75C12.6 12.9489 12.5263 13.1397 12.395 13.2803C12.2637 13.421 12.0857 13.5 11.9 13.5H2.1C1.91435 13.5 1.7363 13.421 1.60503 13.2803C1.47375 13.1397 1.4 12.9489 1.4 12.75V7.5H12.6V12.75ZM12.6 6H1.4V3.75C1.4 3.55109 1.47375 3.36032 1.60503 3.21967C1.7363 3.07902 1.91435 3 2.1 3H3.5V3.75C3.5 3.94891 3.57375 4.13968 3.70503 4.28033C3.8363 4.42098 4.01435 4.5 4.2 4.5C4.38565 4.5 4.5637 4.42098 4.69497 4.28033C4.82625 4.13968 4.9 3.94891 4.9 3.75V3H9.1V3.75C9.1 3.94891 9.17375 4.13968 9.30503 4.28033C9.4363 4.42098 9.61435 4.5 9.8 4.5C9.98565 4.5 10.1637 4.42098 10.295 4.28033C10.4263 4.13968 10.5 3.94891 10.5 3.75V3H11.9C12.0857 3 12.2637 3.07902 12.395 3.21967C12.5263 3.36032 12.6 3.55109 12.6 3.75V6Z"
                                          fill="white"
                                          *ngIf="methodServices.statusDark"/>

                                    <!-- KONDISI DARK (WARNA BIRU)-->
                                    <path d="M11.9 1.5H10.5V0.75C10.5 0.551088 10.4263 0.360322 10.295 0.21967C10.1637 0.0790176 9.98565 0 9.8 0C9.61435 0 9.4363 0.0790176 9.30503 0.21967C9.17375 0.360322 9.1 0.551088 9.1 0.75V1.5H4.9V0.75C4.9 0.551088 4.82625 0.360322 4.69497 0.21967C4.5637 0.0790176 4.38565 0 4.2 0C4.01435 0 3.8363 0.0790176 3.70503 0.21967C3.57375 0.360322 3.5 0.551088 3.5 0.75V1.5H2.1C1.54305 1.5 1.0089 1.73705 0.615076 2.15901C0.221249 2.58097 0 3.15326 0 3.75V12.75C0 13.3467 0.221249 13.919 0.615076 14.341C1.0089 14.7629 1.54305 15 2.1 15H11.9C12.457 15 12.9911 14.7629 13.3849 14.341C13.7788 13.919 14 13.3467 14 12.75V3.75C14 3.15326 13.7788 2.58097 13.3849 2.15901C12.9911 1.73705 12.457 1.5 11.9 1.5ZM12.6 12.75C12.6 12.9489 12.5263 13.1397 12.395 13.2803C12.2637 13.421 12.0857 13.5 11.9 13.5H2.1C1.91435 13.5 1.7363 13.421 1.60503 13.2803C1.47375 13.1397 1.4 12.9489 1.4 12.75V7.5H12.6V12.75ZM12.6 6H1.4V3.75C1.4 3.55109 1.47375 3.36032 1.60503 3.21967C1.7363 3.07902 1.91435 3 2.1 3H3.5V3.75C3.5 3.94891 3.57375 4.13968 3.70503 4.28033C3.8363 4.42098 4.01435 4.5 4.2 4.5C4.38565 4.5 4.5637 4.42098 4.69497 4.28033C4.82625 4.13968 4.9 3.94891 4.9 3.75V3H9.1V3.75C9.1 3.94891 9.17375 4.13968 9.30503 4.28033C9.4363 4.42098 9.61435 4.5 9.8 4.5C9.98565 4.5 10.1637 4.42098 10.295 4.28033C10.4263 4.13968 10.5 3.94891 10.5 3.75V3H11.9C12.0857 3 12.2637 3.07902 12.395 3.21967C12.5263 3.36032 12.6 3.55109 12.6 3.75V6Z"
                                          fill="#3E8BFF"
                                          *ngIf="!methodServices.statusDark"/>

                                </svg>
                            </div>
                        </div>
                        <input class="form-control form-control-sm" #drp="bsDaterangepicker"
                               bsDaterangepicker
                               [bsValue]='bsRangeValue'
                               style='height:31px;border: 1px solid #ABABAB;box-sizing: border-box;border-top-right-radius: 10px; border-bottom-right-radius: 10px;
                                      background: var(--git-field-label-bg) !important; color: var(--git-field-label) !important;'
                               (bsValueChange)="changeDateFilter($event)"
                               [bsConfig]="{ rangeInputFormat : 'DD-MMM-YYYY', dateInputFormat: 'DD-MMM-YYYY'
                          ,adaptivePosition: true, showPreviousMonth:true, maxDateRange: headerRangePickerMaxDate }"
                               [(ngModel)]="bsRangeValue" readonly>

                    </div>
                    <!-- <input
                            type="search"
                            class="form-control form-control-sm"
                            placeholder="Search records"
                            aria-controls="datatable"
                            (keyup)="filterTable($event)"
                    /> -->
                </label>
            </div>
        </div>
    </div>

    <!-- UNTUK TESTING CHANGE STYLE DATATABLE -->
    <!-- <button class = "btn btn-outline-primary" (click)="tesClick()">Click</button> -->
    <!-- <div class = "report" style = "color:blue" >Testing</div> -->

    <ngx-datatable #dataTable
                   *ngIf="showTable"
                   id="print-section"
                   class="material dark table-form mt-2"
                   [ngClass]="[tableId]"
                   [columnMode]="'force'"
                   [headerHeight]="'auto'"
                   [footerHeight]="'60'"
                   [rowHeight]="'auto'"
                   [limit]="entries != -1 ? entries:undefined"
                   [rows]="temp"
                   [selected]="selectedListCheckbox"
                   [selectionType]="SelectionType"
                   [scrollbarH]="true"
                   (activate)="onActivate($event)"
                   (sort)="onSort($event)"
                   (resize)="onResize($event)"
                   (select)="onSelectList($event)"
                   [summaryRow]="true"
                   [summaryPosition]="'top'"
                   [summaryHeight]="'auto'"
                   style='cursor:pointer;-webkit-user-select: text; user-select: text;
                            border-radius: 0 0 10px 10px; box-shadow:none'
                   [ngStyle]="{'background-color':methodServices.statusDark ? '#242526' : 'transparent'}"
    >

        <!-- [scrollbarH] = 'true' -->
        <!-- (sort)="onSort($event)" -->
        <!-- (select)="onSelect($event)" -->
        <!-- selectionType = 'multiClick, cell, single' -->
        <!-- [selected]="selected" -->

        <!-- KHUSUS UNTUK CHECKBOX -->
        <ngx-datatable-column
                *ngIf="configTable?.['tipe'] == 'checkbox'"
                [width]="50"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [sortable]="false"
                [checkboxable]="false">

            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                         let-selectFn="selectFn">

                <label class="datatable-checkbox" *ngIf="configTable?.['checkbox']?.['showAllHeader']">
                    <input type="checkbox" class="ngx-datatable-checkbox" [checked]="allRowsSelected"
                           (change)="selectFn(!allRowsSelected)"
                           [disabled]="progress_show"
                    />
                    <span class="checkmark header-checkmark"></span>
                </label>

            </ng-template>

            <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                         let-onCheckboxChangeFn="onCheckboxChangeFn">
                <label class="datatable-checkbox">

                    <input type="checkbox" class="ngx-datatable-checkbox" [checked]="isSelected"
                           (change)="onCheckboxChangeFn($event)"
                           *ngIf="dataExists"
                           [disabled]="progress_show"
                    />

                    <input type="checkbox" class="ngx-datatable-checkbox" [checked]="isSelected"
                           (change)="onCheckboxChangeFn($event)"
                           style="display:none"
                           [disabled]="progress_show"
                           *ngIf="!dataExists"
                    />
                    <span class="checkmark"></span>
                </label>
            </ng-template>

        </ngx-datatable-column>

        <ngx-datatable-column
                *ngFor="let dtInput of dataInput; let i = index"
                [name]="dataInput[dtInput['idx']]['name']"
                [width]="dataInput[dtInput['idx']]['width']"
                [minWidth]="dataInput[dtInput['idx']]['minWidth']"
                [maxWidth]="dataInput[dtInput['idx']]['maxWidth']"
                [cellClass]="dataInput[dtInput['idx']]['cellClass']"
                [headerClass]="dataInput[dtInput['idx']]['headerClass']"
                [sortable]="dataInput[dtInput['idx']]?.['sortable'] ?? true"
                [summaryTemplate]="dataInput[dtInput['idx']]['statusSummaryTemplate'] ? template1 : null"
        >
            <ng-template #template1>
                  
                  <span *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'nourut'">
                      #
                  </span>

                <!-- default -->
                <input type="text"
                       [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                       [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                       class='form-control form-control-sm form-search-style'
                       (keyup)="keyUp_Proc($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                       (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                       (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                       *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'text'"
                       #nativeFilter1
                />
                <!-- (keyup)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])" -->

                <!-- dropdown -->

                <filter-dropdown-global
                        (selectedValueOutput)="selectedValueOutput($event, dataInput[dtInput['idx']]['nameParamUrl'])"
                        [widthList]="dataInput[dtInput['idx']]['width']"
                        [arrFilterHeader]="arrFilterHeader"

                        [nameParamUrl]="dataInput[dtInput['idx']]['nameParamUrl']"
                        [dataInput]="dataInput"
                        [tableId]="tableId"

                        [dropdownCustomList]="dataInput[dtInput['idx']]['dropdownCustomList']"
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'dropdown'">
                </filter-dropdown-global>

                <!-- <select class = "form-control form-control-sm"
                    *ngIf = "dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'dropdown'"
                    style = "border-radius:10px;border:1px solid #C6C6C6">
                    <option value="true" style="height:100px"></option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                    <option value="false">False</option>
                    <option value="false">False</option>
                    <option value="false">False</option>

                </select> -->

                <!-- date -->
                <input
                        [inputMask]="dateInputMask"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="Date"
                        placeholder="dd-mm-yyyy"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        class='form-control form-control-sm form-search-style'
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'date'"
                        #nativeFilter1
                />

                <!-- date -->
                <input
                        [inputMask]="dateMonYearInputMask"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="Date"
                        placeholder="mm-yyyy"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        class='form-control form-control-sm form-search-style'
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'date-mon-year'"
                        #nativeFilter1
                />

                <!-- time -->
                <input
                        [inputMask]="timeInputMask"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="Date"
                        placeholder="hh:mm"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        class='form-control form-control-sm form-search-style'
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'time'"
                        #nativeFilter1
                />

                <!-- currency -->
                <input
                        [inputMask]="currencyInputMask"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="0.00"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keyUp_Proc($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        class='form-control form-control-sm form-search-style'
                        maxlength="14"
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'currency'"
                        #nativeFilter1
                />

                <!-- currency_no_decimal (work-structure -> location table) -->
                <input
                        [inputMask]="currencyNoDecInputMask"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="_"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keyUp_Proc($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        class='form-control form-control-sm form-search-style'
                        maxlength="14"
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'currency_no_decimal'"
                        #nativeFilter1
                />

                <!-- number -->
                <input
                        [inputMask]="numberInputMask_Temp"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="_"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keyUp_Proc($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (focus)="focusProc($event)"
                        (blur)="blurProc($event)"
                        (mouseenter)="mouseEnterProc($event)"
                        class='form-control form-control-sm form-search-style'
                        maxlength="14"
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'number'"
                        #nativeFilter1
                />
                <!-- && positionCurrentId == dataInput[dtInput['idx']]['idNgTemplate'] -->

                <!-- <input
                    [inputMask]="numberInputMask"
                    [name] = "dataInput[dtInput['idx']]['nameNgTemplate']"
                    [id] = "dataInput[dtInput['idx']]['idNgTemplate']"
                    placeholder="0"
                    (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                    (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                    (focus)="focusProc($event)"
                    (blur)="blurProc($event)"
                    (mouseenter)="mouseEnterProc($event)"
                    class = 'form-control form-control-sm form-search-style'
                    maxlength="14"
                    *ngIf = "dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'number' &&
                            positionCurrentId != dataInput[dtInput['idx']]['idNgTemplate']"
                    #nativeFilter1
                    /> -->

                <!-- number_decimal (employee -> education) -->
                <input
                        [inputMask]="numberInputMaskDecimal_Temp"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="_.__"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keyUp_Proc($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (focus)="focusProc($event)"
                        (blur)="blurProc($event)"
                        (mouseenter)="mouseEnterProc($event)"
                        class='form-control form-control-sm form-search-style'
                        maxlength="14"
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'number_decimal'"
                        #nativeFilter1
                />

                <!-- number_decimal_dynamic (for decimal that not 2 decimal (example 5 decimal in payroll > tax bracket)) -->
                <!-- Notes: Need to add the option and placeholder from front menu -->
                <input
                        [inputMask]="dataInput[dtInput['idx']]['decimalSummaryTemp_Option']"
                        [name]="dataInput[dtInput['idx']]['nameNgTemplate']"
                        [id]="dataInput[dtInput['idx']]['idNgTemplate']"
                        placeholder="{{dataInput[dtInput['idx']]['decimalSummaryTemp_Placeholder']}}"
                        (keydown.enter)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (keyup)="keyUp_Proc($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (change)="keypressFilter($event,dataInput[dtInput['idx']]['nameParamUrl'],dataInput[dtInput['idx']]['typeSummaryTemplate'])"
                        (focus)="focusProc($event)"
                        (blur)="blurProc($event)"
                        (mouseenter)="mouseEnterProc($event)"
                        class='form-control form-control-sm form-search-style'
                        maxlength="14"
                        *ngIf="dataInput[dtInput['idx']]['typeSummaryTemplate'] == 'number_decimal_dynamic'"
                        #nativeFilter1
                />
            </ng-template>

            <ng-template ngx-datatable-header-template>
                <span class='ml--2 reportfilterbackground'>{{ dataInput[dtInput['idx']]['label'] | translate }}</span>
            </ng-template>

            <ng-template ngx-datatable-cell-template let-row='temp' let-value='temp' let-rowIndex='rowIndex'>

                <!-- default -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'default'">
                      <span class="blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusFormProc'] && dataInput[dtInput['idx']]['statusCellDataExists']"
                            (click)='formProc(rowIndex)'><u>{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}</u> </span>

                    <!-- bedanya checkbox maka text overflow di beri ellipsis, sebaliknya jika bukan checkbox maka hanya normal saja tanpa overflow -->
                    <span class="blockText"
                          *ngIf="dataExists && !dataInput[dtInput['idx']]['statusFormProc'] && dataInput[dtInput['idx']]['statusCellDataExists']
                                      && configTable?.['tipe'] != 'checkbox'"
                    >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}</span>

                    <span class="blockText custom-text-ellipsis"
                          *ngIf="dataExists && !dataInput[dtInput['idx']]['statusFormProc'] && dataInput[dtInput['idx']]['statusCellDataExists']
                                      && configTable?.['tipe'] == 'checkbox'"
                    >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}</span>
                    <!-- ... end -->
                </div>
              
                <!-- inner HTML -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'innerHTML'">
                    <span class="blockText"
                          *ngIf="dataExists && !dataInput[dtInput['idx']]['statusFormProc'] && dataInput[dtInput['idx']]['statusCellDataExists']"
                          [innerHTML]="temp[rowIndex][dataInput[dtInput['idx']]['name']]"
                    ></span>
                </div>

                <!-- nourut -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'nourut'">
                    <span class="blockText"
                          *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists'] && !needHitAPI">{{ rowIndex + 1 }} </span>
                    <span class="blockText"
                          *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists'] && needHitAPI">{{ ((currentPage - 1) * entries) + (rowIndex + 1) }} </span>
                    <span class="blockText"
                          *ngIf="!dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"> </span>
                </div>

                <!-- badge_status_approved -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_approved'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                                    {
                                      'badge-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Approved',
                                      'badge-primary': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress', 
                                      'badge-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Canceled',
                                      'badge-warning': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Rejected'
                                    }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <!-- badge_status_inprogress_completed (Home -> Task) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_inprogress_completed'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                              {
                                  'badge-custom-inprogress': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress',
                                  'badge-custom-completed': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Completed'
                              }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'status_success_or_failed'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                              {
                                  'text-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Success',
                                  'text-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] != 'Success'
                              }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <!-- badge_status_inprogress_completed_error (payroll -> closing) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_inprogress_completed_error'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                              {
                                  'badge-primary': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress',
                                  'badge-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Completed',
                                  'badge-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Error'
                              }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <!-- badge_status_new_inprogress_completed_error (customer -> batch update) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_new_inprogress_completed_error'">
                    <span class="badge badge-pill blockText"
                          *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                          [ngClass]="
                            {
                                'badge-warning': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'New',
                                'badge-info': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress',
                                'badge-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Completed',
                                'badge-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Error'
                            }"
                    >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                    </span>
                </div>

                <!-- badge_status_inprogress_completed_error_rollback (payroll -> costing) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_inprogress_completed_error_rollback'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                              {
                                  'badge-primary': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress',
                                  'badge-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Completed',
                                  'badge-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Error',
                                  'badge-secondary': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Rollback'
                              }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <!-- badge_status_inprogress_error (tools -> process-manager) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_inprogress_error'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                          {
                              'badge-primary': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress',
                              'badge-warning': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Cancelled', 
                              'badge-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Completed',
                              'badge-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Error'
                          }"
                      >{{  temp[rowIndex][dataInput[dtInput['idx']]['name']] }} 
                      </span>
                </div>

                <!-- badge_per_column e.g: (Recruitment -> Applicant Selection) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_per_column'">
                      <span class="badge badge-pill blockText" style="font-size:14px;"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                                    {
                                      'badge-success': dataInput[dtInput['idx']]['label'] == 'Hired',
                                      'badge-primary': dataInput[dtInput['idx']]['label'] == 'Applied',
                                      'badge-danger': dataInput[dtInput['idx']]['label'] == 'Rejected',
                                      'badge-info': dataInput[dtInput['idx']]['label'] == 'In Process',
                                      'badge-warning': dataInput[dtInput['idx']]['label'] == 'New'
                                    }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <!-- badge_per_status_canidate e.g:(Recruitment -> Applicant Selection Form) -->

                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_per_status_canidate'">
                      <span class="badge badge-pill blockText"
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                            [ngClass]="
                                    {
                                     'badge-primary': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'New Candidate',
                                     'badge-warning': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'In Progress',
                                     'badge-success': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Hired',
                                     'badge-danger': temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Rejected'
                                    }"
                      >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                      </span>
                </div>

                <!-- badge_status_new_inprogress_completed_passed_failed (employee > career assessment) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'badge_status_new_inprogress_completed_passed_failed'">
                    <span class="badge badge-pill blockText"
                          *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                          [ngStyle]="{
                                'background':temp[rowIndex][dataInput[dtInput['idx']]['name']]=='New' ? '#70D5FA' : 
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='In Progress'? '#FFD19A' :
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='Completed'? '#D1D1D1' :
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='Passed'? '#B0EED3' :
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='Failed'? '#F5A7A7' : 'transparent' ,
                                'color':temp[rowIndex][dataInput[dtInput['idx']]['name']]=='New' ? '#0075A0' : 
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='In Progress'? '#FF6B00' :
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='Completed'? '#888888' :
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='Passed'? '#1AAE6F' :
                                            temp[rowIndex][dataInput[dtInput['idx']]['name']]=='Failed'? '#FF0000' : '#000000'
                            }"
                    >{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                    </span>
                </div>

                <!-- currency_label (starfeeds => feeds) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'currency_label'">
                    <div class="blockText d-flex"
                         *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']">
                        <div style="min-width:45px"
                             *ngIf="temp[rowIndex][dataInput[dtInput['idx']]['name']]!='' && temp[rowIndex][dataInput[dtInput['idx']]['name']]!=null">
                            {{ temp[rowIndex]['currencyCode'] }}</div>
                        <div>{{  temp[rowIndex][dataInput[dtInput['idx']]['name']] }}</div>
                    </div>
                </div>

                <!-- increase_decrease (tools -> process-manager) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'increase_decrease'" class="row pr-3">
                    <div *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']"
                         class="col-9 box-orient-ellipsis"
                    >{{  temp[rowIndex][dataInput[dtInput['idx']]['name']] }}
                    </div>
                    <div class="col-1" style="text-align: left;"
                         *ngIf="temp[rowIndex][dataInput[dtInput['idx']]['name']]!=null && temp[rowIndex][dataInput[dtInput['idx']]['name']]!=''">
                        <span class="fas fa-caret-up mr--2" style="font-size: 1.5rem; color: #00E26B !important"
                              *ngIf="temp[rowIndex][dataInput[dtInput['idx']+1]['nameParamUrl']]>temp[rowIndex][dataInput[dtInput['idx']-1]['nameParamUrl']]"></span>
                        <span class="fas fa-caret-down mr--2" style="font-size: 1.5rem; color: #E20000 !important"
                              *ngIf="temp[rowIndex][dataInput[dtInput['idx']-1]['nameParamUrl']]>temp[rowIndex][dataInput[dtInput['idx']+1]['nameParamUrl']]"></span>
                    </div>
                </div>

                <!-- show_hierarchy (employee)-->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'show_hierarchy'">
                      <span title='View Hierarchy' class='btn-sm fa fa-sitemap text-primary btnHover'
                            style='color:white'
                            (click)='showHierarchy(rowIndex)'
                            *ngIf="dataExists && dataInput[dtInput['idx']]['statusCellDataExists']">
                          <!-- (click) = 'editShow(rowIndex,modalDefault) -->
                      </span>
                </div>

                <!-- edit_show (employee)-->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'edit_show'">
                      <span title='Edit' class='btn-sm fa fa-edit text-primary btnHover' style='color:white'
                            (click)='editShow(rowIndex)'>  </span>
                </div>

                <!-- view_log (payroll -> closing)-->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'view_log'">
                      <span title='View Log'
                            *ngIf="!dataTypeServices.disableButtonPayrollClosing && temp[rowIndex]['logFile'] != null"
                            (click)="downloadViewLog(rowIndex)"
                            class="btn-sm fa fa-file-alt text-primary btnHover pr-2"></span>
                    <span title='View Log'
                          *ngIf="dataTypeServices.disableButtonPayrollClosing || temp[rowIndex]['logFile'] == null"
                          class="btn-sm fa fa-file-alt text-primary btnDisabled pr-2"
                          style="opacity:0.3; cursor: not-allowed;"></span>
                </div>

                <!-- download_view_log (tools -> import-data) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'download_logView'">
                    <div *ngIf="temp[rowIndex]['status'] != 'In Progress' && temp[rowIndex]['status'] != 'Pending'">
                          <span title='View Log'
                                *ngIf="!dataTypeServices.disableButtonImportData || temp[rowIndex]['logFile'] != null"
                                (click)="downloadLogView(rowIndex, 'viewLog')"
                                class="btn-sm fa fa-file-alt text-primary btnHover pr-2"></span>
                        <span title='View Log'
                              *ngIf="dataTypeServices.disableButtonImportData && temp[rowIndex]['logFile'] == null"
                              class="btn-sm fa fa-file-alt text-primary btnDisabled pr-2"></span>
                    </div>
                </div>

                <!-- download_log (tools -> mass-update) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'download_log'">
                      <span *ngIf="temp[rowIndex]['logFile']!=null" class="fas fa-file-alt text-primary btnHover"
                            (click)="downloadLog(rowIndex)"
                            style='font-size:20px'></span>
                    <span *ngIf="temp[rowIndex]['logFile']==null" class="fas fa-file-alt text-primary btnDisabled"
                          style='font-size:20px'></span>
                </div>

                <!-- download_view_log (tools -> process-manager) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'download_view_log'">
                    <div *ngIf="temp[rowIndex]['status'] != 'In Progress' && temp[rowIndex]['status'] != 'Pending'">
                        <span title='Download' *ngIf="temp[rowIndex]['reportUrl'] != null"
                              (click)="download(rowIndex, 'report')"
                              class="btn-sm fa fa-download btnHover text-success pr-2"></span>
                        <span title='Download' *ngIf="temp[rowIndex]['reportUrl'] == null"
                              class="btn-sm fa fa-download btnDisabled text-success pr-2"></span>
                        <span title='View Log'
                              *ngIf="!dataTypeServices.disableButtonProcessManager || temp[rowIndex]['logFile'] != null"
                              (click)="download(rowIndex, 'viewLog')"
                              class="btn-sm fa fa-file-alt text-primary btnHover pr-2"></span>
                        <span title='View Log'
                              *ngIf="dataTypeServices.disableButtonProcessManager && temp[rowIndex]['logFile'] == null"
                              class="btn-sm fa fa-file-alt text-primary btnDisabled pr-2"></span>
                    </div>
                </div>

                <!-- reprocess_rollback_log (payroll -> tax-process [1721A1 process]) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'reprocess_rollback_log'">
                    <span title='Reprocess All' *ngIf="!dataTypeServices.disableButtonTaxProcess"
                          class="btn-sm fa fa-undo-alt btnHover text-info pr-2"
                          (click)="reprocessFunc(rowIndex)"></span>
                    <span title='Reprocess All' *ngIf="dataTypeServices.disableButtonTaxProcess"
                          class="btn-sm fa fa-undo-alt btnDisabled text-info pr-2"></span>
                    <span title='Rollback' *ngIf="!dataTypeServices.disableButtonTaxProcess"
                          class="btn-sm fa fa-reply text-danger btnHover pr-2" (click)='rollbackFunc(rowIndex)'></span>
                    <span title='Rollback' *ngIf="dataTypeServices.disableButtonTaxProcess"
                          class="btn-sm fa fa-reply text-danger btnDisabled pr-2"></span>
                    <span title='View Log'
                          *ngIf="!dataTypeServices.disableButtonTaxProcess && temp[rowIndex]['logFile'] != null"
                          (click)="downloadViewLog(rowIndex)"
                          class="btn-sm fa fa-file-alt text-primary btnHover pr-2"></span>
                    <span title='View Log'
                          *ngIf="dataTypeServices.disableButtonTaxProcess || temp[rowIndex]['logFile'] == null"
                          class="btn-sm fa fa-file-alt text-primary btnDisabled pr-2"></span>
                </div>

                <!-- reprocess_rollback_viewLog (payroll -> process & result) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'reprocess_rollback_viewLog'">
                    <div *ngIf="temp[rowIndex]['status'] != 'In Progress' && temp[rowIndex]['status'] != 'Pending'">
                        <span title='Reprocess All'
                              *ngIf="!dataTypeServices.disableButtonProcessResult && !temp[rowIndex]['closed']"
                              class="btn-sm fa fa-undo-alt btnHover text-info pr-2"
                              (click)="reprocessFunc(rowIndex)"></span>
                        <span title='Reprocess All'
                              *ngIf="dataTypeServices.disableButtonProcessResult || temp[rowIndex]['closed']"
                              class="btn-sm fa fa-undo-alt btnDisabled text-info pr-2"></span>
                        <span title='Rollback'
                              *ngIf="!dataTypeServices.disableButtonProcessResult && !temp[rowIndex]['closed']"
                              class="btn-sm fa fa-reply text-danger btnHover pr-2"
                              (click)='rollbackFunc(rowIndex)'></span>
                        <span title='Rollback'
                              *ngIf="dataTypeServices.disableButtonProcessResult || temp[rowIndex]['closed']"
                              class="btn-sm fa fa-reply text-danger btnDisabled pr-2"></span>
                        <span title='View Log'
                              *ngIf="!dataTypeServices.disableButtonProcessResult && temp[rowIndex]['logFile'] != null"
                              (click)="downloadViewLog(rowIndex)"
                              class="btn-sm fa fa-file-alt text-primary btnHover pr-2"></span>
                        <span title='View Log'
                              *ngIf="dataTypeServices.disableButtonProcessResult || temp[rowIndex]['logFile'] == null"
                              class="btn-sm fa fa-file-alt text-primary btnDisabled pr-2"></span>
                    </div>
                </div>

                <!-- reprocess_rollback_log_costing (payroll -> costing) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'reprocess_rollback_log_costing'">
                    <span title='Reprocess All'
                          *ngIf="!temp[rowIndex]['isClosed'] && !dataTypeServices.disableButtonCostingPayroll"
                          class="btn-sm fa fa-undo-alt btnHover text-info pr-2"
                          (click)="reprocessFunc(rowIndex)"></span>
                    <span title='Reprocess All'
                          *ngIf="temp[rowIndex]['isClosed'] || dataTypeServices.disableButtonCostingPayroll"
                          class="btn-sm fa fa-undo-alt btnDisabled text-info pr-2"></span>
                    <span title='Rollback'
                          *ngIf="!temp[rowIndex]['isClosed'] && !dataTypeServices.disableButtonCostingPayroll"
                          class="btn-sm fa fa-reply text-danger btnHover pr-2" (click)='rollbackFunc(rowIndex)'></span>
                    <span title='Rollback'
                          *ngIf="temp[rowIndex]['isClosed'] || dataTypeServices.disableButtonCostingPayroll"
                          class="btn-sm fa fa-reply text-danger btnDisabled pr-2"></span>
                    <span title='View Log'
                          *ngIf="!dataTypeServices.disableButtonCostingPayroll && temp[rowIndex]['logFile'] != null"
                          (click)="downloadViewLog(rowIndex)"
                          class="btn-sm fa fa-file-alt text-primary btnHover pr-2"></span>
                    <span title='View Log'
                          *ngIf="dataTypeServices.disableButtonCostingPayroll || temp[rowIndex]['logFile'] == null"
                          class="btn-sm fa fa-file-alt text-primary btnDisabled pr-2"></span>
                </div>


                <!-- (NEW) FOR NGX-DATATABLE-FORM-GLOBAL  -->

                <!-- EDIT & DELETE (ORGANIZATION -> FORM -> COSTING) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'edit_delete' && dataExists">
                    <!-- ENABLE -->
                    <span *ngIf="!dataInput[dtInput['idx']]['readOnly']" title='Edit'
                          class='btn-sm fa fa-edit text-primary btnHover' style='color:white'
                          (click)='editShow(rowIndex)'></span>
                    <span *ngIf="!dataInput[dtInput['idx']]['readOnly']" title='Delete'
                          class='btn-sm fa fa-trash-alt text-danger btnHover' style='color:white'
                          (click)='deleteShow(rowIndex)'></span>

                    <!-- DISABLE -->
                    <span *ngIf="dataInput[dtInput['idx']]['readOnly']" title='Edit'
                          class='btn-sm fa fa-edit text-primary btnHover btnDisabled' style='color:white'></span>
                    <span *ngIf="dataInput[dtInput['idx']]['readOnly']" title='Delete'
                          class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled' style='color:white'></span>
                </div>

                <!-- EDIT & DELETE POINT (POINT -> CATALOG -> QUOTA) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'edit_delete_point' && dataExists">
                  <div *ngIf="rows.length<=0">
                    <span title='Edit' class='btn-sm fa fa-edit text-primary btnHover btnDisabled' style='color:white'></span>
                    <span title='Delete' class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled' style='color:white'></span>
                  </div>

                  <div *ngIf="rows.length==1">
                    <span title='Edit' class='btn-sm fa fa-edit text-primary btnHover btnDisabled' style='color:white' *ngIf="temp[rowIndex]['quotaNotDeletable'] && temp[rowIndex]['voucherType']=='Offline'"></span>
                    <span title='Edit' class='btn-sm fa fa-edit text-primary btnHover' style='color:white' (click)='editShow(rowIndex)' *ngIf="!(temp[rowIndex]['quotaNotDeletable'] && temp[rowIndex]['voucherType']=='Offline')"></span>
                    <span title='Delete' class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled' style='color:white' *ngIf="temp[rowIndex]['quotaNotDeletable']"></span>
                    <span title='Delete' class='btn-sm fa fa-trash-alt text-danger btnHover' style='color:white' (click)='deleteShow(rowIndex)' *ngIf="!temp[rowIndex]['quotaNotDeletable']"></span>
                  </div>

                  <div *ngIf="rows.length>1">
                    <span *ngIf="temp[rowIndex]['date'] < temp[rowIndex]['lastTransactionDate']" title='Edit' class='btn-sm fa fa-edit text-primary btnHover btnDisabled' style='color:white'></span>
                    <span *ngIf="temp[rowIndex]['date'] < temp[rowIndex]['lastTransactionDate']" title='Delete' class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled' style='color:white'></span>
                    <span *ngIf="temp[rowIndex]['date'] == temp[rowIndex]['lastTransactionDate'] && (temp[rowIndex]['quotaNotDeletable'] && temp[rowIndex]['voucherType']=='Offline' && temp.length==1)" title='Edit' class='btn-sm fa fa-edit text-primary btnHover btnDisabled' style='color:white'></span>
                    <span *ngIf="temp[rowIndex]['date'] == temp[rowIndex]['lastTransactionDate'] && (!(temp[rowIndex]['quotaNotDeletable'] && temp[rowIndex]['voucherType']=='Offline') || (temp[rowIndex]['quotaNotDeletable'] && temp[rowIndex]['voucherType']=='Offline' && temp.length>1))" title='Edit' class='btn-sm fa fa-edit text-primary btnHover' style='color:white' (click)='editShow(rowIndex)'></span>
                    <span *ngIf="temp.length==1 && temp[rowIndex]['quotaNotDeletable']" title='Delete' class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled' style='color:white'></span>
                    <span *ngIf="(temp[rowIndex]['date'] == temp[rowIndex]['lastTransactionDate']) && ((temp.length==1 && !temp[rowIndex]['quotaNotDeletable']) || temp.length>1)" title='Delete' class='btn-sm fa fa-trash-alt text-danger btnHover' style='color:white' (click)='deleteShow(rowIndex)'></span>
                  </div>
              </div>

                <!-- EDIT (PERFORMANCE -> FORM -> DETAILS) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'edit' && dataExists">
                    <!-- ENABLE -->
                    <span *ngIf="!dataInput[dtInput['idx']]['readOnly']" title='Edit'
                          class='btn-sm fa fa-edit text-primary btnHover' style='color:white'
                          (click)='editShow(rowIndex)'></span>

                    <!-- DISABLE -->
                    <span *ngIf="dataInput[dtInput['idx']]['readOnly']" title='Edit'
                          class='btn-sm fa fa-edit text-primary btnHover btnDisabled' style='color:white'></span>
                </div>

                <!-- EDIT, DELETE, REQUEST (CUSTOMER > BATCH UPDATE) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'edit_delete_request' && dataExists"
                     class="d-flex">
                    <!-- ENABLED EDIT, DELETE-->
                    <div>
                            <span title='Edit data' class='btn-sm fa fa-edit text-primary btnHover' style='color:white'
                                  (click)='editShow(rowIndex)'
                                  *ngIf="temp[rowIndex].status == null || temp[rowIndex]['status']=='New'"></span>
                        <span title='Delete data' class='btn-sm fa fa-trash-alt text-danger btnHover'
                              style='color:white'
                              (click)='deleteShow(rowIndex)'
                              *ngIf="temp[rowIndex].status == null || temp[rowIndex]['status']=='New'"></span>
                    </div>

                    <!-- DISABLED EDIT, DELETE-->
                    <div>
                            <span title="can't be edited anymore" class='btn-sm fa fa-edit text-primary btnDisabled'
                                  style='color:white'
                                  *ngIf="temp[rowIndex].status != null && temp[rowIndex].status != 'New'"></span>
                        <span title="can't be deleted anymore" class='btn-sm fa fa-trash-alt text-danger btnDisabled'
                              style='color:white'
                              *ngIf="temp[rowIndex].status != null && temp[rowIndex].status != 'New'"></span>

                    </div>

                    <div>
                        <!-- ENABLE REQUEST -->
                        <span title='Process' class='btn-sm fa fa-undo-alt text-danger btnHover' style='color:white'
                              (click)='reprocessFunc(rowIndex)' *ngIf="temp[rowIndex]['status']=='New'"></span>
                        <!-- DISABLE REQUEST -->
                        <span title='Process only for new data' class='btn-sm fa fa-undo-alt text-danger btnDisabled'
                              style='color:white'
                              *ngIf="temp[rowIndex]['status']!='New'"></span>
                    </div>

                    <div>
                            <span title="Preview Query" class="btn-sm fa fa-eye text-info btnHover"
                                  (click)="rollbackFunc(rowIndex)"></span>
                    </div>
                </div>

                <!-- REPROCESS DATA (CUSTOMER > BATCH UPDATE) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'reprocess' && dataExists">
                    <!-- ENABLED -->
                    <span title='Reprocess error data' class='btn-sm fa fa-undo-alt text-danger btnHover'
                          style='color:white'
                          (click)='reprocessFunc(rowIndex)' *ngIf="temp[rowIndex].status=='Error'"></span>

                    <!-- DISABLED -->
                    <span title='Can Reprocess only when data error'
                          class='btn-sm fa fa-undo-alt text-danger btnDisabled' style='color:white'
                          *ngIf="temp[rowIndex].status!='Error'"></span>
                </div>


                <!-- View History (Career -> assessment process) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'history_show'">
                    <!-- ENABLE -->
                    <span title='History' class='btn-sm fa fa-history text-primary btnHover' style='color:white'
                          (click)='editShow(rowIndex)'></span>
                </div>

                <!-- show_comment (Employee -> Performance) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'show_comment' && dataExists">
                        <span title='Edit' class='btn-sm fas fa-comment-dots btnHover'
                              style='color:yellowgreen;font-size:20px'
                              (click)='editShow(rowIndex)'>  </span>
                </div>

                <!-- download_attachment (Employee -> Goal)-->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'download_attachment' && dataExists">
                        <span title='Download' class='btn-sm fas fa-download text-success btnHover' style='color:white'
                              (click)='editShow(rowIndex)'>  </span>
                </div>

                <!-- DELETE (WORKFLOW APPROVAL -> APPROVAL-GROUP -> DETAILS) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'delete' && dataExists">
                    <!-- ENABLE -->
                    <span *ngIf="!dataInput[dtInput['idx']]['readOnly']" title='Delete'
                          class='btn-sm fa fa-trash-alt text-danger btnHover' style='color:white'
                          (click)='deleteShow(rowIndex)'></span>

                    <!-- DISABLE -->
                    <span *ngIf="dataInput[dtInput['idx']]['readOnly']" title='Delete'
                          class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled' style='color:white'></span>
                </div>

                <!-- Success status label (Customer -> Purchase History) -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'show_success_status'">
                    <span class="blockText"
                          [ngClass]="temp[rowIndex][dataInput[dtInput['idx']]['name']] == 'Success' ? 'text-success' : 'text-danger'">{{ temp[rowIndex][dataInput[dtInput['idx']]['name']] }} </span>
                </div>

                <!-- flexible_action -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'flexible_action_disabled'">
                        <span *ngFor="let flexible_action of dataInput[dtInput['idx']]['flexible_actions']">
                            <i *ngIf="flexible_action?.['tipe_action'] == 'edit'"
                               class='btn-sm fa fa-edit text-primary btnHover btnDisabled'></i>
                            <i *ngIf="flexible_action?.['tipe_action'] == 'delete'"
                               class='btn-sm fa fa-trash-alt text-danger btnHover btnDisabled'></i>
                        </span>
                </div>

                <!-- flexible_action -->
                <div *ngIf="dataInput[dtInput['idx']]['typeColumn'] == 'flexible_action' && dataExists">
                        <span *ngFor="let flexible_action of dataInput[dtInput['idx']]['flexible_actions']">
                              <i
                                      *ngIf="flexible_action?.['tipe_action'] == 'edit'"
                                      [title]="flexible_action?.['title'] ?? 'Edit'"
                                      class='btn-sm fa fa-edit text-primary btnHover'
                                      [ngClass]="{btnDisabled: (temp[rowIndex]?.[flexible_action?.['disabled_key']] == flexible_action?.['disabled_value']
                                                            && flexible_action?.['disabled_key'] != undefined && flexible_action?.['disabled_value'] != undefined)
                                                              || 
                                                          ((flexible_action?.['disabled_key'] == undefined || flexible_action?.['disabled_value'] == undefined) && dataInput[dtInput['idx']]['readOnly'])
                                              }"

                                      (click)="(
                                                (temp[rowIndex]?.[flexible_action?.['disabled_key']] != flexible_action?.['disabled_value'] &&
                                                  flexible_action?.['disabled_key'] != undefined && flexible_action?.['disabled_value'] != undefined)
                                                      || 
                                                  ((flexible_action?.['disabled_key'] == undefined || flexible_action?.['disabled_value'] == undefined) && !dataInput[dtInput['idx']]['readOnly'])
                                            ) ? editShow(rowIndex) : null"

                                      style='color:white'>
                                </i>
                              <i
                                      *ngIf="flexible_action?.['tipe_action'] == 'delete'"
                                      [title]="flexible_action?.['title'] ?? 'Delete'"
                                      class='btn-sm fa fa-trash-alt text-danger btnHover'
                                      [ngClass]="{btnDisabled: (temp[rowIndex]?.[flexible_action?.['disabled_key']] == flexible_action?.['disabled_value']
                                                            && flexible_action?.['disabled_key'] != undefined && flexible_action?.['disabled_value'] != undefined)
                                                              || 
                                                          ((flexible_action?.['disabled_key'] == undefined || flexible_action?.['disabled_value'] == undefined) && dataInput[dtInput['idx']]['readOnly'])
                                              }"

                                      (click)="(
                                                (temp[rowIndex]?.[flexible_action?.['disabled_key']] != flexible_action?.['disabled_value'] &&
                                                  flexible_action?.['disabled_key'] != undefined && flexible_action?.['disabled_value'] != undefined)
                                                      || 
                                                  ((flexible_action?.['disabled_key'] == undefined || flexible_action?.['disabled_value'] == undefined) && !dataInput[dtInput['idx']]['readOnly'])
                                            ) ? deleteShow(rowIndex) : null"

                                      style='color:white'
                              >
                              </i>

                            <!-- Download icon with attribute (ex: tools > import data parameters) -->
                              <i
                                      *ngIf="flexible_action?.['tipe_action'] == 'download'"
                                      [title]="flexible_action?.['title'] ?? 'Download'"
                                      class='btn-sm fa fa-download text-success btnHover pr-2'
                                      [ngClass]="{btnDisabled: (temp[rowIndex]?.[flexible_action?.['disabled_key']] == flexible_action?.['disabled_value']
                                                            && flexible_action?.['disabled_key'] != undefined && flexible_action?.['disabled_value'] != undefined)
                                                              || 
                                                          ((flexible_action?.['disabled_key'] == undefined || flexible_action?.['disabled_value'] == undefined) && dataInput[dtInput['idx']]['readOnly'])
                                              }"

                                      (click)="(
                                                (temp[rowIndex]?.[flexible_action?.['disabled_key']] != flexible_action?.['disabled_value'] &&
                                                  flexible_action?.['disabled_key'] != undefined && flexible_action?.['disabled_value'] != undefined)
                                                      || 
                                                  ((flexible_action?.['disabled_key'] == undefined || flexible_action?.['disabled_value'] == undefined) && !dataInput[dtInput['idx']]['readOnly'])
                                            ) ? downloadLogView(rowIndex, 'oncheck') : null"

                                      style='color:white'
                              >
                              </i>
                        </span>
                </div>
                <!-- ... end flexible_action -->

                <span *ngIf="!dataExists && dataInput[dtInput['idx']]['noDataDisplay']">{{ 'No data to display' | translate }}</span>
            </ng-template>

        </ngx-datatable-column>

        <ngx-datatable-footer *ngIf="true">
            <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
            >
                <div class="datatable-info-data" style="padding: 10px 20px 5px 5px;white-space:nowrap;">
                    <div class="datatable-total-record" style='font-size:14px'>
                        {{ totalRecord_String }} {{ 'Total Record' | translate }}
                    </div>
                    <!-- <div class = "py-1 text-danger font-weight-bold mt-2" style = 'font-size:12px'
                        *ngIf="dataTypeServices.totalRecord > 1000">
                        <span>{{ 'In order to show you the results that are most relevant to you, we have omitted some entries very similar to the' | translate }} {{ tempLength }} {{ 'of' | translate }} {{ dataTypeServices.totalRecord_String }} {{ 'already shown.' | translate }}</span>
                        <br><span>{{ 'If you want, you can repeat the search including the ignored results.' | translate }} </span>
                    </div> -->
                </div>
                <datatable-pager
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="needHitAPI ? currentPage : curPage"
                        [size]="pageSize"
                        [count]="needHitAPI ? totalElements : rowCount"
                        (change)="needHitAPI ? changePagination($event) : dataTable.onFooterPage($event)"
                        [hidden]="needHitAPI ? !((totalElements / pageSize) > 1) : !((rowCount / pageSize) > 1)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

    <!-- <ng-http-loader
          [entryComponent]="httpLoaderImage"
      >
      </ng-http-loader> -->

</div>

<!-- <div class = "reportfilterbackground">
    <div class = 'input-group input-group-sm'>

        <input type = "text" [name] = "filterHeaderArray[0].column" class = 'form-control form-control-sm'>

        <div class = 'input-group-prepend'>
            <span class = 'input-group-text'>
                <i [class] = 'filterHeaderArray[0].sort' (click)="changeSort(0)"></i>
            </span>
        </div>

    </div>
</div> -->


<!-- REVIEW DEPLOY -->

<!-- <ngx-datatable-form-global
        [configTable]="configTable_Detail"

        [cellKeyId]="'organizationCostingId'"
        [tableId]="tableIdCosting"
        [tableIcon]="'fa fa-sitemap'"
        [tableName]="'Costing'"

        [showStatusDropdown]="true"
        [headerStatusCode]="0"
        [headerStatusKey]="'active'"
        [selectedActive]="selectedActive"
        [modelEffectiveDate]="modelEffectiveDate"
        [modelEffectiveDateFinal]="modelEffectiveDateFinal"
        [hideSortFilterTable]=[0]
        [dataParamsExport]="dataParamsExport"

        [exportApiGetData]="'/api/v1/organization/export'"
        [exportProcessName]="'RPT_SS_WORK_STRUCTURE_ORGANIZATION'"
        [exportNeedAOD]="true"
        [exportIsDisabled]="false"

        [dataExists]="dataExists"
        [dataInput]="dataInput"

        [statusFinishedApi]="statusFinishedApi"
        [statusAODParamUrl]="statusAODParamUrl"

        [showRefreshButton]="true"
        [showExportButton]="true"
        [showCreateButton]="true"

        [statusDisabledDropdown]="false"
        [avatarDivWidth]="'50px'"
        [avatarFontSize]="'1.5rem'"

        (elementGlobal)="elementGlobal($event)"
  >
  </ngx-datatable-form-global> -->


<!-- (NGX-DATATABLE-FORM-GLOBAL) PUSH DATA TO GLOBAL, MODAL DI HANDLE PADA global subject_rows

subject_rows.next({tableId:this.tableIdCosting, tipe:'bulk', data:[]})

subject_rows.next({tableId:this.tableIdCosting, tipe:'bulk', data:[...this.rowsDetail]})

subject_rows.next({tableId: this.tableIdCosting, data:obj, tipe:'add', modalDefault:this.modalDefault
                              ,arrCheckDuplicate:["coaId"]
                              ,arrCheckDuplicate_Msg:'Name is already exists !'

subject_rows.next({tableId: this.tableIdCosting,
      data:obj,
      tipe:'edit',
      rowIndex: this.rowIndex_Selected,
      modalDefault:this.modalDefault,
      deleteKeyNull:[],   // DELETE DATA YANG NULL
      arrCheckDuplicate:["coaId"],      // cek duplicate data baik add ataupun edit
      arrCheckDuplicate_Msg:'Name is already exists !'})
// ... end -->


<!-- TYPESCRIPT -->

<!-- this.dataInput = [
{
  // idx:0,
  // nameNgTemplate:null,
  // idNgTemplate:null,
  name:'no',
  nameParamUrl:'no',
  label:'No',
  width:70,
  minWidth:undefined,   // undefined -> jika tdk ada
  maxWidth:70,          // undefined -> jika tdk ada
  headerClass:'text-left',
  cellClass:'text-left',
  statusCellDataExists:true,
  statusSummaryTemplate:true,
  typeSummaryTemplate:null,   // text, currency, date, number, number_decimal, time
  statusFormProc:false,
  noDataDisplay:false,
  typeColumn:'nourut', // bisa custom dengan komponen global (default, nourut, dst)
  typeSortColumn:'number' // 'number' or 'text' or 'date'
},
{name:'name', nameParamUrl:'name', label:'Name', width:150, minWidth:undefined, maxWidth:undefined, headerClass:'text-left', cellClass:'text-left', statusCellDataExists:true, statusSummaryTemplate:true, typeSummaryTemplate:'text', statusFormProc:false, noDataDisplay:true, typeColumn:'default', typeSortColumn:'text', cellIsEmpty:false},
{name:'segmentLevel', nameParamUrl:'segmentLevel', label:'Segment Level', width:150, minWidth:undefined, maxWidth:undefined, headerClass:'text-left', cellClass:'text-left', statusCellDataExists:true, statusSummaryTemplate:true, typeSummaryTemplate:'number', statusFormProc:false, noDataDisplay:false, typeColumn:'default', typeSortColumn:'number'},
{name:'costing', nameParamUrl:'costing', label:'Costing', width:150, minWidth:undefined, maxWidth:undefined, headerClass:'text-left', cellClass:'text-left', statusCellDataExists:true, statusSummaryTemplate:true, typeSummaryTemplate:'text', statusFormProc:false, noDataDisplay:false, typeColumn:'default', typeSortColumn:'text'},
{name:'balancing', nameParamUrl:'balancing', label:'Balancing', width:150, minWidth:undefined, maxWidth:undefined, headerClass:'text-left', cellClass:'text-left', statusCellDataExists:true, statusSummaryTemplate:true, typeSummaryTemplate:'text', statusFormProc:false, noDataDisplay:false, typeColumn:'default', typeSortColumn:'text'},
{name:'activeLabel', nameParamUrl:'activeLabel', label:'Active', width:140, minWidth:140, maxWidth:140, headerClass:'text-left', cellClass:'text-left', statusCellDataExists:true, statusSummaryTemplate:false, typeSummaryTemplate:null, statusFormProc:false, noDataDisplay:false, typeColumn:'default', typeSortColumn:'text'},
{name:'action', nameParamUrl:'action', label:'Action', width:150, minWidth:150, maxWidth:150, headerClass:'text-left', cellClass:'text-left', statusCellDataExists:true, statusSummaryTemplate:false, typeSummaryTemplate:null, statusFormProc:false, noDataDisplay:false, typeColumn:'edit_delete', typeSortColumn:'text', readOnly:false}
] -->


<!-- // (TYPESCRIPT) HANDLER ELEMENT GLOBAL

elementGlobal($event:element_global_type){

  // KONDISI MEMBUKA MODAL CREATE
  if ($event['tipe'] == 'add'){     // SETELAH TRIGGER CREATE ELEMENT BUTTON
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.createElementDetail()
    }
  }

   // KONDISI SETELAH UPDATE ITEM SELESAI CREATE
  if ($event['tipe'] == 'add_complete'){    // SETELAH INSERT DATA BERHASIL DARI CREATE ELEMENT
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowsDetail = $event?.['rows']
      this.tempDetail = $event?.['temp']
    }
  }

  // KONDISI MEMBUKA MODAL EDIT
  if ($event['tipe'] == 'edit'){     // SETELAH TRIGGER EDIT BUTTON ACTION
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowIndex_Selected = $event?.['rowIndex']          
      this.editShow($event?.['rowIndex'])
    }
  }

  // KONDISI SETELAH UPDATE ITEM SELESAI EDIT
  if ($event['tipe'] == 'edit_complete'){    // SETELAH INSERT DATA BERHASIL DARI CREATE ELEMENT
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowsDetail = $event?.['rows']
      this.tempDetail = $event?.['temp']
    }
  }

  // KONDISI SETELAH DELETE ROW
  if ($event['tipe'] == 'delete_complete'){    // SETELAH DELETE DATA BERHASIL
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowsDetail = $event?.['rows']
      this.tempDetail = $event?.['temp']
    }
  }

  // SORTING
  if ($event['tipe'] == 'sort'){    // SETELAH SORT DATA
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowsDetail = $event?.['rows']
      this.tempDetail = $event?.['temp']
    }
  }

  // KONDISI FILTER INPUT
  if ($event['tipe'] == 'filter'){    // FILTER DATA
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowsDetail = $event?.['rows']
      this.tempDetail = $event?.['temp']
    }
    
    if ($event?.['tableId'] == this.tableIdHistory)
    {
      this.rowsHistory = $event?.['rows']
      this.tempHistory = $event?.['temp']
    }
  }

  // KLIK ITEM MASUK KE FORM
  if ($event['tipe'] == 'form_proc'){     // TRIGGER SETELAH KLIK ITEM TER-UNDERLINE
    // HANYA TABLE ID YANG SESUAI YANG AKAN DI MASUKKAN KE DALAM ARRAY BERSANGKUTAN
    if ($event?.['tableId'] == this.tableIdHistory)
    {
      this.selectedHistory($event?.['selectedRow'])
    }
  }

  // CLOSE MODAL
  if ($event['tipe'] == 'close_modal'){
    if ($event?.['tableId'] == this.tableIdHistory)
    {
      this.modalRef.hide()
    }
  }

  // DATA BLANK
  if ($event['tipe'] == 'error'){     // bisa jadi data kosong
    if ($event?.['tableId'] == this.tableIdHistory)
    {
      this.rowsHistory = $event?.['rows']
      this.tempHistory = $event?.['temp']
    }
    
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.rowsDetail = $event?.['rows']
      this.tempDetail = $event?.['temp']
    }
  }

  // REFRESH
  if ($event['tipe'] == 'refresh'){     // kondisi klik elementClassLoad
    if ($event?.['tableId'] == this.tableIdCosting)
    {
      this.elementClassLoadCriteria()
    }
  }

} -->


<!-- // SET READ ONLY action pada datainput & disabled pada button create, export, refresh
  
  this.dataInput = [...this.dataTypeServices.set_ReadOnly_dataInput(['action'],this.dataInput,true)]
  this.configTable = {
    isDisabled:{create:true, export:true, refresh: true}
  } 
-->

<!-- FUNCTION ATTACH IN LOCAL---

  set_readOnly_dataInput_Local(readonly:boolean){
      // SET READ ONLY action pada datainput & disabled pada button create, export, refresh
      this.dataInput = [...this.dataTypeServices.set_ReadOnly_dataInput(['action'],this.dataInput,readonly)]
      this.configTable_Detail = {
        isDisabled:{create:readonly, export:readonly, refresh: readonly}
      }
      // ... end SET READ ONLY
  } 

-->
