import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import {
  ROUTES,
  subject_sidebar_userprofile_status,
} from "../sidebar/sidebar.component";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { MethodServices } from "../../../services/method-services";
import { JwtHelperService } from "@auth0/angular-jwt";

import { Location } from "@angular/common";
import { Subject, takeUntil } from "rxjs";
import { ModalDirective } from "ngx-bootstrap/modal";
import { DataTypeServices } from "src/services/data-type-services";
import { TranslateService } from "@ngx-translate/core";
import { SharingModule } from "src/app/sharing-module/sharing.module";
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  standalone: true,
  imports: [SharingModule, NgSelectModule],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;

  employeeId: string;
  employeeName: string;
  employeeNameNavbar: string;
  employeeInitial: string;
  backgroundAvatar: string;
  employeeNo: string;
  positionName: string;
  urlEndParse: string;

  // DARK MODE
  darkModeChecked: boolean = false;
  darkModeIsChecked: boolean = false;
  sidenavOpen: boolean = true;

  // LANGUAGE
  dropdownFocused: boolean = false;
  selectedLang: any = "English";
  selectedLangCode: any = "en";
  langLoop: any[] = [
    { lang: "english", flag: "en" },
    { lang: "indonesia", flag: "id" },
    { lang: "korea", flag: "kr" },
    { lang: "thailand", flag: "th" },
  ];

  companyLogo_LightMode: any = "assets/img/brand/starconnect-06.png";
  companyLogo_DarkMode: any = "assets/img/brand/starconnect-06-darkmode.png";
  listUsersData: any = [];

  companyChatbotList: any = [];
  selectedCompany = null;
  loadingChatbotApi: boolean = false;
  chatbotValue: any;
  questionAnswerList = [];
  welcomeTitle = "Welcome to StarConnect ! 👋";
  welcomeDesc =
    "My name is Synthia, your AI Assistant dedicated to helping you navigate through our company's regulations and policies. Whether you're looking for specific guidelines or need clarity on best practices, I'm here to provide you with the information you need. How can I assist you today?";

  @ViewChild("nativeToggleDarkMode", { static: false, read: ElementRef })
  nativeToggleDarkMode: any;

  constructor(
    public methodServices: MethodServices,
    public dataTypeServices: DataTypeServices,
    location: Location,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          this.sidenavOpen = false;
        }

        // LANGUAGE FROM LOCALSTORAGE
        if (
          localStorage.getItem("GITLANG") != null &&
          typeof localStorage.getItem("GITLANG") != "undefined"
        ) {
          const langCode = this.methodServices.ivSha256Decrypt(
            localStorage.getItem("GITLANG")
          );
          this.selectedLang = this.langLoop.find(
            (result) => result.flag.toLowerCase() == langCode.toLowerCase()
          )["lang"];
          this.selectedLangCode = langCode;
          this.translate.use(langCode);
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
      }
    });
  }
  employeeNames: string;
  hasil_temp: string[] = [];
  employeeImage: string;
  loginCompanyId: any;
  authoritiesToken: any;

  modelCurrentPasswordModal: any;
  modelNewPasswordModal: any;
  modelConfirmNewPasswordModal: any;
  modelImage: any;
  modelImageReader: any;
  modelImageReaderFirst: any;

  statusImageExists: any;

  disabledButton: boolean = false;
  readOnlyInput: boolean = false;
  keyjsonLogout: any;

  @ViewChild("modalDefault") modalDefault: ModalDirective;
  @ViewChild("modalChangePicture") modalChangePicture: ModalDirective;
  @ViewChild("modalSwitchUser") modalSwitchUser: ModalDirective;
  @ViewChild("modalChatbot") modalChatbot: ModalDirective;
  @ViewChild("nativeNewPasswordModal") nativeNewPasswordModal: ElementRef;
  @ViewChild("nativeCurrentPasswordModal")
  nativeCurrentPasswordModal: ElementRef;
  @ViewChild("nativeConfirmNewPasswordModal")
  nativeConfirmNewPasswordModal: ElementRef;
  @ViewChild("nativeImage") nativeImage: ElementRef;
  @ViewChild("nativeImageLoad") nativeImageLoad: ElementRef;

  subject_DarkMode: Subject<any> = new Subject();

  ngUnsubscribe: Subject<any> = new Subject();

  usernameKey: any;
  passKey: any;

  skeletonIcon: boolean = true;
  disabledSideMenu: boolean = false;

  ngOnInit() {
    this.methodServices.imageLoad = false;
    this.methodServices.companyLogo = null;
    this.skeletonIcon = true;
    this.sidenavOpen = true;

    this.methodServices.employeInformation.subscribe((res) => {
      let dataObj = JSON.parse(res);
      this.employeeName = dataObj["employeeName"];
      this.employeeNameNavbar = dataObj["employeeName"];
      this.employeeId = dataObj["employeeId"];
      this.employeeInitial = dataObj["employeeInitial"];
    });

    this.methodServices.openTopNav.subscribe((res) => {
      this.sidenavOpen = !res;
    });

    this.methodServices.employeeProfilePicture
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        let dataObj = JSON.parse(res);
        this.backgroundAvatar = dataObj["backgroundAvatar"];
        this.employeeImage = dataObj["employeeImage"];
        this.modelImage = dataObj["employeeImage"];
        this.modelImageReader = dataObj["employeeImage"];
        this.modelImageReaderFirst = dataObj["employeeImage"];
        this.disabledSideMenu = false;
      });

    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    this.activatedRoute.params.subscribe((result) => {
      let keyStor: any;
      let valStor: any;
      let existsKeyjson: boolean = false;
      if (localStorage.length > 0) {
        for (var i = 0; i < localStorage.length; i++) {
          keyStor = this.methodServices.ivSha256Decrypt(localStorage.key(i));
          valStor = this.methodServices.ivSha256Decrypt(
            localStorage.getItem(localStorage.key(i))
          );
          if (keyStor == "keyjson") {
            existsKeyjson = true;
          }
        }
        if (!existsKeyjson) {
          if (localStorage.getItem("SPS!#WU") != null) {
            this.passKey = localStorage.getItem("SPS!#WU");
          }
          if (localStorage.getItem("ZEU@AL!") != null) {
            this.usernameKey = localStorage.getItem("ZEU@AL!");
          }
          localStorage.clear();
          if (this.passKey != undefined && this.usernameKey != undefined) {
            localStorage.setItem("ZEU@AL!", this.usernameKey);
            localStorage.setItem("SPS!#WU", this.passKey);
          }
          this.router.navigate(["login"]);
        }
      } else {
        this.router.navigate(["login"]);
      }

      var url = this.location.prepareExternalUrl(this.location.path());
      var urlEnd = url.lastIndexOf("/") + 1;
      this.urlEndParse = (
        url.substr(urlEnd, 1).toUpperCase() +
        url.slice(urlEnd + 1).toLowerCase()
      ).toUpperCase();
      //remove -
      this.urlEndParse = this.urlEndParse.replace("-", " ");

      //No Get QUery Params
      var urlQuestion = this.urlEndParse.indexOf("?");
      if (urlQuestion != -1) {
        var urlFinal = this.urlEndParse.slice(0, urlQuestion);
        this.urlEndParse = urlFinal;
      }

      // sebagai tanda bahwa user/profile sudah di hit api nya
      subject_sidebar_userprofile_status.next(false);

      // GET IMAGE PROFILE (NANTI DI AKTIFKAN LAGI)
      this.methodServices.loopStorage(() => {
        if (this.methodServices.jwt_role_user != "SUPER_ADMIN") {
          // MICROSERVICES
          this.methodServices.getInformationEmployee(() => {
            this.methodServices.getTenantRefreshApi(
              "",
              this.methodServices.jwt_access_token,
              this.methodServices.jwt_refresh_token,
              "",
              {},
              () => {}
            );
          });
          this.methodServices.getCompanyProfile(() => {
            this.methodServices.getTenantRefreshApi(
              "",
              this.methodServices.jwt_access_token,
              this.methodServices.jwt_refresh_token,
              "",
              {},
              () => {}
            );
          });
        } else {
          let jwt = new JwtHelperService();
          let jwtDecode = jwt.decodeToken(this.methodServices.jwt_access_token);
          this.employeeName = jwtDecode["user_name"];
          this.employeeNameNavbar = jwtDecode["user_name"];
          this.employeeNames = jwtDecode["user_name"];
          this.employeeInitial = jwtDecode["user_name"].charAt(0);
          this.backgroundAvatar =
            this.methodServices.getAvatarBackgroundColor();
          // Comment Sementara untuk subscription SUPER_ADMIN
          // this.methodServices.getTenantApi('/api/v1/subscription', (result,status) => {
          //     if (status == 'Success') {
          //         this.employeeName = result.contactPerson
          //         this.employeeNameNavbar = result.contactPerson
          //         this.employeeNames = result.contactPerson
          //         this.employeeInitial = result.contactPerson.charAt(0)
          //         this.backgroundAvatar = this.methodServices.getAvatarBackgroundColor()
          //     }
          // })
          this.methodServices.companyLogo =
            "assets/img/brand/starconnect-06.png";
          this.methodServices.imageLoad = true;
          this.disabledSideMenu = false;
        }
      }); //tutup loop localStorage
    });
  }

  loadNavbarIcon(event) {
    this.skeletonIcon = false;
    let navWidth = document.getElementById("nav-brand");
    let calc = (navWidth.offsetWidth + 90).toString() + "px";
    document.documentElement.style.setProperty("--left-wrapper-width", calc);
    document.documentElement.style.setProperty("--as-of-date-opacity", "1");
    document.documentElement.style.setProperty("--as-of-date-z-index", "1001");
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSidebar() {
    if (this.disabledSideMenu) {
      return;
    }
    if (this.sidenavOpen) {
      this.sidenavOpen = false;
    } else {
      this.sidenavOpen = true;
    }
    this.methodServices.openTopNav.next(!this.sidenavOpen);
  }

  logout() {
    if (typeof Storage !== "undefined") {
      let keyjson = {
        darkMode: localStorage.getItem("GITDMOD"),
        lang: localStorage.getItem("GITLANG"),
      };

      let encryptKeyjson = this.methodServices.ivSha256Encrypt(
        JSON.stringify(keyjson)
      );
      if (localStorage.getItem("SPS!#WU") != null) {
        this.passKey = localStorage.getItem("SPS!#WU");
      }
      if (localStorage.getItem("ZEU@AL!") != null) {
        this.usernameKey = localStorage.getItem("ZEU@AL!");
      }
      localStorage.clear();

      if (this.usernameKey != undefined) {
        localStorage.setItem("ZEU@AL!", this.usernameKey);
      }
      if (this.passKey != undefined) {
        localStorage.setItem("SPS!#WU", this.passKey);
      }
      if (keyjson["darkMode"] != undefined) {
        localStorage.setItem("GITDMOD", keyjson["darkMode"]);
      }
      if (keyjson["lang"] != undefined) {
        localStorage.setItem("GITLANG", keyjson["lang"]);
      }
      this.usernameKey = null;
      this.passKey = null;
      this.keyjsonLogout = encryptKeyjson;
    }
    this.router.navigate(["login"]);
  }

  clickNotification(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  changePassword() {
    this.nativeCurrentPasswordModal.nativeElement.value = "";
    this.nativeNewPasswordModal.nativeElement.value = "";
    this.nativeConfirmNewPasswordModal.nativeElement.value = "";
    this.modalDefault.show();
  }

  updateItem() {
    let currentPasswordModal =
      this.nativeCurrentPasswordModal.nativeElement.value == null
        ? ""
        : this.nativeCurrentPasswordModal.nativeElement.value;
    let newPasswordModal =
      this.nativeNewPasswordModal.nativeElement.value == null
        ? ""
        : this.nativeNewPasswordModal.nativeElement.value;
    let confirmNewPasswordModal =
      this.nativeConfirmNewPasswordModal.nativeElement.value == null
        ? ""
        : this.nativeConfirmNewPasswordModal.nativeElement.value;

    if (currentPasswordModal == "") {
      this.methodServices.showToast(
        "Current Password can't be blank !",
        "error"
      );
      return;
    }
    if (newPasswordModal == "") {
      this.methodServices.showToast("New Password can't be blank !", "error");
      return;
    }
    if (confirmNewPasswordModal == "") {
      this.methodServices.showToast(
        "Confirm New Password can't be blank !",
        "error"
      );
      return;
    }
    if (newPasswordModal != confirmNewPasswordModal) {
      this.methodServices.showToast(
        "New and Confirm New Password do not match !",
        "error"
      );
      return;
    }
    let exptes = new RegExp(
      /^(?=.*[A-Z])(?=.*[#?!@$%^&*-])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
      "g"
    );
    if (newPasswordModal.match(exptes) == null) {
      this.methodServices.showToast(
        "Your Password must contain one lowercase character, one uppercase, one number, one symbol and between 8 - 20 character long !",
        "error"
      );
      return;
    }
    if (confirmNewPasswordModal.match(exptes) == null) {
      this.methodServices.showToast(
        "Your Password must contain one lowercase character, one uppercase, one number, one symbol and between 8 - 20 character long !",
        "error"
      );
      return;
    }

    let dataObj = {};
    dataObj["oldPassword"] = currentPasswordModal;
    dataObj["newPassword"] = newPasswordModal;
    dataObj["confirmationPassword"] = confirmNewPasswordModal;

    this.readOnlyInput = true;
    this.disabledButton = true;

    this.saveData(dataObj, "password", (result, msg) => {
      if (result == "Success") {
        this.methodServices.showToast(msg, "success");
        setTimeout(() => {
          this.readOnlyInput = false;
          this.disabledButton = false;
          this.modalDefault.hide();
        }, 1800);
      }
    });
  }

  saveData(dataObj, userprofile, callback?) {
    let link: any;
    if (this.methodServices.jwt_role_user == "SUPER_ADMIN") {
      link = "/api/subscription/user/change-password";
    } else {
      if (userprofile == "password") {
        link = "/api/user/my-profile/password/change";
      } else if (userprofile == "picture") {
        link = "/api/user/profile/picture";
      }
    }

    this.methodServices.postTenantApi(link, dataObj, (result, status) => {
      if (status == "Success") {
        callback("Success", result.message);
      } else {
        if (
          typeof result.error.message != "undefined" &&
          result.error.message != "" &&
          result.error.message != null
        ) {
          this.methodServices.showToast(result.error.message, "error");
          this.readOnlyInput = false;
          this.disabledButton = false;
          callback("Error");
        } else {
          this.methodServices.showToast(result.error.error, "error");
          this.readOnlyInput = false;
          this.disabledButton = false;
          callback("Error");
        }
      }
    });
  }

  changePicture() {
    this.modelImage = this.modelImageReaderFirst;
    this.modelImageReader = this.modelImageReaderFirst;
    this.statusImageExists = "";
    this.readOnlyInput = false;
    this.disabledButton = false;
    this.modalChangePicture.show();
  }

  clearImage() {
    this.modelImage = null;
    this.nativeImage.nativeElement.value = null;
    this.statusImageExists = "DELETE";
  }

  uploadFile(event) {
    if (typeof event.target.files[0] != "undefined") {
      this.modelImage = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(this.modelImage);
      fileReader.onload = (event: any) => {
        let size = this.modelImage["size"] / (1024 * 1024);
        if (size > 5) {
          this.modelImage = null;
          this.nativeImage.nativeElement.value = null;
          this.methodServices.showToast(
            "Maximum file size allowed is 5MB",
            "error"
          );
          return;
        }
        this.modelImageReader = event.target.result;
        this.statusImageExists = "UPDATE";
      };
    }
  }

  // SUBMIT CHANGE PICTURE
  submitProc() {
    if (typeof this.modelImage == "undefined" || this.modelImage == null) {
      this.methodServices.showToast("Image can't be Blank !", "error");
      return;
    }

    if (this.statusImageExists != "UPDATE") {
      this.methodServices.showToast("No Image Update !", "error");
      return;
    }

    let formData = new FormData();
    if (typeof this.modelImage != "undefined" && this.modelImage != null) {
      if (typeof this.modelImage == "object") {
        formData.append("profilePicture", this.modelImage);
      }
    }
    this.readOnlyInput = true;
    this.disabledButton = true;

    this.saveData(formData, "picture", (result, msg) => {
      if (result == "Success") {
        this.methodServices.showToast(msg, "success");
        this.modelImageReaderFirst = this.modelImageReader;
        this.employeeImage = this.modelImageReader;

        setTimeout(() => {
          this.methodServices.statusChange = false;
          this.readOnlyInput = false;
          this.disabledButton = false;
          this.modalChangePicture.hide();
        }, 1800);
      } else {
        this.readOnlyInput = false;
        this.disabledButton = false;
      }
    });
  }

  ngAfterViewInit() {
    // GITDMOD => DARK MODE STATUS (TRUE / FALSE)
    if (
      localStorage.getItem("GITDMOD") != null &&
      typeof localStorage.getItem("GITDMOD") != "undefined"
    ) {
      this.darkModeChecked =
        this.methodServices.ivSha256Decrypt(localStorage.getItem("GITDMOD")) ==
        "true"
          ? true
          : false;
      if (this.darkModeChecked) {
        this.methodServices.subject_DarkMode.next(true);
        this.darkModeIsChecked = true;
      }
    } else {
      localStorage.setItem(
        "GITDMOD",
        this.methodServices.ivSha256Encrypt("false")
      );
      this.darkModeChecked = false;
      this.darkModeIsChecked = false;
    }
  }

  changeDarkMode($event) {
    // parsing ke app.component.ts
    let isDarkMode = this.nativeToggleDarkMode.nativeElement.checked;

    this.methodServices.statusDark = !isDarkMode;

    if (
      localStorage.getItem("GITDMOD") != null &&
      typeof localStorage.getItem("GITDMOD") != "undefined"
    ) {
      localStorage.removeItem("GITDMOD");
    }
    localStorage.setItem(
      "GITDMOD",
      this.methodServices.ivSha256Encrypt((!isDarkMode).toString())
    );

    this.methodServices.subject_DarkMode.next(!isDarkMode);
    this.darkModeIsChecked = !this.darkModeIsChecked;
  }

  hoverDropdown() {
    this.dropdownFocused = !this.dropdownFocused;
  }

  funcChangeLang(lang: any) {
    switch (lang) {
      case "en":
        this.selectedLang = "English";
        this.selectedLangCode = "en";
        this.translate.use("en");
        break;
      case "id":
        this.selectedLang = "Indonesia";
        this.selectedLangCode = "id";
        this.translate.use("id");
        break;
      case "kr":
        this.selectedLang = "Korea";
        this.selectedLangCode = "kr";
        this.translate.use("kr");
        break;
      case "th":
        this.selectedLang = "Thailand";
        this.selectedLangCode = "th";
        this.translate.use("th");
        break;
    }

    if (
      typeof localStorage.getItem("GITLANG") != "undefined" &&
      localStorage.getItem("GITLANG") != null
    ) {
      localStorage.removeItem("GITLANG");
    }
    localStorage.setItem(
      "GITLANG",
      this.methodServices.ivSha256Encrypt(this.selectedLangCode)
    );
  }

  switchUser() {
    this.methodServices.getTenantApi(
      "/api/user/profile/list",
      (result, status) => {
        if (status == "Success") {
          this.listUsersData = [];
          for (let arr in result) {
            this.listUsersData.push({
              username: result[arr]["username"],
              employeeId: result[arr]["employeeId"],
              fullName: result[arr]["fullName"],
              firstName: result[arr]["firstName"],
              lastName: result[arr]["lastName"],
              alias: result[arr]["alias"],
              positionName: result[arr]["positionName"],
              employeeNo: result[arr]["employeeNo"],
              profilePicture: result[arr]["profilePicture"],
              currentProfile: result[arr]["currentProfile"],
              backgroundAvatarColor: this.methodServices.getRandomColor(),
            });
          }
        }
      }
    );
    this.modalSwitchUser.show();
  }

  selectProfile(data) {
    if (data.currentProfile) return;

    let keyjson: any = {};

    keyjson["username"] = data.username;
    keyjson["currentProfileEmployeeId"] = data.employeeId;

    this.methodServices.postTenantApi(
      "/api/user/profile/change-profile",
      keyjson,
      (result, status) => {
        if (status == "Success") {
          this.modalSwitchUser.hide();
          this.methodServices.getTenantRefreshApi(
            "",
            this.methodServices.jwt_access_token,
            this.methodServices.jwt_refresh_token,
            "",
            {},
            () => {
              window.location.reload();
            }
          );
        }
      }
    );
  }

  onOpenChange(event) {}

  openChatbot() {
    if (!this.methodServices.imageLoad) {
      return;
    }

    this.chatbotValue = null;
    this.selectedCompany = null;
    this.companyChatbotList = [];
    this.questionAnswerList = [];

    this.methodServices.getTenantApi(
      "/api/admin/ai/knowledge",
      (result: any, status: any) => {
        if (status == "Success") {
          for (let arr in result["data"]) {
            if (result["data"][arr]["detailKnowledge"].length > 0) {
              this.companyChatbotList.push({
                companyId: result["data"][arr]["companyId"],
                companyName: result["data"][arr]["companyName"],
              });
            }
          }

          if (this.companyChatbotList.length > 0) {
            this.selectedCompany = this.companyChatbotList[0]["companyId"];
            if (
              this.companyChatbotList.find(
                (data) => data["companyId"] == this.methodServices.companyId
              )
            ) {
              this.selectedCompany = this.methodServices.companyId;
            }
            this.modalChatbot.show();
          } else {
            this.methodServices.showToast(
              "Hello! I apologize, but AI knowledge base hasn't been configured yet, so I'm unable to assist with that specific request.",
              "warning"
            );
          }
        } else {
          this.methodServices.showToast(result.message, "error");
        }
      }
    );
  }

  changeCompany() {
    this.questionAnswerList = [];
  }

  searchAnswer(event: any) {
    if (event.keyCode == 13) {
      this.getAnswerFromApi();
    }
  }

  clickSearchAnswer() {
    if (!this.methodServices.imageLoad || this.loadingChatbotApi) {
      return;
    }
    this.getAnswerFromApi();
  }

  getAnswerFromApi(apiIndex = -1) {
    if (
      ((typeof this.chatbotValue == "undefined" ||
        this.chatbotValue == null ||
        this.chatbotValue == "") &&
        apiIndex == -1) ||
      this.loadingChatbotApi
    ) {
      return;
    }

    let dataObj = {};
    dataObj["groups"] = [
      this.methodServices.jwt_tenant_id,
      this.selectedCompany,
    ];
    dataObj["chatHistory"] = [];

    let tempChatHistory = this.questionAnswerList.slice();
    let reversedChatHistory = tempChatHistory.reverse();

    for (let arr in reversedChatHistory) {
      if (
        dataObj["chatHistory"].length < 3 &&
        reversedChatHistory[arr]["answer"] != null
      ) {
        dataObj["chatHistory"].push({
          question: window.btoa(reversedChatHistory[arr]["question"]),
          answer: window.btoa(reversedChatHistory[arr]["answer"]),
        });
      }
    }
    let questionValue =
      apiIndex == -1
        ? this.chatbotValue
        : this.questionAnswerList[apiIndex].question;

    if (apiIndex == -1) {
      dataObj["question"] = window.btoa(this.chatbotValue);
    } else {
      dataObj["question"] = window.btoa(questionValue);
      this.questionAnswerList.splice(apiIndex, 1);
    }

    apiIndex = this.questionAnswerList.length;

    this.questionAnswerList.push({
      question: questionValue,
      loadChatApi: true,
      statusLoad: true,
    });

    setTimeout(() => {
      let chatbotContainer = document.getElementById("chatbot-body-container");
      chatbotContainer.scrollTop = chatbotContainer.scrollHeight;

      this.chatbotValue = null;
      this.loadingChatbotApi = true;

      this.methodServices.postTenantApi(
        "/api/admin/ai/knowledge/ask",
        dataObj,
        (result: any, status: any) => {
          if (status == "Success") {
            this.questionAnswerList[apiIndex].answer = window.atob(
              result.answer
            );
            this.questionAnswerList[apiIndex].loadChatApi = false;
            this.questionAnswerList[apiIndex].statusLoad = true;

            this.loadingChatbotApi = false;

            setTimeout(() => {
              let chatbotContainer = document.getElementById(
                "chatbot-body-container"
              );
              chatbotContainer.scrollTop = chatbotContainer.scrollHeight;
            }, 100);
          } else {
            this.questionAnswerList[apiIndex].answer = null;
            this.questionAnswerList[apiIndex].loadChatApi = false;
            this.questionAnswerList[apiIndex].statusLoad = false;

            this.loadingChatbotApi = false;
          }
        },
        10000000
      );
    }, 100);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(void 0);
    this.ngUnsubscribe.complete();

    this.subject_DarkMode.next(void 0);
    this.subject_DarkMode.complete();
  }
}
