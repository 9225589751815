import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TagInputModule } from 'ngx-chips';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { GlobalModule } from '../global/global.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BuildDetailsHttpService } from '../build-details/build-details.http.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InputMaskModule } from '@ngneat/input-mask';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { LinkifyPipe } from '../../services/linkifypipe-services';


// for translation file i18n
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function fetchBuildDetails(buildDetailsService: BuildDetailsHttpService) {
  return () => buildDetailsService.fetchBuildDetails();
}

@Injectable({
  providedIn:'root'
})


@NgModule({
  declarations: [LinkifyPipe],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    RouterModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    GlobalModule,
    NgHttpLoaderModule.forRoot(),
    InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),
    NgApexchartsModule,
  ],
  exports: [
    CommonModule,
    ModalModule,
    RouterModule,
    FormsModule,
    BsDropdownModule,
    ToastrModule,
    CollapseModule,
    TagInputModule,
    NgHttpLoaderModule,
    BsDatepickerModule,
    NgxSkeletonLoaderModule,
    TimepickerModule,
    TranslateModule,
    GlobalModule,
    LinkifyPipe,
    NgApexchartsModule]
})
export class SharingModule { }
